import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import ResendEmailApi from 'data/api/resendEmail';
import { Failure, StatusRequest } from 'data/types';
import {
	ResendEmailRequest,
	ResendEmailResponse,
} from 'data/types/resendEmailTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TResendEmailSlice = {
	resendEmailStatusRequest: StatusRequest;
	resendEmailResponse?: ResendEmailResponse;
	resendEmailStatusError?: Failure;
};
const initialState: TResendEmailSlice = {
	resendEmailStatusRequest: StatusRequest.initial,
	resendEmailResponse: undefined,
	resendEmailStatusError: undefined,
};

export const ResendEmailAsync = createAsyncThunk<
	ResendEmailResponse,
	ResendEmailRequest,
	{ rejectValue: Failure }
>('auth/ResendEmailAsync', async (request: ResendEmailRequest, thunkAPI) => {
	try {
		console.log(request);
		const eitherResponse: Either<Failure, ResendEmailResponse> =
			await ResendEmailApi.resendEmail(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response resend email');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const ResendEmailSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetResendEmailStatusRequest: (state) => {
			state.resendEmailStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(ResendEmailAsync.pending, (state) => {
			state.resendEmailStatusRequest = StatusRequest.pending;
		});
		builder.addCase(ResendEmailAsync.fulfilled, (state, action) => {
			state.resendEmailStatusRequest = StatusRequest.fulfilled;
			state.resendEmailResponse = action.payload;
		});
		builder.addCase(ResendEmailAsync.rejected, (state, action) => {
			state.resendEmailStatusRequest = StatusRequest.rejected;
			state.resendEmailStatusError = action.payload;
		});
	},
});

export const selectResendEmail = (state: RootState) => state.resendEmail;
export const { resetResendEmailStatusRequest } =
	ResendEmailSlice.actions;
export default ResendEmailSlice.reducer;
