import React, { useEffect } from 'react';
import ViewLogout from './view';
import { useLogout } from './hooks';
import { useAppSelector } from 'data/global/hooks';
import { selectAuth } from 'data/global/features/AuthSlice/authSlice';
import { useNavigate } from 'react-router-dom';
export const Logout: React.FC = () => {
	const logoutUser = useLogout();
	const navigate = useNavigate();
	const { signInStatusError, signInStatusRequest, isLogin } =
		useAppSelector(selectAuth);
	useEffect(() => {
		if (isLogin === false) navigate('/');
	}, [isLogin, navigate]);

	return <ViewLogout onClick={logoutUser} />;
};

export default Logout;
