import { Sidebar as SidebarPrime, SidebarProps } from 'primereact/sidebar';
import { FunctionComponent } from 'react';

type Props = {} & SidebarProps;

export const Sidebar: FunctionComponent<Props> = ({
	visible = true,
	showCloseIcon = false,

	...props
}) => (
	<SidebarPrime
		visible={visible}
		showCloseIcon={showCloseIcon}
		//onHide={() => true}
		{...props}
	>
		<h1>{props.title}</h1>
		{props.children}
	</SidebarPrime>
);
