import { FunctionComponent } from 'react';
import {
	ConfirmPopup as ConfirmPopupPrime,
	ConfirmPopupProps,
} from 'primereact/confirmpopup';

type Props = {
	header?: any;
	title?: any;
	className?: string;
} & ConfirmPopupProps;

export const ConfirmPopup: FunctionComponent<Props> = ({ ...props }) => (
	<ConfirmPopupPrime title={props.title} {...props}>
		{props.children}
	</ConfirmPopupPrime>
);
