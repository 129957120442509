import { useAppDispatch } from 'data/global/hooks';
import { PutManufacturerAsync } from 'data/global/features/ManufacturersSlice/manufacturersSlice';
import { PutManufacturerRequest } from 'data/types/manufacturerTypes';

const usePutManufacturers = () => {
	const dispatch = useAppDispatch();

	const putManufacturers = (request: PutManufacturerRequest): void => {
		dispatch(PutManufacturerAsync(request));
	};

	return putManufacturers;
};

export default usePutManufacturers;
