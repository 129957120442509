import { useAppDispatch } from 'data/global/hooks';
import { resetCreateStatusRequest } from 'data/global/features/BrandsSlice/brandsSlice';

const useResetCreateBrand = () => {
	const dispatch = useAppDispatch();
	const ResetCreate = (): void => {
		dispatch(resetCreateStatusRequest());
	};
	return ResetCreate;
};

export default useResetCreateBrand;
