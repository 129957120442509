import { useAppDispatch } from 'data/global/hooks';
import { AddDistributorsAsync } from 'data/global/features/DistributorsSlice/distributorsSlice';
import { AddDistributorsRequest } from 'data/types/distributorsTypes';

export const useAddDistributors = () => {
	const dispatch = useAppDispatch();

	const addDistributors = (distributors: AddDistributorsRequest): void => {
		dispatch(AddDistributorsAsync(distributors));
	};

	return addDistributors;
};

export default useAddDistributors;
