import { generateDescriptionAsync } from './../../../data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useGenerateDescription = () => {
	const dispatch = useAppDispatch();

	const generateDescription = (): void => {
		dispatch(generateDescriptionAsync());
	};

	return generateDescription;
};

export default useGenerateDescription;