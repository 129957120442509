import { useAppDispatch } from 'data/global/hooks';
import { GetAllLibraryUserAsync } from 'data/global/features/LibraryUserSlice/libraryUserSlice';
import { useEffect } from 'react';

const useGetLibraryUsers = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllLibraryUserAsync({}));
	}, [dispatch]);
};

export default useGetLibraryUsers;