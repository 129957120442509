import { useAppDispatch } from 'data/global/hooks';
import { DeleteCatalogProductsAsync } from 'data/global/features/CatalogProductsSlice/catalogProductsSlice';

const useDeleteCatalogProducts = () => {
	const dispatch = useAppDispatch();

	const deleteCatalogProducts = (id: string): void => {
		dispatch(DeleteCatalogProductsAsync(id));
	};

	return deleteCatalogProducts;
};

export default useDeleteCatalogProducts;