import { importMasterProductAsync } from './../../../data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';
import { ImportRequest } from 'data/types/importerTypes';

const useImportMasterProduct = () => {
	const dispatch = useAppDispatch();

	const importMasterProduct = (req: ImportRequest): void => {
		dispatch(importMasterProductAsync(req));
	};

	return importMasterProduct;
};

export default useImportMasterProduct;