import { FunctionComponent } from 'react';
import {
	PanelMenu as PanelMenuPrime,
	PanelMenuProps,
} from 'primereact/panelmenu';

type Props = {
	model?: [];
} & PanelMenuProps;

export const PanelMenu: FunctionComponent<Props> = ({ ...props }) => (
	<PanelMenuPrime model={props.model} {...props}>
		{props.children}
	</PanelMenuPrime>
);
