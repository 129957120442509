import { useAppDispatch } from 'data/global/hooks';
import { GetAllUserAsync } from 'data/global/features/UserSlice/userSlice';
import { useEffect } from 'react';

const useGetUsers = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllUserAsync({}));
	}, [dispatch]);
};

export default useGetUsers;
