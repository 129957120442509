import { useAppDispatch } from 'data/global/hooks';
import { GetAllBannerAsync } from 'data/global/features/BannerSlice/bannerSlice';
import { useEffect } from 'react';

const useGetBanners = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllBannerAsync({}));
	}, [dispatch]);
};

export default useGetBanners;