import { AllPanelMenu, Logout } from 'components';
import DataTableDistributors from 'components/DataTableDistributors';
import { FunctionComponent } from 'react';
import style from './Distributors.module.css';

type Props = {
	value?: any;
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	distributors?: any;
	successText?: boolean;
};

export const ViewDistributors: FunctionComponent<Props> = (props) => (
	<div>
		<Logout />
		
			<div className={style.container}>
				<aside>
				<AllPanelMenu />

				</aside>

				<aside className={style.containerDataTable}>

			
				<DataTableDistributors 
				value={props.value}
				body={props.body}
				header={props.header}
				visible={props.visible}
				footer={props.footer}
				onHide={props.onHide}
				distributors={props.distributors}
				/>
				</aside>
			
			</div>
		
		
	</div>
);