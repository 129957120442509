import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllBannerRequest,
	GetAllBannerResponse,
	AddBannerRequest,
	AddBannerResponse,
	DeleteBannerResponse,
	PutBannerRequest,
	PutBannerResponse,
} from 'data/types/bannerTypes';
import { Either } from 'models/either';

export const getAllBanners = async (): Promise<
	Either<Failure, GetAllBannerResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllBannerRequest, GetAllBannerResponse>({
			url: '/api/v1/backoffice/banners',
			secure: true,
		})
	);
};
export const addBanner = async (
	request: AddBannerRequest
): Promise<Either<Failure, AddBannerResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<AddBannerRequest, AddBannerResponse>({
			url: '/api/v1/backoffice/banners',
			request,
		})
	);
};

export const deleteBanner = async (
	id: string
): Promise<Either<Failure, DeleteBannerResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<string, DeleteBannerResponse>({
			url: `/api/v1/backoffice/banners/${id}`,
		})
	);
};

export const putBanner = async (
	request: PutBannerRequest
): Promise<Either<Failure, PutBannerResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutBannerRequest, PutBannerResponse>({
			url: `/api/v1/backoffice/banners/${request.id}`,
			request,
		})
	);
};

const BannersApi = {
	getAllBanners,
	addBanner,
	deleteBanner,
	putBanner,
};

export default BannersApi;
