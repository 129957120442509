import React, { useRef, useEffect } from 'react';
import { ViewCreateBrand } from './view';
import { useForm } from 'react-hook-form';
import { rulesName, rulesLink } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import {
	useAddBrand,
	usePutBrand,
	usePutReset,
	useCreateReset,
} from './hooks';
import { selectBrands } from 'data/global/features/BrandsSlice/brandsSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const CreateBrand: React.FC = () => {
	// funcion que hace el dispatch para crear brand
	const addBrand = useAddBrand();

	const putBrand = usePutBrand();
	const resetPutBrand = usePutReset();
	const resetCreateBrand = useCreateReset();

	const { t } = useTranslation();
	const { addBrandsStatusError, addBrandsStatusRequest } =
		useAppSelector(selectBrands);

	const { putBrandsStatusError, putBrandsStatusRequest } =
		useAppSelector(selectBrands);

	type LocationStateProps = {
		brands: any;
	};

	const location = useLocation().state as LocationStateProps;
	const brandModified = location?.brands;
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = brandModified || {
		name: '',
		logo: '',
	};

	const handleSubmitModify = async (data: any) => {
		const id = brandModified.brandID;
		const { name, logo } = data;
		const request = { id, logo, name };
		putBrand(request);
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (putBrandsStatusRequest === 1) {
			showSuccessModify();
		} else if (putBrandsStatusRequest === 2) {
			showErrorModify();
		}
	}, [putBrandsStatusRequest]);

	useEffect(() => {
		if (addBrandsStatusRequest === 1) {
			showSuccessCreate();
		} else if (addBrandsStatusRequest === 2) {
			showErrorCreate();
		}
	}, [addBrandsStatusRequest]);

	const showSuccessModify = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Brand Modificado',
			detail: 'Se modifico con exito el brand seleccionado',
			life: 2000,
		});
		resetPutBrand();
		setTimeout(() => {
			navigate('/brands');
		}, 1000);
	};
	const showErrorModify = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido modificar el brand seleccionado',
		});
	};

	const showSuccessCreate = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Brand Creado',
			detail: 'El Brand fue creado con Exito',
			life: 3000,
		});
		resetCreateBrand();
		setTimeout(() => {
			navigate('/brands');
		}, 1000);
	};
	const showErrorCreate = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido crear el Brand',
		});
	};
	//
	//reglas de los inputs
	const rules = {
		rulesImage: rulesLink(t),
		rulesLink: rulesLink(t),
		rulesPosition: rulesName(t),
	};
	return (
		<ViewCreateBrand
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			addBrand={addBrand}
			error={addBrandsStatusError}
			modifyBrand={brandModified}
			handleSubmitModify={handleSubmitModify}
			toast={toast}
		/>
	);
};
