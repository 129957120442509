import { OrdersExportAsync } from 'data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useOrdersExport = () => {
	const dispatch = useAppDispatch();

	const OrdersExport = (): void => {
		dispatch(OrdersExportAsync());
	};

	return OrdersExport;
};

export default useOrdersExport;