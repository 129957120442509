import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	ResendEmailRequest,
	ResendEmailResponse,
} from 'data/types/resendEmailTypes';
import { Either } from 'models/either';

export const resendEmail = async (
	request: ResendEmailRequest
): Promise<Either<Failure, ResendEmailResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<ResendEmailRequest, ResendEmailResponse>({
			url: '/api/v1/backoffice/orders/resend-distributor-email',
			request,
		})
	);
};

const ResendEmailApi = {
	resendEmail
};

export default ResendEmailApi;
