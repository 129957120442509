import { useAppDispatch } from 'data/global/hooks';
import { PutDistributorsAsync } from 'data/global/features/DistributorsSlice/distributorsSlice';
import { PutDistributorsRequest } from 'data/types/distributorsTypes';

const usePutDistributors = () => {
	const dispatch = useAppDispatch();

	const putDistributors = (request: PutDistributorsRequest): void => {
		dispatch(PutDistributorsAsync(request));
	};

	return putDistributors;
};

export default usePutDistributors;
