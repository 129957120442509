import { ViewCatalogProducts } from "./view";
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectCatalogProducts } from 'data/global/features/CatalogProductsSlice/catalogProductsSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteCatalogProducts, useGetCatalogProducts } from "./hooks";

export const CatalogProducts: React.FC = () => {
    const [deleteCatalogProductsDialog, setDeleteCatalogProductsDialog] = useState(false);
	const [deleteCatalogProductsSuccess, setDeleteCatalogProductsSuccess] = useState(false);

	const deleteCatalogProducts = useDeleteCatalogProducts();

	const navigate = useNavigate();

	useGetCatalogProducts();
	const { getAllCatalogProductsResponse, deleteCatalogProductsStatusRequest } =
		useAppSelector(selectCatalogProducts);

	useEffect(() => {
		if (deleteCatalogProductsStatusRequest === 1) {
			setDeleteCatalogProductsSuccess(true);
		}
	}, [deleteCatalogProductsStatusRequest]);

	useEffect(() => {
		if (deleteCatalogProductsSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [catalogProductsDelete, setCatalogProductsDelete] = useState<null | { catalogProductsId: any }>(
		null
	);

	const editCatalogProducts = (catalogProducts: any) => {
		navigate('/addcatalogProducts', { state: { catalogProducts: catalogProducts } });
	};

	//  delete
	const confirmDeleteCatalogProducts = (catalogProducts: any) => {
		setCatalogProductsDelete(catalogProducts);
		setDeleteCatalogProductsDialog(true);
	};

	const deleteCatalogProductsDispatch = () => {
		setDeleteCatalogProductsDialog(false);
		deleteCatalogProducts(catalogProductsDelete?.catalogProductsId);
	};
	const hideDeleteCatalogProductsDialog = () => {
		setDeleteCatalogProductsDialog(false);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'CatalogProducts'}
					textButton={'Agregar CatalogProducts'}
                    redirectTo={'/addCatalogProducts'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar catalogProducts'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editCatalogProducts(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar catalogProducts'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteCatalogProducts(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteCatalogProductsDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteCatalogProductsDispatch}
			/>
		</Fragment>
	);

    return <ViewCatalogProducts
        value={getAllCatalogProductsResponse?.cpel}
        body={bodyTemplate}
        header={header}
        footer={deleteProductDialogFooter}
        visible={deleteCatalogProductsDialog}
        onHide={hideDeleteCatalogProductsDialog}
        catalogProducts={catalogProductsDelete}
            />
};