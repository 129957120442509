import { useAppDispatch } from 'data/global/hooks';
import { resetCreateStatusRequest } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';

const useResetCreatePresetCart = () => {
	const dispatch = useAppDispatch();
	const ResetCreate = (): void => {
		dispatch(resetCreateStatusRequest());
	};
	return ResetCreate;
};

export default useResetCreatePresetCart;
