import { FunctionComponent } from 'react';
import {
	DataTable as DataTablePrime,
	DataTableProps,
} from 'primereact/datatable';

type Props = {
	value?: any[];
	header?: any;
	footer?: any;
	alignheader?: string;
	filter?: boolean;
	sortable?: boolean;
	className?: string;
	body?: any;
} & DataTableProps;

export const DataTable: FunctionComponent<Props> = ({ ...props }) => (
	<DataTablePrime
		value={props.value}
		header={props.header}
		body={props.body}
		footer={props.footer}
		alignheader={props.alignheader}
		filter={props.filter}
		sortable={props.sortable}
		className={props.className}
		{...props}
	>
		{props.children}
	</DataTablePrime>
);
