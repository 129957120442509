import { FunctionComponent } from 'react';
import { InputTextSigIn, Button, ErrorText, Dropdown, Toast } from 'UI';
import { AllPanelMenu, Logout } from 'components';
import { ControlProps, FormProps } from 'models/form';
import { TCreateUser } from 'models/userModels';
import style from './CreateUser.module.css';

type Props = {
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	value?: string;
	options?: any[];
	placeholder?: string;
	modifyUser?: any;
	onChange?: (e: any) => void;
	createUser: (user: TCreateUser) => void;
	handleSubmitModify: (data: any) => void;
	toast?: any;
};

export const ViewCreateUser: FunctionComponent<Props> = (props) => (
	<div className={style.allContainer}>
		<Logout />

		<div className={style.container}>
			<aside>
				<AllPanelMenu />
			</aside>
			<aside className={style.cardContainer}>
				<div className={style.card}>
					{props?.modifyUser ? (
						<h1>Modificar Usuario</h1>
					) : (
						<h1> Crear Usuario</h1>
					)}
					<div className={style.inputContainer}>
						<label className={style.label}>Email</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.email.email,
								name: 'email',
								rules: props.controlProps.rules.rulesEmail,
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='email'
							className={style.input}
						></InputTextSigIn>
						<label className={style.label}>Contraseña</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.password.password,
								name: 'password',
								rules: props.controlProps.rules.rulesPassword,
							}}
							errors={props.formProps.errors}
							type='password'
							placeholder='password'
							className={style.input}
						></InputTextSigIn>
						<label className={style.label}>Roles</label>
						<Dropdown
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.roles.roles,
								name: 'roles',
							}}
							options={props.options}
							placeholder={props.placeholder}
							className={style.input}
						></Dropdown>
						<label className={style.label}>ID Reference</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: "",
								name: 'referenceId',
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='ID reference'
							className={style.input}
						></InputTextSigIn>
						{props?.modifyUser ? (
							<Button
								label='Modificar Usuario'
								className='p-button-lg'
								style={{ marginTop: '1.5em' }}
								onClick={props.formProps.handleSubmit(props.handleSubmitModify)}
							></Button>
						) : (
							<Button
								label='Crear Usuario'
								className='p-button-lg'
								style={{ marginTop: '1.5em' }}
								onClick={props.formProps.handleSubmit(props.createUser)}
							></Button>
						)}
						<Toast toast={props.toast} />
					</div>

					{props?.error?.error && (
						<ErrorText style={{ marginTop: '1.5em' }}>
							{props?.error?.error}
						</ErrorText>
					)}
				</div>
			</aside>
		</div>
	</div>
);
