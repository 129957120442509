import { ViewMasterProducts } from "./view";
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectMasterProducts } from 'data/global/features/MasterProductsSlice/masterProductsSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteMasterProducts, useGetMasterProducts } from "./hooks";

export const MasterProducts: React.FC = () => {
    const [deleteMasterProductsDialog, setDeleteMasterProductsDialog] = useState(false);
	const [deleteMasterProductsSuccess, setDeleteMasterProductsSuccess] = useState(false);

	const deleteMasterProducts = useDeleteMasterProducts();

	const navigate = useNavigate();

	useGetMasterProducts();
	const { getAllMasterProductsResponse, deleteMasterProductsStatusRequest } =
		useAppSelector(selectMasterProducts);

	useEffect(() => {
		if (deleteMasterProductsStatusRequest === 1) {
			setDeleteMasterProductsSuccess(true);
		}
	}, [deleteMasterProductsStatusRequest]);

	useEffect(() => {
		if (deleteMasterProductsSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [masterProductsDelete, setMasterProductsDelete] = useState<null | { masterProductsId: any }>(
		null
	);

	const editMasterProducts = (masterProducts: any) => {
		navigate('/addmasterProducts', { state: { masterProducts: masterProducts } });
	};

	//  delete
	const confirmDeleteMasterProducts = (masterProducts: any) => {
		setMasterProductsDelete(masterProducts);
		setDeleteMasterProductsDialog(true);
	};

	const deleteMasterProductsDispatch = () => {
		setDeleteMasterProductsDialog(false);
		deleteMasterProducts(masterProductsDelete?.masterProductsId);
	};
	const hideDeleteMasterProductsDialog = () => {
		setDeleteMasterProductsDialog(false);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'MasterProducts'}
					textButton={'Agregar MasterProducts'}
                    redirectTo={'/addMasterProducts'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar masterProducts'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editMasterProducts(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar masterProducts'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteMasterProducts(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteMasterProductsDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteMasterProductsDispatch}
			/>
		</Fragment>
	);
	
    return <ViewMasterProducts
        value={getAllMasterProductsResponse?.elements}
        body={bodyTemplate}
        header={header}
        footer={deleteProductDialogFooter}
        visible={deleteMasterProductsDialog}
        onHide={hideDeleteMasterProductsDialog}
        masterProducts={masterProductsDelete}
            />
};