const namesRoutes = {
	createuser: '/createuser',
	useradmin: '/useradmin',
	dashboard: '/dashboard',
	addbanner: '/addbanner',
	banners: '/banners',
	brands: '/brands',
	addbrand: '/addbrand',
	categories: '/categories',
	addcategories: '/addcategories',
	distributors: '/distributors',
	manufacturers:'/manufacturers',
	adddistributors: '/adddistributors',
	addManufacturers:'/addManufacturers',
	libraryuser: '/libraryuser',
	addlibraryuser: '/addlibraryuser',
	libraryusersdetail:'/libraryusersdetail',
	masterproducts: '/masterproducts',
	catalogproducts: '/catalogproducts',
	importer: '/importer',
	resendEmail: '/resendEmail',
	addPresetCarts: '/addPresetCarts',
	presetCarts: '/presetcarts',
	home: '/',
};

export default namesRoutes;
