import { FunctionComponent } from 'react';
import { Column, DataTable, Dialog } from 'UI';

type Props = {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	masterProducts?: any;
};

const ViewDataTableMasterProducts: FunctionComponent<Props> = (props) => (
	<div>
		<DataTable value={props.value} header={props.header}>
			<Column field='masterProductId' header='ID'></Column>
			<Column field='name' header='name'></Column>
			<Column header='Acciones' body={props.body}></Column>
		</DataTable>
		<Dialog
			visible={props.visible}
			header='Eliminar MasterProducts'
			modal
			footer={props.footer}
			style={{ width: '450px' }}
			onHide={props.onHide}
		>
			<div className='confirmation-content'>
				<i
					className='pi pi-exclamation-triangle mr-3'
					style={{ fontSize: '2rem' }}
				/>
				{props.masterProducts && (
					<span>
						Estas seguro de eliminar el masterProducts? <b>{props.masterProducts?.email}</b>?
					</span>
				)}
			</div>
		</Dialog>
	</div>
);

export default ViewDataTableMasterProducts;