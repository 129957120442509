import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllUserAdminRequest,
	GetAllUserAdminResponse,
	CreateUserRequest,
	CreateUserResponse,
	DeleteUserRequest,
	DeleteUserResponse,
	PutUserRequest,
	PutUserResponse,
} from 'data/types/userTypes';
import { Either } from 'models/either';

export const getAllUsers = async (): Promise<
	Either<Failure, GetAllUserAdminResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllUserAdminRequest, GetAllUserAdminResponse>({
			url: '/api/v1/backoffice/admin-user',
			secure: true,
		})
	);
};
export const createUser = async (
	request: CreateUserRequest
): Promise<Either<Failure, CreateUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<CreateUserRequest, CreateUserResponse>({
			url: '/api/v1/backoffice/admin-user',
			request,
		})
	);
};

export const deleteUser = async (
	id: DeleteUserRequest
): Promise<Either<Failure, DeleteUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<DeleteUserRequest, DeleteUserResponse>({
			url: `/api/v1/backoffice/admin-user/${id}`,
		})
	);
};

export const putUser = async (
	request: PutUserRequest
): Promise<Either<Failure, PutUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutUserRequest, PutUserResponse>({
			url: `/api/v1/backoffice/admin-user/${request.id}`,
			request,
		})
	);
};

const UsersApi = {
	getAllUsers,
	createUser,
	deleteUser,
	putUser,
};

export default UsersApi;
