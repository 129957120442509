import { FunctionComponent } from 'react';
import { Menubar, Button } from 'UI';
type Props = {
	onClick?: (e: any) => void;
};

export const LogoutView: FunctionComponent<Props> = (props) => (
	<div>
		<Menubar
			end={
				<Button label='Logout' icon='pi pi-power-off' onClick={props.onClick} />
			}
		/>
	</div>
);
export default LogoutView;
