import React, { useRef, useEffect } from 'react';
import { ViewCreateLibraryUser } from './view';
import { useForm } from 'react-hook-form';
import { rulesName, rulesLink } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import {
	useAddLibraryUser,
	usePutLibraryUser,
	usePutReset,
	useCreateReset,
	useGetDistributors,
} from './hooks';
import { selectLibraryUser } from 'data/global/features/LibraryUserSlice/libraryUserSlice';
import { selectDistributors } from 'data/global/features/DistributorsSlice/distributorsSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const CreateLibraryUser: React.FC = () => {
	// funcion que hace el dispatch para crear el library user
	const addLibraryUser = useAddLibraryUser();

	const putLibraryUser = usePutLibraryUser();
	const resetPutLibraryUser = usePutReset();
	const resetCreateLibraryUser = useCreateReset();

	useGetDistributors();
	const { getAllDistributorsResponse } = useAppSelector(selectDistributors);

	const { t } = useTranslation();
	const { addLibraryUserStatusError, addLibraryUserStatusRequest } =
		useAppSelector(selectLibraryUser);

	const { putLibraryUserStatusError, putLibraryUserStatusRequest } =
		useAppSelector(selectLibraryUser);

	type LocationStateProps = {
		libraryUser: any;
	};

	const location = useLocation().state as LocationStateProps;
	const libraryUserModified = location?.libraryUser;
	const navigate = useNavigate();

	const getDistributorName = (distributorID: string) => {
		const distributor = getAllDistributorsResponse?.find(
			(distributor) => distributor.distributorID === distributorID
		);
		return distributor?.distributorName || '';
	};

	const getDistributorIDs = (distributorNames: { [key: string]: string[] }) => {
		const namesArray = Object.values(distributorNames).flat();

		const distributorIDs: string[] = namesArray.map((distributorName) =>
			getAllDistributorsResponse?.find(
				(distributor) => distributor.distributorName === distributorName
			)?.distributorID
		).filter((id): id is string => !!id);
				
		return distributorIDs;
	};

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = libraryUserModified || {
		libraryUserId: '',
		name: '',
		lastName: '',
		distributorList: [],
	};

	const handleSubmitModify = async (data: any) => {
		const id = libraryUserModified.libraryUserId;
		const distributors = getDistributorIDs(data.distributorList);
		const { name, lastName, email } = data;
		const request = { id, name, lastName, email, distributors };
		putLibraryUser(request);
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (putLibraryUserStatusRequest === 1) {
			showSuccessModify();
		} else if (putLibraryUserStatusRequest === 2) {
			showErrorModify();
		}
	}, [putLibraryUserStatusRequest]);

	useEffect(() => {
		if (addLibraryUserStatusRequest === 1) {
			showSuccessCreate();
		} else if (addLibraryUserStatusRequest === 2) {
			showErrorCreate();
		}
	}, [addLibraryUserStatusRequest]);

	const showSuccessModify = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Libreria Modificada',
			detail: 'Se modifico con exito la libreria seleccionada',
			life: 2000,
		});
		resetPutLibraryUser();
		setTimeout(() => {
			navigate('/libraryuser');
		}, 1000);
	};
	const showErrorModify = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido modificar la libreria seleccionada',
		});
	};

	const showSuccessCreate = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Libreria Creada',
			detail: 'La libreria fue creada con Exito',
			life: 3000,
		});
		resetCreateLibraryUser();
		setTimeout(() => {
			navigate('/libraryuser');
		}, 1000);
	};
	const showErrorCreate = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido crear la libreria',
		});
	};
	//
	//reglas de los inputs
	const rules = {
		rules: '',
	};

	const distributorSelectItems = getAllDistributorsResponse?.map(
		(distributor) => ({
			label: distributor.distributorName,
			value: [distributor.distributorName],
		})
	);

	const placeholder = 'Selecciona un distribuidor';
	return (
		<ViewCreateLibraryUser
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			options={distributorSelectItems}
			placeholder={placeholder}
			addLibraryUser={addLibraryUser}
			error={addLibraryUserStatusError}
			modifyLibraryUser={libraryUserModified}
			handleSubmitModify={handleSubmitModify}
			findById={getDistributorName}
			toast={toast}
		/>
	);
};
