import { FunctionComponent } from 'react';
import style from './ContainerSidebar.module.css';
// import { Card as CardPrime, CardProps } from 'primereact/card';

type Props = {};

export const ContainerSidebar: FunctionComponent<Props> = ({ ...props }) => (
	<span className={style.sidebar} {...props}>
		{props.children}
	</span>
);
