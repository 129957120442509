import { useAppDispatch } from 'data/global/hooks';
import { PutUserAsync } from 'data/global/features/UserSlice/userSlice';
import { PutUserRequest } from 'data/types/userTypes';

const usePutUser = () => {
	const dispatch = useAppDispatch();

	const putUser = (request: PutUserRequest): void => {
		dispatch(PutUserAsync(request));
	};

	return putUser;
};

export default usePutUser;
