import { FunctionComponent } from 'react';
import { InputTextSigIn, Button, ErrorText, Dropdown, Toast } from 'UI';
import { AllPanelMenu, Logout } from 'components';
import { ControlProps, FormProps } from 'models/form';
import { TResendEmail } from 'models/resendEmailModels';
import style from './ResendEmail.module.css';

type Props = {
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	value?: string;
	options?: any[];
	placeholder?: string;
	onChange?: (e: any) => void;
	resendEmail: (resendEmail: TResendEmail) => void;
	toast?: any;
};

export const ViewResendEmail: FunctionComponent<Props> = (props) => (
	<div className={style.allContainer}>
		<Logout />

		<div className={style.container}>
			<aside>
				<AllPanelMenu />
			</aside>
			<aside className={style.cardContainer}>
				<div className={style.card}>
					{<h1>Reenviar Email</h1>}
					<div className={style.inputContainer}>
						<label className={style.label}>Order Id</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.subOrderId,
								name: 'subOrderId',
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='orderId'
							className={style.input}
						></InputTextSigIn>
						<label className={style.label}>
							Destinatarios (separados por coma)
						</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.emails,
								name: 'emails',
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='emails'
							className={style.input}
						></InputTextSigIn>
						<label className={style.label}>
							Añadir archivo adjunto con listado de productos
						</label>
						<Dropdown
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.attachedFile,
								name: 'attachedFile',
							}}
							options={props.options}
							placeholder='Seleccione una opcion'
							className={style.input}
						></Dropdown>
						<Button
							label='Reenviar Email'
							className='p-button-lg'
							style={{ marginTop: '1.5em' }}
							onClick={props.formProps.handleSubmit(props.resendEmail)}
						></Button>
						<Toast toast={props.toast} />
					</div>

					{props?.error?.error && (
						<ErrorText style={{ marginTop: '1.5em' }}>
							{props?.error?.error}
						</ErrorText>
					)}
				</div>
			</aside>
		</div>
	</div>
);
