import React, { useRef, useEffect } from 'react';
import { ViewCreateBanner } from './view';
import { useForm } from 'react-hook-form';
import { rulesName, rulesLink } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import {
	useAddBanner,
	usePutBanner,
	usePutReset,
	useCreateReset,
} from './hooks';
import { selectBanner } from 'data/global/features/BannerSlice/bannerSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const CreateBanner: React.FC = () => {
	// funcion que hace el dispatch para crear banner
	const addBanner = useAddBanner();

	const putBanner = usePutBanner();
	const resetPutBanner = usePutReset();
	const resetCreateBanner = useCreateReset();

	const { t } = useTranslation();
	const { addBannerStatusError, addBannerStatusRequest } =
		useAppSelector(selectBanner);

	const { putBannerStatusError, putBannerStatusRequest } =
		useAppSelector(selectBanner);

	type LocationStateProps = {
		banner: any;
	};

	const location = useLocation().state as LocationStateProps;
	const bannerModified = location?.banner;
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = bannerModified || {
		image: '',
		link: '',
		position: '',
	};

	const handleSubmitModify = async (data: any) => {
		const id = bannerModified.bannerId;
		const { image, positions, link } = data;
		const request = { id, image, positions, link };
		putBanner(request);
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (putBannerStatusRequest === 1) {
			showSuccessModify();
		} else if (putBannerStatusRequest === 2) {
			showErrorModify();
		}
	}, [putBannerStatusRequest]);

	useEffect(() => {
		if (addBannerStatusRequest === 1) {
			showSuccessCreate();
		} else if (addBannerStatusRequest === 2) {
			showErrorCreate();
		}
	}, [addBannerStatusRequest]);

	const showSuccessModify = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Banner Modificado',
			detail: 'Se modifico con exito el banner seleccionado',
			life: 2000,
		});
		resetPutBanner();
		setTimeout(() => {
			navigate('/banners');
		}, 1000);
	};
	const showErrorModify = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido modificar el banner seleccionado',
		});
	};

	const showSuccessCreate = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Banner Creado',
			detail: 'El Banner fue creado con Exito',
			life: 3000,
		});
		resetCreateBanner();
		setTimeout(() => {
			navigate('/banners');
		}, 1000);
	};
	const showErrorCreate = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido crear el Banner',
		});
	};
	//
	//reglas de los inputs
	const rules = {
		rulesImage: rulesLink(t),
		rulesLink: rulesLink(t),
		rulesPosition: rulesName(t),
	};

	const positionSelectItems = [
		{ label: 'START', value: ['START'] },
		{ label: 'END', value: ['END'] },
	];
	const placeholder = 'Selecciona una position';
	return (
		<ViewCreateBanner
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			options={positionSelectItems}
			placeholder={placeholder}
			addBanner={addBanner}
			error={addBannerStatusError}
			modifyBanner={bannerModified}
			handleSubmitModify={handleSubmitModify}
			toast={toast}
		/>
	);
};
