import React from 'react';
import { FunctionComponent } from 'react';
import ViewDataTableUser from './view';

interface Props {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	user?: object;
}

export const DataTableUser: FunctionComponent<Props> = ({
	value,
	header,
	body,
	visible,
	footer,
	onHide,
	user,
}) => {
	return (
		<ViewDataTableUser
			value={value}
			header={header}
			body={body}
			visible={visible}
			footer={footer}
			onHide={onHide}
			user={user}
		/>
	);
};

export default DataTableUser;
