import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { ViewUserAdmin } from './view';
import { useGetUsers, useDeleteUser } from './hooks';
import { useAppSelector } from 'data/global/hooks';
import { selectUser } from 'data/global/features/UserSlice/userSlice';
import { useNavigate } from 'react-router-dom';

export const UserAdmin: React.FC = () => {
	const [deleteUserDialog, setDeleteUserDialog] = useState(false);
	const [deleteUserSuccess, setDeleteUserSuccess] = useState(false);

	const deleteUser = useDeleteUser();

	const navigate = useNavigate();

	useGetUsers();
	const { getAllUserAdminResponse, deleteUserStatusRequest } =
		useAppSelector(selectUser);

	useEffect(() => {
		if (deleteUserStatusRequest === 1) {
			setDeleteUserSuccess(true);
		}
	}, [deleteUserStatusRequest]);

	useEffect(() => {
		if (deleteUserSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [userDelete, setUserDelete] = useState<null | { adminUserId: any }>(
		null
	);

	const editUser = (user: any) => {
		navigate('/createuser', { state: { user: user } });
	};

	//  delete
	const confirmDeleteUser = (user: any) => {
		setUserDelete(user);
		setDeleteUserDialog(true);
	};

	const deleteUserDispatch = () => {
		setDeleteUserDialog(false);
		deleteUser(userDelete?.adminUserId);
	};
	const hideDeleteUserDialog = () => {
		setDeleteUserDialog(false);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'Usuarios Administradores'}
					textButton={'Agregar Usuario'}
					redirectTo = {'/createuser'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar usuario'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editUser(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar usuario'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteUser(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteUserDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteUserDispatch}
			/>
		</Fragment>
	);
	return (
		<ViewUserAdmin
			value={getAllUserAdminResponse}
			body={bodyTemplate}
			header={header}
			footer={deleteProductDialogFooter}
			visible={deleteUserDialog}
			onHide={hideDeleteUserDialog}
			user={userDelete}
		/>
	);
};
