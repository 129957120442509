import React, { useRef, useEffect } from 'react';
import { ViewCreateCategories } from './view';
import { useForm } from 'react-hook-form';
import { rulesName, rulesLink } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import {
	useAddCategories,
	usePutCategories,
	usePutReset,
	useCreateReset,
} from './hooks';
import { selectCategories } from 'data/global/features/CategoriesSlice/categoriesSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const CreateCategories: React.FC = () => {
	// funcion que hace el dispatch para crear categories
	const addCategories = useAddCategories();

	const putCategories = usePutCategories();
	const resetPutCategories = usePutReset();
	const resetCreateCategories = useCreateReset();

	const { t } = useTranslation();
	const { addCategoriesStatusError, addCategoriesStatusRequest } =
		useAppSelector(selectCategories);

	const { putCategoriesStatusError, putCategoriesStatusRequest } =
		useAppSelector(selectCategories);

	type LocationStateProps = {
		categories: any;
	};

	const location = useLocation().state as LocationStateProps;
	const categoriesModified = location?.categories;
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = categoriesModified || {
		name: '',
		photo: '',
	};

	const handleSubmitModify = async (data: any) => {
		const id = categoriesModified.categoryID;
		const { name, photo } = data;
		const request = { id, name, photo };
		putCategories(request);
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (putCategoriesStatusRequest === 1) {
			showSuccessModify();
		} else if (putCategoriesStatusRequest === 2) {
			showErrorModify();
		}
	}, [putCategoriesStatusRequest]);

	useEffect(() => {
		if (addCategoriesStatusRequest === 1) {
			showSuccessCreate();
		} else if (addCategoriesStatusRequest === 2) {
			showErrorCreate();
		}
	}, [addCategoriesStatusRequest]);

	const showSuccessModify = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Categories Modificado',
			detail: 'Se modifico con exito el categories seleccionado',
			life: 2000,
		});
		resetPutCategories();
		setTimeout(() => {
			navigate('/categories');
		}, 1000);
	};
	const showErrorModify = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido modificar el categories seleccionado',
		});
	};

	const showSuccessCreate = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Categories Creado',
			detail: 'El Categories fue creado con Exito',
			life: 3000,
		});
		resetCreateCategories();
		setTimeout(() => {
			navigate('/categories');
		}, 1000);
	};
	const showErrorCreate = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido crear el Categories',
		});
	};
	//
	//reglas de los inputs
	const rules = {
		rulesImage: rulesLink(t),
		rulesLink: rulesLink(t),
		rulesPosition: rulesName(t),
	};

	const positionSelectItems = [
		{ label: 'START', value: ['START'] },
		{ label: 'END', value: ['END'] },
	];
	const placeholder = 'Selecciona una position';
	return (
		<ViewCreateCategories
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			options={positionSelectItems}
			placeholder={placeholder}
			addCategories={addCategories}
			error={addCategoriesStatusError}
			modifyCategories={categoriesModified}
			handleSubmitModify={handleSubmitModify}
			toast={toast}
		/>
	);
};
