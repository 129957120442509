import { importBrandsAsync } from 'data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';
import { ImportRequest } from 'data/types/importerTypes';

const useImportBrands = () => {
	const dispatch = useAppDispatch();

	const importBrands = (req: ImportRequest): void => {
		dispatch(importBrandsAsync(req));
	};

	return importBrands;
};

export default useImportBrands;