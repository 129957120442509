import { FunctionComponent } from 'react';
import { InputTextSigIn, Button, ErrorText, Dropdown, Toast } from 'UI';
import { AllPanelMenu, Logout } from 'components';
import { ControlProps, FormProps } from 'models/form';
import { PostManufacturerRequest } from 'data/types/manufacturerTypes';
import style from './CreateManufacturers.module.css';

type Props = {
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	value?: string;
	options?: any[];
	placeholder?: string;
	modifyManufacturers?: any;
	onChange?: (e: any) => void;
	addManufacturers: (manufacturers: PostManufacturerRequest) => void;
	handleSubmitModify: (data: any) => void;
	toast?: any;
};

export const ViewCreateManufacturer: FunctionComponent<Props> = (props) => (
	<div className={style.allContainer}>
		<Logout />

		<div className={style.container}>
			<aside>
				<AllPanelMenu />
			</aside>
			<aside className={style.cardContainer}>
				<div className={style.card}>
					{props?.modifyManufacturers ? (
						<h1>Modificar Manufacturers</h1>
					) : (
						<h1> Crear Manufacturers</h1>
					)}
					<div className={style.inputContainer}>
						<label className={style.label}>Name</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.manufacturerName,
								name: 'name',
								rules: props.controlProps.rules.rulesName,
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='name'
							className={style.input}
						></InputTextSigIn>
						<label className={style.label}>Photo</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.manufacturerPhoto,
								name: 'photo',
							    rules:props.controlProps.rules.rulesLink
							}}
							errors= {props.formProps.errors}
							type='text'
							placeholder='photo'
							className={style.label}
							></InputTextSigIn>
						<label className={style.label}>maxManufacturingDistance</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.maxManufacturingDistance,
								name: 'maxManufacturingDistance',
								rules: props.controlProps.rules.rulesName,
							}}
							errors= {props.formProps.errors}
							type='text'
							placeholder='maxManufacturingDistance'
							className={style.label}
							></InputTextSigIn>
						{props?.modifyManufacturers ? (
							<Button
								label='Modificar Manufacturers'
								className='p-button-lg'
								style={{ marginTop: '1.5em' }}
								onClick={props.formProps.handleSubmit(props.handleSubmitModify)}
							></Button>
						) : (
							<Button
								label='Crear Manufacturers'
								className='p-button-lg'
								style={{ marginTop: '1.5em' }}
								onClick={props.formProps.handleSubmit(props.addManufacturers)}
							></Button>
						)}
						<Toast toast={props.toast} />
					</div>

					{props?.error?.error && (
						<ErrorText style={{ marginTop: '1.5em' }}>
							{props?.error?.error}
						</ErrorText>
					)}
				</div>
			</aside>
		</div>
	</div>
);
