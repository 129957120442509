import React from 'react';
import View from './view';
import { NavLink } from 'react-router-dom';

export const PanelMenuUserAdmin: React.FC = () => {
	const Role = localStorage.getItem('role');

	const items = [
		{
			label: 'Management',
			//icon: 'pi pi-fw pi-file',
			items: [
				{
					label: 'Admin Users',
					template: () => {
						return (
							/* custom element */
							<NavLink className='p-menuitem-link' to={'/useradmin'}>
								{'Admin Users'}
							</NavLink>
						);
					},
				},
			],
		},
		{
			label: 'Groups',
			items: [
				{
					label: 'Banners',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/banners'}>
								{'Banners'}
							</NavLink>
						);
					},
				},
				{
					label: 'Brands',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/brands'}>
								{'Brands'}
							</NavLink>
						);
					},
				},
				{
					label: 'Categories',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/categories'}>
								{'Categories'}
							</NavLink>
						);
					},
				},
				{
					label: 'Distributors',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/distributors'}>
								{'Distributors'}
							</NavLink>
						);
					},
				},
				{
					label: 'Manufacturers',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/manufacturers'}>
								{'Manufacturers'}
							</NavLink>
						);
					},
				},
				{
					label: 'Preset Carts',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/presetCarts'}>
								{'Preset Carts'}
							</NavLink>
						);
					},
				},
			],
		},
		{
			label: 'Libreries',
			items: [
				{
					label: 'Library Users',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/libraryuser'}>
								{'Library Users'}
							</NavLink>
						);
					},
				},
				{
					label: 'Library Users Details',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/libraryusersdetail'}>
								{'Library Users Details'}
							</NavLink>
						);
					},
				},
				/* {
					label: 'Ordenes'
				},
				{
					label: 'Carrito'
				} */
			],
		},
		{
			label: 'Products',
			items: [
				{
					label: 'Catalog Products',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/catalogproducts'}>
								{'Catalog Products'}
							</NavLink>
						);
					},
				},
				{
					label: 'Master Products',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/masterproducts'}>
								{'Master Products'}
							</NavLink>
						);
					},
				},
			],
		},
		{
			label: 'Gestión',
			items: [
				{
					label: 'Importer',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/importer'}>
								{'Importer'}
							</NavLink>
						);
					},
				},
				{
					label: 'Resend Email',
					template: () => {
						return (
							<NavLink className='p-menuitem-link' to={'/resendEmail'}>
								{'Resend Email'}
							</NavLink>
						);
					},
				},
			],
		},
	];
	console.log(Role);
	const itemsDistributorsAndManufactures = [
		Role === 'ROLE_DISTRIBUTOR'
			? [
					{
						label: 'Products',
						items: [
							{
								label: 'Catalog Products',
								template: () => {
									return (
										<NavLink
											className='p-menuitem-link'
											to={'/catalogproducts'}
										>
											{'Catalog Products'}
										</NavLink>
									);
								},
							},
						],
					},
					{
						label: 'Gestión',
						items: [
							{
								label: 'Importer',
								template: () => {
									return (
										<NavLink className='p-menuitem-link' to={'/importer'}>
											{'Importer'}
										</NavLink>
									);
								},
							},
						],
					},
			  ]
			: [
					{
						label: 'Gestión',
						items: [
							{
								label: 'Importer',
								template: () => {
									return (
										<NavLink className='p-menuitem-link' to={'/importer'}>
											{'Importer'}
										</NavLink>
									);
								},
							},
						],
					},
			  ],
	];

	return (
		<View
			model={
				Role === 'ROLE_DISTRIBUTOR' || Role === 'ROLE_MANUFACTURER'
					? itemsDistributorsAndManufactures[0]
					: items
			}
		/>
	);
};

export default PanelMenuUserAdmin;
