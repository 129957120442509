import { FunctionComponent } from 'react';
import { InputTextSigIn, Button, ErrorText, Dropdown, Toast } from 'UI';
import { AllPanelMenu, Logout } from 'components';
import { ControlProps, FormProps } from 'models/form';
import { TCreateLibraryUser } from 'models/libraryUserModels';
import style from './CreateLibraryUser.module.css';

type Props = {
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	value?: string;
	options?: any[];
	placeholder?: string;
	modifyLibraryUser?: any;
	onChange?: (e: any) => void;
	addLibraryUser: (libraryUser: TCreateLibraryUser) => void;
	findById: (distributorID: string) => string;
	handleSubmitModify: (data: any) => void;
	toast?: any;
};

export const ViewCreateLibraryUser: FunctionComponent<Props> = (props) => (
	<div className={style.allContainer}>
		<Logout />
		<div className={style.container}>
			<aside>
				<AllPanelMenu />
			</aside>
			<aside className={style.cardContainer}>
				<div className={style.card}>
					{props?.modifyLibraryUser ? (
						<h1>Modificar Libreria</h1>
					) : (
						<h1> Crear Libreria</h1>
					)}
					<div className={style.inputContainer}>
					{props?.modifyLibraryUser && (
						<>
							<label className={style.label}>Id Libreria</label>
							<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.libraryUserId,
								name: 'libraryUserId',
								rules: '',
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='libreria id'
							className={style.input}
							/>
						</>
						)}
						<label className={style.label}>Nombre</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.name,
								name: 'name',
								rules: '',
							}}
							errors= {props.formProps.errors}
							type='text'
							placeholder='nombre'
							className={style.input}
							></InputTextSigIn>
						<label className={style.label}>Apellido</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.lastName,
								name: 'lastName',
								rules: '',
							}}
							errors= {props.formProps.errors}
							type='text'
							placeholder='apellido'
							className={style.input}
						></InputTextSigIn>
						<label className={style.label}>Email</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.email,
								name: 'email',
								rules: '',
							}}
							errors= {props.formProps.errors}
							type='text'
							placeholder='apellido'
							className={style.input}
						></InputTextSigIn>
						<label className={style.label}>Distribuidor 1</label>
						<Dropdown
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: [props.findById(props.controlProps.defaultValue.distributorList.at(0))],
								name: 'distributorList.at(0)',
							}}
							options={props.options}
							placeholder={props.placeholder}
							className={style.input}
						></Dropdown>

						<label className={style.label}>Distribuidor 2</label>
						<Dropdown
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: [props.findById(props.controlProps.defaultValue.distributorList.at(1))],
								name: 'distributorList.at(1)',
							}}
							options={props.options}
							placeholder={props.placeholder}
							className={style.input}
						></Dropdown>

						<label className={style.label}>Distribuidor 3</label>
						<Dropdown
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: [props.findById(props.controlProps.defaultValue.distributorList.at(2))],
								name: 'distributorList.at(2)',
							}}
							options={props.options}
							placeholder={props.placeholder}
							className={style.input}
						></Dropdown>

						<label className={style.label}>Distribuidor 4</label>
						<Dropdown
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: [props.findById(props.controlProps.defaultValue.distributorList.at(3))],
								name: 'distributorList.at(3)',
							}}
							options={props.options}
							placeholder={props.placeholder}
							className={style.input}
						></Dropdown>
						{props?.modifyLibraryUser ? (
							<Button
								label='Modificar Library User'
								className='p-button-lg'
								style={{ marginTop: '1.5em' }}
								onClick={props.formProps.handleSubmit(props.handleSubmitModify)}
							></Button>
						) : (
							<Button
								label='Crear Library User '
								className='p-button-lg'
								style={{ marginTop: '1.5em' }}
								onClick={props.formProps.handleSubmit(props.addLibraryUser)}
							></Button>
						)}
						<Toast toast={props.toast} />
					</div>

					{props?.error?.error && (
						<ErrorText style={{ marginTop: '1.5em' }}>
							{props?.error?.error}
						</ErrorText>
					)}
				</div>
			</aside>
		</div>
	</div>
);
