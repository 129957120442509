import { useAppDispatch } from 'data/global/hooks';
import { PutCategoriesAsync } from 'data/global/features/CategoriesSlice/categoriesSlice';
import { PutCategoriesRequest } from 'data/types/categoriesTypes';

const usePutCategories = () => {
	const dispatch = useAppDispatch();

	const putCategories = (request: PutCategoriesRequest): void => {
		dispatch(PutCategoriesAsync(request));
	};

	return putCategories;
};

export default usePutCategories;
