import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import ManufacturersApi from 'data/api/manufacturer';
import { Failure, StatusRequest } from 'data/types';
import {
GetAllManufacturerRequest,
GetAllManufacturerResponse,
PostManufacturerRequest,
PutManufacturerRequest,
PutManufacturerResponse,
PostManufacturerResponse,
DeleteManufacturerResponse,
DeleteManufacturerRequest,

} from 'data/types/manufacturerTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TmanufacturerSlice = {
	getAllManufacturersStatusRequest: StatusRequest;
	getAllManufacturersResponse?: GetAllManufacturerResponse;
	getAllManufacturersStatusError?: Failure;
	addManufacturersStatusRequest?: StatusRequest;
	addManufacturersStatusError?: Failure;
	deleteManufacturersStatusRequest?: StatusRequest;
	deleteManufacturersStatusError?: Failure;
	putManufacturersStatusRequest: StatusRequest;
	putManufacturersStatusError?: Failure;
};
const initialState: TmanufacturerSlice = {
	getAllManufacturersStatusRequest: StatusRequest.initial,
	getAllManufacturersResponse: undefined,
	getAllManufacturersStatusError: undefined,
	addManufacturersStatusRequest: StatusRequest.initial,
	addManufacturersStatusError: undefined,
	deleteManufacturersStatusRequest: StatusRequest.initial,
	deleteManufacturersStatusError: undefined,
	putManufacturersStatusRequest: StatusRequest.initial,
	putManufacturersStatusError: undefined,
};

export const GetAllManufacturersAsync = createAsyncThunk<
	GetAllManufacturerResponse,
	GetAllManufacturerRequest,
	{ rejectValue: Failure }
>('auth/GetAllManufacturerAsync', async (request: GetAllManufacturerRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllManufacturerResponse> =
			await ManufacturersApi.getAllManufacturer();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get Manufacturers');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddManufacturerAsync = createAsyncThunk<
	PostManufacturerResponse,
	PostManufacturerRequest,
	{ rejectValue: Failure }
>('manufacturer/AddManufacturerAsync', async (request: PostManufacturerRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PostManufacturerResponse> =
			await ManufacturersApi.addManufacturer(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post Manufacturer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteManufacturerAsync = createAsyncThunk<
	DeleteManufacturerResponse,
	DeleteManufacturerRequest,
	{ rejectValue: Failure }
>('manufacturer/DeleteManufacturerAsync', async (request: DeleteManufacturerRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteManufacturerResponse> =
			await ManufacturersApi.deleteManufacturer(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete Manufacturer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
	
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutManufacturerAsync = createAsyncThunk<
	PutManufacturerResponse,
	PutManufacturerRequest,
	{ rejectValue: Failure }
>('manufacturer/PutManufacturerAsync', async (request: PutManufacturerRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutManufacturerResponse> =
			await ManufacturersApi.putManufacturer(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put manufacturer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const ManufacturerSlice = createSlice({
	name: 'Manufacturer',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putManufacturersStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addManufacturersStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllManufacturersAsync.pending, (state) => {
			state.getAllManufacturersStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllManufacturersAsync.fulfilled, (state, action) => {
			state.getAllManufacturersStatusRequest = StatusRequest.fulfilled;
			state.getAllManufacturersResponse = action.payload;
		});
		builder.addCase(GetAllManufacturersAsync.rejected, (state, action) => {
			state.getAllManufacturersStatusRequest = StatusRequest.rejected;
			state.getAllManufacturersStatusError = action.payload;
		});
		builder.addCase(AddManufacturerAsync.pending, (state) => {
			state.addManufacturersStatusRequest = StatusRequest.pending;
			state.addManufacturersStatusError = undefined;
		});
		builder.addCase(AddManufacturerAsync.fulfilled, (state, action) => {
			state.addManufacturersStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddManufacturerAsync.rejected, (state, action) => {
			state.addManufacturersStatusRequest = StatusRequest.rejected;
			state.addManufacturersStatusError = action.payload;
		});
		builder.addCase(DeleteManufacturerAsync.pending, (state) => {
			state.deleteManufacturersStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteManufacturerAsync.fulfilled, (state, action) => {
			state.deleteManufacturersStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteManufacturerAsync.rejected, (state, action) => {
			state.addManufacturersStatusRequest = StatusRequest.rejected;
			state.deleteManufacturersStatusError = action.payload;
		});
		builder.addCase(PutManufacturerAsync.pending, (state) => {
			state.putManufacturersStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutManufacturerAsync.fulfilled, (state, action) => {
			state.putManufacturersStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutManufacturerAsync.rejected, (state, action) => {
			state.putManufacturersStatusRequest = StatusRequest.rejected;
			state.putManufacturersStatusError = action.payload;
		});
	},
});

export const selectManufacturer = (state: RootState) => state.manufacturer;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	ManufacturerSlice.actions;
export default ManufacturerSlice.reducer;
