import { GetBrandsExportAsync } from 'data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useBrandsExport = () => {
	const dispatch = useAppDispatch();

	const GetBrandsExport = (): void => {
		dispatch(GetBrandsExportAsync());
	};

	return GetBrandsExport;
};

export default useBrandsExport;