import { useAppDispatch } from 'data/global/hooks';
import { AddLibraryUserAsync } from 'data/global/features/LibraryUserSlice/libraryUserSlice';
import { AddLibraryUserRequest } from 'data/types/libraryUserTypes';

export const useAddLibraryUser = () => {
	const dispatch = useAppDispatch();

	const addLibraryUser = (libraryUser: AddLibraryUserRequest): void => {
		dispatch(AddLibraryUserAsync(libraryUser));
	};

	return addLibraryUser;
};

export default useAddLibraryUser;
