import { FunctionComponent } from 'react';
import { Column, DataTable, Dialog } from 'UI';

type Props = {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	manufacturers?: any;
};

const ViewDataTableManufacturer: FunctionComponent<Props> = (props) => (
	<div>
		<DataTable value={props.value} header={props.header}>
			<Column field='manufacturerID' header='ID'></Column>
			<Column field='manufacturerName' header='Name'></Column>
			<Column field='manufacturerPhoto' header='Photo'></Column>
			<Column field='maxManufacturerDistance' header='MaxDist'></Column>
			<Column header='Acciones' body={props.body}></Column>
		</DataTable>
		<Dialog
			visible={props.visible}
			header='Eliminar Manufacturer'
			modal
			footer={props.footer}
			style={{ width: '450px' }}
			onHide={props.onHide}
		>
			<div className='confirmation-content'>
				<i
					className='pi pi-exclamation-triangle mr-3'
					style={{ fontSize: '2rem' }}
				/>
				{props.manufacturers && (
					<span>
						¿Estas seguro de eliminar el Fabricante <b>{props.manufacturers?.manufacturerName}</b>?
					</span>
				)}
			</div>
		</Dialog>
	</div>
);

export default ViewDataTableManufacturer;