import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllLibraryUserRequest,
	GetAllLibraryUserResponse,
	AddLibraryUserRequest,
	AddLibraryUserResponse,
	DeleteLibraryUserResponse,
	PutLibraryUserRequest,
	PutLibraryUserResponse,
	ImpersonateLibraryUserResponse,
} from 'data/types/libraryUserTypes';
import { Either } from 'models/either';

export const getAllLibraryUser = async (): Promise<
	Either<Failure, GetAllLibraryUserResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllLibraryUserRequest, GetAllLibraryUserResponse>({
			url: '/api/v1/backoffice/library-user',
			secure: true,
		})
	);
};
export const addLibraryUser = async (
	request: AddLibraryUserRequest
): Promise<Either<Failure, AddLibraryUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<AddLibraryUserRequest, AddLibraryUserResponse>({
			url: '/api/v1/backoffice/library-user',
			request,
		})
	);
};

export const deleteLibraryUser = async (
	id: string
): Promise<Either<Failure, DeleteLibraryUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<string, DeleteLibraryUserResponse>({
			url: `/api/v1/backoffice/library-user/${id}`,
		})
	);
};

export const impersonateLibraryUser = async (
	id: string
): Promise<Either<Failure, ImpersonateLibraryUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<string, ImpersonateLibraryUserResponse>({
			url: `/api/v1/backoffice/login-as/${id}`,
			secure: true,
		})
	);
};

export const putLibraryUser = async (
	request: PutLibraryUserRequest
): Promise<Either<Failure, PutLibraryUserResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutLibraryUserRequest, PutLibraryUserResponse>({
			url: `/api/v1/backoffice/library-user/${request.id}`,
			request,
		})
	);
};

const LibraryUserApi = {
	getAllLibraryUser,
	addLibraryUser,
	deleteLibraryUser,
	putLibraryUser,
	impersonateLibraryUser,
};

export default LibraryUserApi;
