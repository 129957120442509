import { ViewPresetCarts } from './view';
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectPresetCarts } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';
import { useNavigate } from 'react-router-dom';
import { useDeletePresetCarts, useGetPresetCarts, usePresetCartItemsExport } from './hooks';
import { StatusRequest } from 'data/types';
import useResetGetExport from './hooks/useResetGetExport';

export const PresetCarts: React.FC = () => {
	const [deletePresetCartsDialog, setDeletePresetCartsDialog] = useState(false);
	const [deletePresetCartsSuccess, setDeletePresetCartsSuccess] =
		useState(false);

	// Put
	const [presetCartsDelete, setPresetCartsDelete] = useState<any>(null);

	const deletePresetCarts = useDeletePresetCarts();

	const getPresetCartItemsExport = usePresetCartItemsExport();

	const resetGetExport = useResetGetExport();

	const navigate = useNavigate();

	const download_txt = (file: string, name: string) => {
		var hiddenElement = document.createElement('a');
		hiddenElement.href = 'data:attachment/text,' + encodeURIComponent(file);
		hiddenElement.target = '_blank';
		hiddenElement.download = `${name}.csv`;
		hiddenElement.click();
	};

	useGetPresetCarts();
	const { getAllPresetCartsResponse, deletePresetCartsStatusRequest, getPresetCartItemsExportStatusRequest, getPresetCartItemsExportResponse } =
		useAppSelector(selectPresetCarts);

	useEffect(() => {
		console.log(deletePresetCartsStatusRequest)
		if (deletePresetCartsStatusRequest === 1) {
			setDeletePresetCartsSuccess(true);
		}
	}, [deletePresetCartsStatusRequest]);

	useEffect(() => {
		if (deletePresetCartsSuccess) {
			window.location.reload();
		}
	});

	useEffect(() => {
		if (getPresetCartItemsExportStatusRequest === StatusRequest.fulfilled) {
			download_txt(getPresetCartItemsExportResponse!.file, 'presetCartItems');
			resetGetExport();
		}
	}, [getPresetCartItemsExportStatusRequest]);

	const editPresetCarts = (presetCarts: any) => {
		navigate('/addpresetCarts', { state: { presetCarts: presetCarts } });
	};

	//  delete
	const confirmDeletePresetCarts = (presetCarts: any) => {
		setPresetCartsDelete(presetCarts);
		setDeletePresetCartsDialog(true);
	};

	const deletePresetCartsDispatch = () => {
		setDeletePresetCartsDialog(false);
		console.log('presetCartId', presetCartsDelete);
		deletePresetCarts(presetCartsDelete.id);
	};
	const hideDeletePresetCartsDialog = () => {
		setDeletePresetCartsDialog(false);
	};

	const getPresetCartItemsDispatch = (presetCarts: any) => {
		getPresetCartItemsExport(presetCarts.id);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'PresetCarts'}
					textButton={'Agregar PresetCarts'}
					redirectTo={'/addPresetCarts'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar presetCarts'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editPresetCarts(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar presetCarts'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeletePresetCarts(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<Button
						icon='pi pi-download'
						tooltip='Descargar Productos en .csv'
						tooltipOptions={{ position: 'top' }}
						onClick={() => getPresetCartItemsDispatch(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeletePresetCartsDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deletePresetCartsDispatch}
			/>
		</Fragment>
	);

	return (
		<ViewPresetCarts
			value={getAllPresetCartsResponse}
			body={bodyTemplate}
			header={header}
			footer={deleteProductDialogFooter}
			visible={deletePresetCartsDialog}
			onHide={hideDeletePresetCartsDialog}
			presetCarts={presetCartsDelete}
		/>
	);
};
