import { importDistributorsAsync } from 'data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';
import { ImportRequest } from 'data/types/importerTypes';

const useImportDistributors = () => {
	const dispatch = useAppDispatch();

	const importDistributors = (req: ImportRequest): void => {
		dispatch(importDistributorsAsync(req));
	};

	return importDistributors;
};

export default useImportDistributors;