import { useAppDispatch } from 'data/global/hooks';
import { DeleteUserAsync } from 'data/global/features/UserSlice/userSlice';
import { DeleteUserRequest } from 'data/types/userTypes';

const useDeleteUser = () => {
	const dispatch = useAppDispatch();

	const deleteUser = (id: DeleteUserRequest): void => {
		dispatch(DeleteUserAsync(id));
	};

	return deleteUser;
};

export default useDeleteUser;
