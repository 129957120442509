import { useAppDispatch } from 'data/global/hooks';
import { AddManufacturerAsync } from 'data/global/features/ManufacturersSlice/manufacturersSlice';
import { PostManufacturerRequest } from 'data/types/manufacturerTypes';

export const useAddManufacturerers = () => {
	const dispatch = useAppDispatch();

	const addManufacture = (manufacturer: PostManufacturerRequest): void => {
		dispatch(AddManufacturerAsync(manufacturer));
	};

	return addManufacture;
};

export default useAddManufacturerers;
