import { importCategoriesAsync } from 'data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';
import { ImportRequest } from 'data/types/importerTypes';

const useImportCategories = () => {
	const dispatch = useAppDispatch();

	const importCategories = (req: ImportRequest): void => {
		dispatch(importCategoriesAsync(req));
	};

	return importCategories;
};

export default useImportCategories;