import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from 'data/global/features/AuthSlice/authSlice';
import userReducer from 'data/global/features/UserSlice/userSlice';
import bannerReducer from 'data/global/features/BannerSlice/bannerSlice';
import brandsReducer from 'data/global/features/BrandsSlice/brandsSlice';
import resendEmailReducer from 'data/global/features/ResendEmailSlice/resendEmailSlice';
import categoriesReducer from 'data/global/features/CategoriesSlice/categoriesSlice';
import distributorsReducer from 'data/global/features/DistributorsSlice/distributorsSlice';
import libraryUserReducer from 'data/global/features/LibraryUserSlice/libraryUserSlice';
import masterProductsReducer from 'data/global/features/MasterProductsSlice/masterProductsSlice';
import catalogProductsSlice from '../features/CatalogProductsSlice/catalogProductsSlice';
import importerSlice from '../features/ImporterSlice/importerSlice';
import manufacturersSlice from '../features/ManufacturersSlice/manufacturersSlice';
import ResendEmailSlice from '../features/ResendEmailSlice/resendEmailSlice';
import presetCartsReducer from 'data/global/features/PresetCartsSlice/presetsCartsSlice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		user: userReducer,
		banner: bannerReducer,
		brands: brandsReducer,
		categories: categoriesReducer,
		distributors: distributorsReducer,
		manufacturer: manufacturersSlice,
		libraryUser: libraryUserReducer,
		masterProducts: masterProductsReducer,
		catalogProducts: catalogProductsSlice,
		importers: importerSlice,
		resendEmail: resendEmailReducer,
		presetCarts: presetCartsReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
