import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllManufacturerRequest,
	GetAllManufacturerResponse,
	PostManufacturerRequest,
	PostManufacturerResponse,
	DeleteManufacturerResponse,
	DeleteManufacturerRequest,
	PutManufacturerRequest,
	PutManufacturerResponse,
} from 'data/types/manufacturerTypes';
import { Either } from 'models/either';

export const getAllManufacturer = async (): Promise<
	Either<Failure, GetAllManufacturerResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllManufacturerRequest, GetAllManufacturerResponse>({
			url: '/api/v1/backoffice/manufacturer',
			secure: true,
		})
	);
};
export const addManufacturer = async (
	request: PostManufacturerRequest
): Promise<Either<Failure, PostManufacturerResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<PostManufacturerRequest, PostManufacturerResponse>({
			url: '/api/v1/backoffice/manufacturer',
			request,
		})
	);
};

export const deleteManufacturer = async (
	id: DeleteManufacturerRequest
): Promise<Either<Failure, DeleteManufacturerResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<DeleteManufacturerRequest, DeleteManufacturerResponse>({
			url: `/api/v1/backoffice/manufacturer/${id}`,
		})
	);
};

export const putManufacturer = async (
	request: PutManufacturerRequest
): Promise<Either<Failure, PutManufacturerResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutManufacturerRequest, PutManufacturerResponse>({
			url: `/api/v1/backoffice/manufacturer/${request.manufacturerID}`,
			request,
		})
	);
};

const ManufacturersApi = {
	getAllManufacturer,
	addManufacturer,
	deleteManufacturer,
	putManufacturer,
};

export default ManufacturersApi;
