import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ImporterApi from 'data/api/importerApi';
import { RootState } from 'data/global/store';
import { Failure, StatusRequest } from 'data/types';
import { ExportResponse, ImportRequest } from 'data/types/importerTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TimporterSlice = {
	getBrandsExportStatusRequest: StatusRequest;
	getBrandsExportResponse?: ExportResponse;
	getBrandsExportStatusError?: Failure;
	//
	getCategoriesExportStatusRequest: StatusRequest;
	getCategoriesExportResponse?: ExportResponse;
	getCategoriesExportStatusError?: Failure;
	//
	getDistributorsExportStatusRequest: StatusRequest;
	getDistributorsExportResponse?: ExportResponse;
	getDistributorsExportStatusError?: Failure;
	//
	getMasterProductExportStatusRequest: StatusRequest;
	getMasterProductExportResponse?: ExportResponse;
	getMasterProductExportStatusError?: Failure;
	//
	getCatalogProductExportStatusRequest: StatusRequest;
	getCatalogProductExportResponse?: ExportResponse;
	getCatalogProductExportStatusError?: Failure;
	//
	ordersToExportRequest: StatusRequest;
	ordersToExportResponse?: ExportResponse;
	ordersToExportError?: Failure;
	// ////
	importBrandsStatusRequest: StatusRequest;
	importBrandsResponse?: ExportResponse;
	importBrandsStatusError?: Failure;
	//
	importCategoriesStatusRequest: StatusRequest;
	importCategoriesResponse?: ExportResponse;
	importCategoriesStatusError?: Failure;
	//
	importDistributorsStatusRequest: StatusRequest;
	importDistributorsExportResponse?: ExportResponse;
	importDistributorsExportStatusError?: Failure;
	//
	importCatalogProductStatusRequest: StatusRequest;
	importCatalogProductExportResponse?: ExportResponse;
	importCatalogProductExportStatusError?: Failure;
	//
	importMasterProductStatusRequest: StatusRequest;
	importMasterProductExportResponse?: ExportResponse;
	importMasterProductExportStatusError?: Failure;
	//
	cleanerStatusRequest: StatusRequest;
	cleanerResponse?: ExportResponse;
	cleanerStatusError?: Failure;
	//
	reIndexStatusRequest: StatusRequest;
	reIndexResponse?: string;
	reIndexStatusError?: Failure;

	generateDescriptionStatusRequest: StatusRequest;
	generateDescriptionResponse?: string;
	generateDescriptionStatusError?: Failure;
};

const initialState: TimporterSlice = {
	ordersToExportRequest: StatusRequest.initial,
	ordersToExportResponse: undefined,
	ordersToExportError: undefined,
	getBrandsExportStatusRequest: StatusRequest.initial,
	getBrandsExportResponse: undefined,
	getBrandsExportStatusError: undefined,
	getCategoriesExportStatusRequest: StatusRequest.initial,
	getCategoriesExportResponse: undefined,
	getCategoriesExportStatusError: undefined,
	getDistributorsExportStatusRequest: StatusRequest.initial,
	getDistributorsExportResponse: undefined,
	getDistributorsExportStatusError: undefined,
	getMasterProductExportStatusRequest: StatusRequest.initial,
	getMasterProductExportResponse: undefined,
	getMasterProductExportStatusError: undefined,
	getCatalogProductExportStatusRequest: StatusRequest.initial,
	getCatalogProductExportResponse: undefined,
	getCatalogProductExportStatusError: undefined,
	importBrandsStatusRequest: StatusRequest.initial,
	importBrandsResponse: undefined,
	importBrandsStatusError: undefined,
	importCategoriesStatusRequest: StatusRequest.initial,
	importCategoriesResponse: undefined,
	importCategoriesStatusError: undefined,
	importDistributorsStatusRequest: StatusRequest.initial,
	importDistributorsExportResponse: undefined,
	importDistributorsExportStatusError: undefined,
	importCatalogProductStatusRequest: StatusRequest.initial,
	importCatalogProductExportResponse: undefined,
	importCatalogProductExportStatusError: undefined,
	importMasterProductStatusRequest: StatusRequest.initial,
	importMasterProductExportResponse: undefined,
	importMasterProductExportStatusError: undefined,
	cleanerStatusRequest: StatusRequest.initial,
	cleanerResponse: undefined,
	cleanerStatusError: undefined,
	reIndexStatusRequest: StatusRequest.initial,
	reIndexResponse: undefined,
	reIndexStatusError: undefined,
	generateDescriptionStatusRequest: StatusRequest.initial,
	generateDescriptionResponse: undefined,
	generateDescriptionStatusError: undefined,
};

export const OrdersExportAsync = createAsyncThunk<
	ExportResponse,
	void,
	{ rejectValue: Failure }
>('importer/OrdersExportAsync', async (request: void, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ExportResponse> =
			await ImporterApi.OrdersToExport();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response importer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const getMasterProductsAsync = createAsyncThunk<
	ExportResponse,
	void,
	{ rejectValue: Failure }
>('importer/getMasterProductsAsync', async (request: void, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ExportResponse> =
			await ImporterApi.getMasterProductsToExport();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response importer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetCatalogProductExportAsync = createAsyncThunk<
	ExportResponse,
	void,
	{ rejectValue: Failure }
>('importer/GetCatalogProductExportAsync', async (request: void, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ExportResponse> =
			await ImporterApi.getCatalogProductsToExport();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response importer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetBrandsExportAsync = createAsyncThunk<
	ExportResponse,
	void,
	{ rejectValue: Failure }
>('importer/GetBrandsExportAsync', async (request: void, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ExportResponse> =
			await ImporterApi.getBrandsToExport();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response importer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetCategoriesExportAsync = createAsyncThunk<
	ExportResponse,
	void,
	{ rejectValue: Failure }
>('importer/GetCategoriesExportAsync', async (request: void, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ExportResponse> =
			await ImporterApi.getCategoriesToExport();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response importer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetDistributorsExportAsync = createAsyncThunk<
	ExportResponse,
	void,
	{ rejectValue: Failure }
>('importer/GetDistributorsExportAsync', async (request: void, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ExportResponse> =
			await ImporterApi.getDistributorsToExport();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response importer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

//////////////

export const importBrandsAsync = createAsyncThunk<
	ExportResponse,
	ImportRequest,
	{ rejectValue: Failure }
>('importer/importBrandsAsync', async (request: ImportRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ExportResponse> =
			await ImporterApi.importBrands(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response importer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const importCategoriesAsync = createAsyncThunk<
	ExportResponse,
	ImportRequest,
	{ rejectValue: Failure }
>(
	'importer/importCategoriesAsync',
	async (request: ImportRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, ExportResponse> =
				await ImporterApi.importCategories(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response, 'Response importer');
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const importDistributorsAsync = createAsyncThunk<
	ExportResponse,
	ImportRequest,
	{ rejectValue: Failure }
>(
	'importer/importDistributorsAsync',
	async (request: ImportRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, ExportResponse> =
				await ImporterApi.importDistributors(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response, 'Response importer');
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const importMasterProductAsync = createAsyncThunk<
	ExportResponse,
	ImportRequest,
	{ rejectValue: Failure }
>(
	'importer/importMasterProductAsync',
	async (request: ImportRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, ExportResponse> =
				await ImporterApi.importMasterProducts(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response, 'Response importer');
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const importCatalogProductAsync = createAsyncThunk<
	ExportResponse,
	ImportRequest,
	{ rejectValue: Failure }
>(
	'importer/importCatalogProductAsync',
	async (request: ImportRequest, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, ExportResponse> =
				await ImporterApi.importCatalogProducts(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response, 'Response importer');
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const cleanerProductAsync = createAsyncThunk<
	ExportResponse,
	{ type: string },
	{ rejectValue: Failure }
>(
	'importer/cleanerProductAsync',
	async (request: { type: string }, thunkAPI) => {
		try {
			const eitherResponse: Either<Failure, ExportResponse> =
				await ImporterApi.cleanerProducts(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				console.log(response, 'Response importer');
				return response;
			}
			const error = unwrapEither(eitherResponse);

			return thunkAPI.rejectWithValue(error);
		} catch (e) {
			const error: Failure = {
				error: true,
				message: (e as Error).toString(),
			};
			return thunkAPI.rejectWithValue(error);
		}
	}
);

export const reIndexAsync = createAsyncThunk<
	string,
	void,
	{ rejectValue: Failure }
>('importer/reIndexAsync', async (request, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, string> =
			await ImporterApi.reIndexElastic();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response importer');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const generateDescriptionAsync = createAsyncThunk<
	string,
	void,
	{ rejectValue: Failure }
>('importer/generateDescriptionAsync', async (request, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, string> =
			await ImporterApi.generateDescription();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response description generator');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const ImporterSlice = createSlice({
	name: 'importer',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getMasterProductsAsync.pending, (state) => {
			state.getMasterProductExportStatusRequest = StatusRequest.pending;
		});
		builder.addCase(getMasterProductsAsync.fulfilled, (state, action) => {
			state.getMasterProductExportStatusRequest = StatusRequest.fulfilled;
			state.getMasterProductExportResponse = action.payload;
		});
		builder.addCase(getMasterProductsAsync.rejected, (state, action) => {
			state.getMasterProductExportStatusRequest = StatusRequest.rejected;
			state.getMasterProductExportStatusError = action.payload;
		});
		//
		builder.addCase(OrdersExportAsync.pending, (state) => {
			state.ordersToExportRequest = StatusRequest.pending;
		});
		builder.addCase(OrdersExportAsync.fulfilled, (state, action) => {
			state.ordersToExportRequest = StatusRequest.fulfilled;
			state.ordersToExportResponse = action.payload;
		});
		builder.addCase(OrdersExportAsync.rejected, (state, action) => {
			state.ordersToExportRequest = StatusRequest.rejected;
			state.ordersToExportError = action.payload;
		});
		//
		builder.addCase(GetCatalogProductExportAsync.pending, (state) => {
			state.getCatalogProductExportStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetCatalogProductExportAsync.fulfilled, (state, action) => {
			state.getCatalogProductExportStatusRequest = StatusRequest.fulfilled;
			state.getCatalogProductExportResponse = action.payload;
		});
		builder.addCase(GetCatalogProductExportAsync.rejected, (state, action) => {
			state.getCatalogProductExportStatusRequest = StatusRequest.rejected;
			state.getCatalogProductExportStatusError = action.payload;
		});
		//
		builder.addCase(GetBrandsExportAsync.pending, (state) => {
			state.getBrandsExportStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetBrandsExportAsync.fulfilled, (state, action) => {
			state.getBrandsExportStatusRequest = StatusRequest.fulfilled;
			state.getBrandsExportResponse = action.payload;
		});
		builder.addCase(GetBrandsExportAsync.rejected, (state, action) => {
			state.getBrandsExportStatusRequest = StatusRequest.rejected;
			state.getBrandsExportStatusError = action.payload;
		});
		//
		builder.addCase(GetCategoriesExportAsync.pending, (state) => {
			state.getCategoriesExportStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetCategoriesExportAsync.fulfilled, (state, action) => {
			state.getCategoriesExportStatusRequest = StatusRequest.fulfilled;
			state.getCategoriesExportResponse = action.payload;
		});
		builder.addCase(GetCategoriesExportAsync.rejected, (state, action) => {
			state.getCategoriesExportStatusRequest = StatusRequest.rejected;
			state.getCategoriesExportStatusError = action.payload;
		});
		//
		builder.addCase(GetDistributorsExportAsync.pending, (state) => {
			state.getDistributorsExportStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetDistributorsExportAsync.fulfilled, (state, action) => {
			state.getDistributorsExportStatusRequest = StatusRequest.fulfilled;
			state.getDistributorsExportResponse = action.payload;
		});
		builder.addCase(GetDistributorsExportAsync.rejected, (state, action) => {
			state.getDistributorsExportStatusRequest = StatusRequest.rejected;
			state.getDistributorsExportStatusError = action.payload;
		});

		//////////////

		builder.addCase(importDistributorsAsync.pending, (state) => {
			state.importDistributorsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(importDistributorsAsync.fulfilled, (state, action) => {
			state.importDistributorsStatusRequest = StatusRequest.fulfilled;
			state.importDistributorsExportResponse = action.payload;
		});
		builder.addCase(importDistributorsAsync.rejected, (state, action) => {
			state.importDistributorsStatusRequest = StatusRequest.rejected;
			state.importDistributorsExportStatusError = action.payload;
		});
		//
		builder.addCase(importCategoriesAsync.pending, (state) => {
			state.importCategoriesStatusRequest = StatusRequest.pending;
		});
		builder.addCase(importCategoriesAsync.fulfilled, (state, action) => {
			state.importCategoriesStatusRequest = StatusRequest.fulfilled;
			state.importCategoriesResponse = action.payload;
		});
		builder.addCase(importCategoriesAsync.rejected, (state, action) => {
			state.importCategoriesStatusRequest = StatusRequest.rejected;
		});
		//
		builder.addCase(importBrandsAsync.pending, (state) => {
			state.importBrandsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(importBrandsAsync.fulfilled, (state, action) => {
			state.importBrandsStatusRequest = StatusRequest.fulfilled;
			state.importBrandsResponse = action.payload;
		});
		builder.addCase(importBrandsAsync.rejected, (state, action) => {
			state.importBrandsStatusRequest = StatusRequest.rejected;
		});

		builder.addCase(importMasterProductAsync.pending, (state) => {
			state.importMasterProductStatusRequest = StatusRequest.pending;
		});
		builder.addCase(importMasterProductAsync.fulfilled, (state, action) => {
			state.importMasterProductStatusRequest = StatusRequest.fulfilled;
			state.importMasterProductExportResponse = action.payload;
		});
		builder.addCase(importMasterProductAsync.rejected, (state, action) => {
			state.importMasterProductStatusRequest = StatusRequest.rejected;
			state.importMasterProductExportStatusError = action.payload;
		});
		//
		builder.addCase(importCatalogProductAsync.pending, (state) => {
			state.importCatalogProductStatusRequest = StatusRequest.pending;
		});
		builder.addCase(importCatalogProductAsync.fulfilled, (state, action) => {
			state.importCatalogProductStatusRequest = StatusRequest.fulfilled;
			state.importCatalogProductExportResponse = action.payload;
		});
		builder.addCase(importCatalogProductAsync.rejected, (state, action) => {
			state.importCatalogProductStatusRequest = StatusRequest.rejected;
			state.importCatalogProductExportStatusError = action.payload;
		});
		//
		builder.addCase(cleanerProductAsync.pending, (state) => {
			state.cleanerStatusRequest = StatusRequest.pending;
		});
		builder.addCase(cleanerProductAsync.fulfilled, (state, action) => {
			state.cleanerStatusRequest = StatusRequest.fulfilled;
			state.cleanerResponse = action.payload;
		});
		builder.addCase(cleanerProductAsync.rejected, (state, action) => {
			state.cleanerStatusRequest = StatusRequest.rejected;
			state.cleanerStatusError = action.payload;
		});
		//
		builder.addCase(reIndexAsync.pending, (state) => {
			state.reIndexStatusRequest = StatusRequest.pending;
		});
		builder.addCase(reIndexAsync.fulfilled, (state, action) => {
			state.reIndexStatusRequest = StatusRequest.fulfilled;
			state.reIndexResponse = action.payload;
		});
		builder.addCase(reIndexAsync.rejected, (state, action) => {
			state.reIndexStatusRequest = StatusRequest.rejected;
			state.reIndexStatusError = action.payload;
		});
		//
		builder.addCase(generateDescriptionAsync.pending, (state) => {
			state.generateDescriptionStatusRequest = StatusRequest.pending;
		});
		builder.addCase(generateDescriptionAsync.fulfilled, (state, action) => {
			state.generateDescriptionStatusRequest = StatusRequest.fulfilled;
			state.generateDescriptionResponse = action.payload;
		});
		builder.addCase(generateDescriptionAsync.rejected, (state, action) => {
			state.generateDescriptionStatusRequest = StatusRequest.rejected;
			state.generateDescriptionStatusError = action.payload;
		});
	},
});

export const selectImporters = (state: RootState) => state.importers;
export default ImporterSlice.reducer;
