import React, { useRef, useEffect } from 'react';
import { ViewCreateDistributors } from './view';
import { useForm } from 'react-hook-form';
import { rulesName, rulesLink } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import {
	useAddDistributors,
	usePutDistributors,
	usePutReset,
	useCreateReset,
} from './hooks';
import { selectDistributors } from 'data/global/features/DistributorsSlice/distributorsSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const CreateDistributors: React.FC = () => {
	// funcion que hace el dispatch para crear distributors
	const addDistributors = useAddDistributors();

	const putDistributors = usePutDistributors();
	const resetPutDistributors = usePutReset();
	const resetCreateDistributors = useCreateReset();

	const { t } = useTranslation();
	const { addDistributorsStatusError, addDistributorsStatusRequest } =
		useAppSelector(selectDistributors);

	const { putDistributorsStatusError, putDistributorsStatusRequest } =
		useAppSelector(selectDistributors);

	type LocationStateProps = {
		distributors: any;
	};

	const location = useLocation().state as LocationStateProps;
	const distributorsModified = location?.distributors;
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = distributorsModified || {
		image: '',
		link: '',
		position: '',
		maxDistance: '',
	};

	const handleSubmitModify = async (data: any) => {
		const id = distributorsModified.distributorID;
		const { name, photo, maxDistributionDistance, priority, minimumPickUpPrice, minimumDeliveryPrice } = data;
		const request = { id, name, photo, maxDistributionDistance, priority, minimumPickUpPrice, minimumDeliveryPrice };
		putDistributors(request);
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (putDistributorsStatusRequest === 1) {
			showSuccessModify();
		} else if (putDistributorsStatusRequest === 2) {
			showErrorModify();
		}
	}, [putDistributorsStatusRequest]);

	useEffect(() => {
		if (addDistributorsStatusRequest === 1) {
			showSuccessCreate();
		} else if (addDistributorsStatusRequest === 2) {
			showErrorCreate();
		}
	}, [addDistributorsStatusRequest]);

	const showSuccessModify = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Distributors Modificado',
			detail: 'Se modifico con exito el distributors seleccionado',
			life: 2000,
		});
		resetPutDistributors();
		setTimeout(() => {
			navigate('/distributors');
		}, 1000);
	};
	const showErrorModify = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido modificar el distributors seleccionado',
		});
	};

	const showSuccessCreate = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Distributors Creado',
			detail: 'El Distributors fue creado con Exito',
			life: 3000,
		});
		resetCreateDistributors();
		setTimeout(() => {
			navigate('/distributors');
		}, 1000);
	};
	const showErrorCreate = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido crear el Distributors',
		});
	};
	//
	//reglas de los inputs
	const rules = {
		rulesImage: rulesLink(t),
		rulesLink: rulesLink(t),
		rulesPosition: rulesName(t),
	};

	const prioritySelectItems = [
		{ label: 'Ninguna', value: 0 },
		{ label: '1', value: 1 },
		{ label: '2', value: 2 },
		{ label: '3', value: 3 },
		{ label: '4', value: 4 },
	];
	const placeholder = 'Selecciona una prioridad';
	return (
		<ViewCreateDistributors
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			options={prioritySelectItems}
			placeholder={placeholder}
			addDistributors={addDistributors}
			error={addDistributorsStatusError}
			modifyDistributors={distributorsModified}
			handleSubmitModify={handleSubmitModify}
			toast={toast}
		/>
	);
};
