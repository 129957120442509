import { FunctionComponent } from 'react';
import { PanelMenuUserAdmin } from 'components';
import { ContainerSidebar } from 'UI';

type Props = {};

const ViewAllPanelMenu: FunctionComponent<Props> = (props) => (
	<div>
		<ContainerSidebar>
			<PanelMenuUserAdmin></PanelMenuUserAdmin>
		</ContainerSidebar>
	</div>
);

export default ViewAllPanelMenu;
