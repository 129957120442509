import { reIndexAsync } from './../../../data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useReIndex = () => {
	const dispatch = useAppDispatch();

	const ReIndex = (): void => {
		dispatch(reIndexAsync());
	};

	return ReIndex;
};

export default useReIndex;