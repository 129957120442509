import { useAppDispatch } from 'data/global/hooks';
import { DeleteBannerAsync } from 'data/global/features/BannerSlice/bannerSlice';

const useDeleteBanner = () => {
	const dispatch = useAppDispatch();

	const deleteBanner = (id: string): void => {
		dispatch(DeleteBannerAsync(id));
	};

	return deleteBanner;
};

export default useDeleteBanner;