// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateBrand_container__RJ9qn {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.CreateBrand_allContainer__MLY0S {\n\theight: 100%;\n\tbackground-color: white;\n}\n.CreateBrand_cardContainer__O8e0Y {\n\twidth: 60%;\n}\n\n.CreateBrand_card__ithZi {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.CreateBrand_inputContainer__htQPt {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.CreateBrand_label__kzoVJ {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/AddBrand/CreateBrand.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;AACA;CACC,YAAY;CACZ,uBAAuB;AACxB;AACA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;CACV,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.allContainer {\n\theight: 100%;\n\tbackground-color: white;\n}\n.cardContainer {\n\twidth: 60%;\n}\n\n.card {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.inputContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.label {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateBrand_container__RJ9qn",
	"allContainer": "CreateBrand_allContainer__MLY0S",
	"cardContainer": "CreateBrand_cardContainer__O8e0Y",
	"card": "CreateBrand_card__ithZi",
	"inputContainer": "CreateBrand_inputContainer__htQPt",
	"label": "CreateBrand_label__kzoVJ"
};
export default ___CSS_LOADER_EXPORT___;
