import { useAppDispatch } from 'data/global/hooks';
import { resetResendEmailStatusRequest } from 'data/global/features/ResendEmailSlice/resendEmailSlice';

const useResetResendEmail = () => {
	const dispatch = useAppDispatch();
	const ResetCreate = (): void => {
		dispatch(resetResendEmailStatusRequest());
	};
	return ResetCreate;
};

export default useResetResendEmail;
