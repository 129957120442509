import { FunctionComponent } from 'react';
import { Column, DataTable, Dialog } from 'UI';

type Props = {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	distributors?: any;
};

const ViewDataTableDistributors: FunctionComponent<Props> = (props) => (
	<div>
		<DataTable value={props.value} header={props.header}>
			<Column field='distributorID' header='ID'></Column>
			<Column field='distributorName' header='Name'></Column>
			<Column field='distributorPhoto' header='Photo'></Column>
			<Column field='maxDistributionDistance' header='MaxDist'></Column>
			<Column field='priority' header='Prioridad'></Column>
			<Column header='Acciones' body={props.body}></Column>
		</DataTable>
		<Dialog
			visible={props.visible}
			header='Eliminar Distributors'
			modal
			footer={props.footer}
			style={{ width: '450px' }}
			onHide={props.onHide}
		>
			<div className='confirmation-content'>
				<i
					className='pi pi-exclamation-triangle mr-3'
					style={{ fontSize: '2rem' }}
				/>
				{props.distributors && (
					<span>
						Estas seguro de eliminar el distributor <b>{props.distributors?.distributorEmail}</b>?
					</span>
				)}
			</div>
		</Dialog>
	</div>
);

export default ViewDataTableDistributors;