import { useAppDispatch } from 'data/global/hooks';
import { PutLibraryUserAsync } from 'data/global/features/LibraryUserSlice/libraryUserSlice';
import { PutLibraryUserRequest } from 'data/types/libraryUserTypes';

const usePutLibraryUser = () => {
	const dispatch = useAppDispatch();

	const putLibraryUser = (request: PutLibraryUserRequest): void => {
		dispatch(PutLibraryUserAsync(request));
	};

	return putLibraryUser;
};

export default usePutLibraryUser;
