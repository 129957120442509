import { useAppDispatch } from 'data/global/hooks';
import { ResendEmailAsync } from 'data/global/features/ResendEmailSlice/resendEmailSlice';
import { ResendEmailRequest } from 'data/types/resendEmailTypes';

export const useResendEmail = () => {
	const dispatch = useAppDispatch();

	const resendEmail = (resendEmail: ResendEmailRequest): void => {
		dispatch(ResendEmailAsync(resendEmail));
	};

	return resendEmail;
};

export default useResendEmail;
