import { FunctionComponent, useState } from 'react';
import { InputTextSigIn, Button, ErrorText, Dropdown, Toast } from 'UI';
import { AllPanelMenu, Logout } from 'components';
import { ControlProps, FormProps } from 'models/form';
import style from './CreatePresetCart.module.css';
import { TCreatePresetCart } from 'models/presetCartModels';

type Props = {
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	value?: string;
	options?: any[];
	placeholder?: string;
	modifyPresetCart?: any;
	onChange?: (e: any) => void;
	addPresetCart: (presetCart: TCreatePresetCart) => void;
	handleSubmitModify: (data: any, csvFileBase64: string) => void;
	handleSubmitCreate: (data: any, csvFileBase64: string) => void;
	toast?: any;
};

export const ViewCreatePresetCart: FunctionComponent<Props> = (props) => {
	const [csvFileBase64, setCsvFileBase64] = useState<string>('');
	const handleCsvFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (event) => {
				if (event.target) {
					const base64String = event.target.result as string;
					setCsvFileBase64(base64String.split('base64,')[1]);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<div className={style.allContainer}>
			<Logout />

			<div className={style.container}>
				<aside>
					<AllPanelMenu />
				</aside>
				<aside className={style.cardContainer}>
					<div className={style.card}>
						{props?.modifyPresetCart ? (
							<h1>Modificar PresetCart</h1>
						) : (
							<h1> Crear PresetCart</h1>
						)}
						<div className={style.inputContainer}>
							<label className={style.label}>Nombre Fantasia</label>
							<InputTextSigIn
								controllerProps={{
									control: props.controlProps.control,
									defaultValue: props.controlProps.defaultValue.nombreFantasia,
									name: 'nombreFantasia',
									rules: props.controlProps.rules.rulesName,
								}}
								errors={props.formProps.errors}
								type='text'
								placeholder='Nombre Fantasia'
								className={style.input}
							></InputTextSigIn>
							<label className={style.label}>
								Fecha Inicio (empieza ese dia a las 00:00)
							</label>
							<InputTextSigIn
								controllerProps={{
									control: props.controlProps.control,
									defaultValue: props.controlProps.defaultValue.fechaInicio,
									name: 'fechaInicio',
									rules: props.controlProps.rules.rulesLogo,
								}}
								errors={props.formProps.errors}
								type='date'
								placeholder='Fecha Inicio'
							></InputTextSigIn>
							<label className={style.label}>
								Fecha Fin (termina ese dia a las 23:59)
							</label>
							<InputTextSigIn
								controllerProps={{
									control: props.controlProps.control,
									defaultValue: props.controlProps.defaultValue.fechaFin,
									name: 'fechaFin',
									rules: props.controlProps.rules.rulesLogo,
								}}
								errors={props.formProps.errors}
								type='date'
								placeholder='Fecha Fin'
							></InputTextSigIn>
							{props?.modifyPresetCart ? (
								<div>
									<label className={style.label}>Enabled (encendido o apagado)</label>
									<Dropdown
										controllerProps={{
											control: props.controlProps.control,
											defaultValue: props.controlProps.defaultValue.enabled,
											name: 'enabled',
										}}
										options={props.options}
										placeholder={props.placeholder}
										className={style.input}
									></Dropdown>
								</div>
							) : (
								<div></div>
							)}
							<label className={style.label}></label>
							<Button
								label='Importar Productos'
								onClick={() => {
									document.getElementById('uploader')?.click();
								}}
							></Button>
							<input
								type='file'
								id='uploader'
								accept='.csv'
								onChange={handleCsvFileChange}
								className={style.input}
							/>
							{props?.modifyPresetCart ? (
								<Button
									label='Modificar PresetCart'
									className='p-button-lg'
									style={{ marginTop: '1.5em' }}
									onClick={props.formProps.handleSubmit((data: any) =>
										props.handleSubmitModify(data, csvFileBase64)
									)}
								></Button>
							) : (
								<Button
									label='Crear PresetCart'
									className='p-button-lg'
									style={{ marginTop: '1.5em' }}
									onClick={props.formProps.handleSubmit((data: any) =>
										props.handleSubmitCreate(data, csvFileBase64)
									)}
								></Button>
							)}
							<Toast toast={props.toast} />
						</div>

						{props?.error?.error && (
							<ErrorText style={{ marginTop: '1.5em' }}>
								{props?.error?.error}
							</ErrorText>
						)}
					</div>
				</aside>
			</div>
		</div>
	);
};
