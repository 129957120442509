import React, { useRef, useEffect } from 'react';
import { ViewCreateUser } from './view';
import { useForm } from 'react-hook-form';
import { rulesEmail, rulesPassword } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import {
	useCreateUser,
	usePutUser,
	usePutReset,
	useCreateReset,
} from './hooks';
import { selectUser } from 'data/global/features/UserSlice/userSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const CreateUser: React.FC = () => {
	// funcion que hace el dispatch para crear user
	const createUser = useCreateUser();

	const putUser = usePutUser();
	const resetPutUser = usePutReset();
	const resetCreateUser = useCreateReset();

	const { t } = useTranslation();
	const { createUserStatusError, createUserStatusRequest } =
		useAppSelector(selectUser);

	const { putUserStatusError, putUserStatusRequest } =
		useAppSelector(selectUser);

	type LocationStateProps = {
		user: any;
	};

	const location = useLocation().state as LocationStateProps;
	const userModified = location?.user;
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = {
		email: userModified || '',
		password: userModified || '',
		roles: userModified || '',
	};

	const handleSubmitModify = async (data: any) => {
		const id = userModified.adminUserId;
		const request = { id, email: data.email, password: data.password, roles: data.roles, referenceId: data.referenceId };
		putUser(request);
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (putUserStatusRequest === 1) {
			showSuccessModify();
		} else if (putUserStatusRequest === 2) {
			showErrorModify();
		}
	}, [putUserStatusRequest]);

	useEffect(() => {
		if (createUserStatusRequest === 1) {
			showSuccessCreate();
		} else if (createUserStatusRequest === 2) {
			showErrorCreate();
		}
	}, [createUserStatusRequest]);

	const showSuccessModify = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Usuario Modificado',
			detail: 'Se modifico con exito el usuario seleccionado',
			life: 2000,
		});
		resetPutUser();
		setTimeout(() => {
			navigate('/useradmin');
		}, 3000);
	};
	const showErrorModify = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido modificar el usuario seleccionado',
		});
	};

	const showSuccessCreate = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Usuario Creado',
			detail: 'El Usuario fue creado con Exito',
			life: 3000,
		});
		resetCreateUser();
		setTimeout(() => {
			navigate('/useradmin');
		}, 3000);
	};
	const showErrorCreate = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido crear el Usuario',
		});
	};
	//
	//reglas de los inputs
	const rules = {
		rulesEmail: rulesEmail(t),
		rulesPassword: rulesPassword(t),
	};

	const rolesSelectItems = [
		{ label: 'ROLE_DISTRIBUTOR', value: ['ROLE_DISTRIBUTOR'] },
		{ label: 'ROLE_MANUFACTURER', value: ['ROLE_MANUFACTURER'] },
		{ label: 'ROLE_OPERATIONS', value: ['ROLE_OPERATIONS'] },
		{ label: 'ROLE_SUPER_USER', value: ['ROLE_SUPER_USER'] },
	];
	const placeholder = 'Selecciona un Rol';
	return (
		<ViewCreateUser
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			options={rolesSelectItems}
			placeholder={placeholder}
			createUser={createUser}
			error={createUserStatusError}
			modifyUser={userModified}
			handleSubmitModify={handleSubmitModify}
			toast={toast}
		/>
	);
};
