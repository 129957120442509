import { ViewBanners } from "./view";
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectBanner } from 'data/global/features/BannerSlice/bannerSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteBanner, useGetBanners } from "./hooks";

export const Banners: React.FC = () => {
    const [deleteBannerDialog, setDeleteBannerDialog] = useState(false);
	const [deleteBannerSuccess, setDeleteBannerSuccess] = useState(false);

	const deleteBanner = useDeleteBanner();

	const navigate = useNavigate();

	useGetBanners();
	const { getAllBannerResponse, deleteBannerStatusRequest } =
		useAppSelector(selectBanner);

	useEffect(() => {
		if (deleteBannerStatusRequest === 1) {
			setDeleteBannerSuccess(true);
		}
	}, [deleteBannerStatusRequest]);

	useEffect(() => {
		if (deleteBannerSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [bannerDelete, setBannerDelete] = useState<null | { bannerId: any }>(
		null
	);

	const editBanner = (banner: any) => {
		navigate('/addbanner', { state: { banner: banner } });
	};

	//  delete
	const confirmDeleteBanner = (banner: any) => {
		setBannerDelete(banner);
		setDeleteBannerDialog(true);
	};

	const deleteBannerDispatch = () => {
		setDeleteBannerDialog(false);
		deleteBanner(bannerDelete?.bannerId);
	};
	const hideDeleteBannerDialog = () => {
		setDeleteBannerDialog(false);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'Banners'}
					textButton={'Agregar Banner'}
                    redirectTo={'/addBanner'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar banner'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editBanner(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar banner'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteBanner(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteBannerDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteBannerDispatch}
			/>
		</Fragment>
	);

    return <ViewBanners
        value={getAllBannerResponse}
        body={bodyTemplate}
        header={header}
        footer={deleteProductDialogFooter}
        visible={deleteBannerDialog}
        onHide={hideDeleteBannerDialog}
        banner={bannerDelete}
            />
};