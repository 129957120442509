import { ViewLibraryUsersDetail } from './view';
import React, { useState, useEffect } from 'react';
import config from 'config';

export const LibraryUsersDetail: React.FC = () => {
	const [jsonData, setJsonData] = useState<object | null>(null);
	const JWT = localStorage.getItem('jwt');
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					config.baseURL + '/api/v1/backoffice/library-user',
					{
						headers: {
							Authorization: `Bearer ${JWT}`,
						},
					}
				);
				const data = await response.json();
				setJsonData(data);
			} catch (error) {
				console.error('Error fetching JSON:', error);
			}
		};

		fetchData();
	}, []);

	return <ViewLibraryUsersDetail jsonData={jsonData} />;
};
