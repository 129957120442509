import { useAppDispatch } from 'data/global/hooks';
import { DeleteDistributorsAsync } from 'data/global/features/DistributorsSlice/distributorsSlice';

const useDeleteDistributors = () => {
	const dispatch = useAppDispatch();

	const deleteDistributors = (id: string): void => {
		dispatch(DeleteDistributorsAsync(id));
	};

	return deleteDistributors;
};

export default useDeleteDistributors;