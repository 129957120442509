import { useAppDispatch } from 'data/global/hooks';
import { PutBannerAsync } from 'data/global/features/BannerSlice/bannerSlice';
import { PutBannerRequest } from 'data/types/bannerTypes';

const usePutBanner = () => {
	const dispatch = useAppDispatch();

	const putBanner = (request: PutBannerRequest): void => {
		dispatch(PutBannerAsync(request));
	};

	return putBanner;
};

export default usePutBanner;
