// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateCategories_container__ZeMk4 {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.CreateCategories_allContainer__a4vFb {\n\theight: 100%;\n\tbackground-color: white;\n}\n.CreateCategories_cardContainer__PpL8o {\n\twidth: 60%;\n}\n\n.CreateCategories_card__oEY3S {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.CreateCategories_inputContainer__BS3r3 {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.CreateCategories_label__kqVUw {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/AddCategories/CreateCategories.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;AACA;CACC,YAAY;CACZ,uBAAuB;AACxB;AACA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;CACV,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.allContainer {\n\theight: 100%;\n\tbackground-color: white;\n}\n.cardContainer {\n\twidth: 60%;\n}\n\n.card {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.inputContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.label {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateCategories_container__ZeMk4",
	"allContainer": "CreateCategories_allContainer__a4vFb",
	"cardContainer": "CreateCategories_cardContainer__PpL8o",
	"card": "CreateCategories_card__oEY3S",
	"inputContainer": "CreateCategories_inputContainer__BS3r3",
	"label": "CreateCategories_label__kqVUw"
};
export default ___CSS_LOADER_EXPORT___;
