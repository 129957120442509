import { ViewManufacturer } from "./view";
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectManufacturer } from 'data/global/features/ManufacturersSlice/manufacturersSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteManufacturer, useGetManufacturer } from "./hooks";


export const Manufacturer: React.FC = () => {
    const [deleteManufacturerDialog, setDeleteManufacturerDialog] = useState(false);
	const [deleteManufacturerSuccess, setDeleteManufacturerSuccess] = useState(false);

	const deleteManufacturer = useDeleteManufacturer();

	const navigate = useNavigate();

	useGetManufacturer();
	const {getAllManufacturersResponse, deleteManufacturersStatusRequest } =
		useAppSelector(selectManufacturer);

		console.log(getAllManufacturersResponse, '1')

		console.log(deleteManufacturersStatusRequest, 'es')
	useEffect(() => {
		if (deleteManufacturersStatusRequest === 1) {
			setDeleteManufacturerSuccess(true);
		}
	}, [deleteManufacturersStatusRequest]);

	useEffect(() => {
		if (deleteManufacturerSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [manufacturerDelete, setManufacturerDelete] = useState<null | { manufacturerID: any }>(
		null
	);

	const editManufacturer = (manufacturer: any) => {
		navigate('/addManufacturers', { state: { manufacturer: manufacturer } });
	};

	//  delete
	const confirmDeleteManufacturer = (manufacturer: any) => {
		setManufacturerDelete(manufacturer);
		setDeleteManufacturerDialog(true);
	};

	const deleteManufacturerDispatch = () => {
		setDeleteManufacturerDialog(false);
		console.log('manufacturerID', manufacturerDelete);
		deleteManufacturer(manufacturerDelete?.manufacturerID);
	};
	const hideDeleteManufacturerDialog = () => {
		setDeleteManufacturerDialog(false);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'Manufacturer'}
					textButton={'Agregar Manufacturer'}
                    redirectTo={'/addManufacturers'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar manufacturer'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editManufacturer(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar manufacturer'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteManufacturer(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteManufacturerDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteManufacturerDispatch}
			/>
		</Fragment>
	);

    return <ViewManufacturer
        value={getAllManufacturersResponse}
        body={bodyTemplate}
        header={header}
        footer={deleteProductDialogFooter}
        visible={deleteManufacturerDialog}
        onHide={hideDeleteManufacturerDialog}
        manufacturers={manufacturerDelete}
            />
};