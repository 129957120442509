import { FunctionComponent } from 'react';
import ViewDataTablecatalogProducts from './view';

interface Props {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	catalogProducts?: object;
}

export const DataTablecatalogProducts: FunctionComponent<Props> = ({
	value,
	header,
	body,
	visible,
	footer,
	onHide,
	catalogProducts,
}) => {
	return (
		<ViewDataTablecatalogProducts
			value={value}
			header={header}
			body={body}
			visible={visible}
			footer={footer}
			onHide={onHide}
			catalogProducts={catalogProducts}
		/>
	);
};

export default DataTablecatalogProducts;