import { useAppDispatch } from 'data/global/hooks';
import { AddCategoriesAsync } from 'data/global/features/CategoriesSlice/categoriesSlice';
import { AddCategoriesRequest } from 'data/types/categoriesTypes';

export const useAddCategories = () => {
	const dispatch = useAppDispatch();

	const addCategories = (categories: AddCategoriesRequest): void => {
		dispatch(AddCategoriesAsync(categories));
	};

	return addCategories;
};

export default useAddCategories;
