import { FunctionComponent } from 'react';
import ViewDataTableCategories from './view';

interface Props {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	categories?: object;
}

export const DataTableCategories: FunctionComponent<Props> = ({
	value,
	header,
	body,
	visible,
	footer,
	onHide,
	categories,
}) => {
	return (
		<ViewDataTableCategories
			value={value}
			header={header}
			body={body}
			visible={visible}
			footer={footer}
			onHide={onHide}
			categories={categories}
		/>
	);
};

export default DataTableCategories;