import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import CatalogProductsApi from 'data/api/catalogProducts';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllCatalogProductsRequest,
	GetAllCatalogProductsResponse,
	AddCatalogProductsRequest,
	AddCatalogProductsResponse,
	DeleteCatalogProductsResponse,
	PutCatalogProductsRequest,
	PutCatalogProductsResponse,
} from 'data/types/catalogProductsTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TcatalogProductsSlice = {
	getAllCatalogProductsStatusRequest: StatusRequest;
	getAllCatalogProductsResponse?: GetAllCatalogProductsResponse;
	getAllCatalogProductsStatusError?: Failure;
	addCatalogProductsStatusRequest?: StatusRequest;
	addCatalogProductsStatusError?: Failure;
	deleteCatalogProductsStatusRequest?: StatusRequest;
	deleteCatalogProductsStatusError?: Failure;
	putCatalogProductsStatusRequest: StatusRequest;
	putCatalogProductsStatusError?: Failure;
};
const initialState: TcatalogProductsSlice = {
	getAllCatalogProductsStatusRequest: StatusRequest.initial,
	getAllCatalogProductsResponse: undefined,
	getAllCatalogProductsStatusError: undefined,
	addCatalogProductsStatusRequest: StatusRequest.initial,
	addCatalogProductsStatusError: undefined,
	deleteCatalogProductsStatusRequest: StatusRequest.initial,
	deleteCatalogProductsStatusError: undefined,
	putCatalogProductsStatusRequest: StatusRequest.initial,
	putCatalogProductsStatusError: undefined,
};

export const GetAllCatalogProductsAsync = createAsyncThunk<
	GetAllCatalogProductsResponse,
	GetAllCatalogProductsRequest,
	{ rejectValue: Failure }
>('auth/GetAllCatalogProductsAsync', async (request: GetAllCatalogProductsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllCatalogProductsResponse> =
			await CatalogProductsApi.getAllCatalogProducts();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get CatalogProducts');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddCatalogProductsAsync = createAsyncThunk<
	AddCatalogProductsResponse,
	AddCatalogProductsRequest,
	{ rejectValue: Failure }
>('catalogProducts/AddCatalogProductsAsync', async (request: AddCatalogProductsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, AddCatalogProductsResponse> =
			await CatalogProductsApi.addCatalogProducts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post CatalogProducts');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteCatalogProductsAsync = createAsyncThunk<
	DeleteCatalogProductsResponse,
	string,
	{ rejectValue: Failure }
>('catalogProducts/DeleteCatalogProductsAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteCatalogProductsResponse> =
			await CatalogProductsApi.deleteCatalogProducts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutCatalogProductsAsync = createAsyncThunk<
	PutCatalogProductsResponse,
	PutCatalogProductsRequest,
	{ rejectValue: Failure }
>('catalogProducts/PutCatalogProductsAsync', async (request: PutCatalogProductsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutCatalogProductsResponse> =
			await CatalogProductsApi.putCatalogProducts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const CatalogProductsSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putCatalogProductsStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addCatalogProductsStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllCatalogProductsAsync.pending, (state) => {
			state.getAllCatalogProductsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllCatalogProductsAsync.fulfilled, (state, action) => {
			state.getAllCatalogProductsStatusRequest = StatusRequest.fulfilled;
			state.getAllCatalogProductsResponse = action.payload;
		});
		builder.addCase(GetAllCatalogProductsAsync.rejected, (state, action) => {
			state.getAllCatalogProductsStatusRequest = StatusRequest.rejected;
			state.getAllCatalogProductsStatusError = action.payload;
		});
		builder.addCase(AddCatalogProductsAsync.pending, (state) => {
			state.addCatalogProductsStatusRequest = StatusRequest.pending;
			state.addCatalogProductsStatusError = undefined;
		});
		builder.addCase(AddCatalogProductsAsync.fulfilled, (state, action) => {
			state.addCatalogProductsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddCatalogProductsAsync.rejected, (state, action) => {
			state.addCatalogProductsStatusRequest = StatusRequest.rejected;
			state.addCatalogProductsStatusError = action.payload;
		});
		builder.addCase(DeleteCatalogProductsAsync.pending, (state) => {
			state.deleteCatalogProductsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteCatalogProductsAsync.fulfilled, (state, action) => {
			state.deleteCatalogProductsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteCatalogProductsAsync.rejected, (state, action) => {
			state.deleteCatalogProductsStatusRequest = StatusRequest.rejected;
			state.deleteCatalogProductsStatusError = action.payload;
		});
		builder.addCase(PutCatalogProductsAsync.pending, (state) => {
			state.putCatalogProductsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutCatalogProductsAsync.fulfilled, (state, action) => {
			state.putCatalogProductsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutCatalogProductsAsync.rejected, (state, action) => {
			state.putCatalogProductsStatusRequest = StatusRequest.rejected;
			state.putCatalogProductsStatusError = action.payload;
		});
	},
});

export const selectCatalogProducts = (state: RootState) => state.catalogProducts;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	CatalogProductsSlice.actions;
export default CatalogProductsSlice.reducer;
