import { useAppDispatch } from 'data/global/hooks';
import { CreateUserAsync } from 'data/global/features/UserSlice/userSlice';
import { CreateUserRequest } from 'data/types/userTypes';

export const useCreateUser = () => {
	const dispatch = useAppDispatch();

	const createUser = (user: CreateUserRequest): void => {
		dispatch(CreateUserAsync(user));
	};

	return createUser;
};

export default useCreateUser;
