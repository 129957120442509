import { useAppDispatch } from 'data/global/hooks';
import { resetCreateStatusRequest } from 'data/global/features/BannerSlice/bannerSlice';

const useResetCreateBanner = () => {
	const dispatch = useAppDispatch();
	const ResetCreate = (): void => {
		dispatch(resetCreateStatusRequest());
	};
	return ResetCreate;
};

export default useResetCreateBanner;
