import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllMasterProductsRequest,
	GetAllMasterProductsResponse,
	AddMasterProductsRequest,
	AddMasterProductsResponse,
	DeleteMasterProductsResponse,
	PutMasterProductsRequest,
	PutMasterProductsResponse,
} from 'data/types/masterProductsTypes';
import { Either } from 'models/either';

export const getAllMasterProducts = async (): Promise<
	Either<Failure, GetAllMasterProductsResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllMasterProductsRequest, GetAllMasterProductsResponse>({
			url: '/api/v1/backoffice/products',
			secure: true,
		})
	);
};
export const addMasterProducts = async (
	request: AddMasterProductsRequest
): Promise<Either<Failure, AddMasterProductsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<AddMasterProductsRequest, AddMasterProductsResponse>({
			url: '/api/v1/backoffice/products',
			request,
		})
	);
};

export const deleteMasterProducts = async (
	id: string
): Promise<Either<Failure, DeleteMasterProductsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<string, DeleteMasterProductsResponse>({
			url: `/api/v1/backoffice/products/${id}`,
		})
	);
};

export const putMasterProducts = async (
	request: PutMasterProductsRequest
): Promise<Either<Failure, PutMasterProductsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutMasterProductsRequest, PutMasterProductsResponse>({
			url: `/api/v1/backoffice/products/${request.id}`,
			request,
		})
	);
};

const MasterProductsApi = {
	getAllMasterProducts,
	addMasterProducts,
	deleteMasterProducts,
	putMasterProducts,
};

export default MasterProductsApi;
