import React, { Fragment, useEffect } from 'react';
import { ViewSignIn } from './view';
import { useForm } from 'react-hook-form';
import { useSignIn } from './hooks';
import { rulesEmail, rulesPassword } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'data/global/hooks';
import { selectAuth } from 'data/global/features/AuthSlice/authSlice';
import { useNavigate } from 'react-router-dom';

export const SignIn: React.FC = () => {
	const signIn = useSignIn();
	const { t } = useTranslation();

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = { email: '', password: '' };
	const { signInStatusError, signInStatusRequest, isLogin } =
		useAppSelector(selectAuth);

	const rules = {
		rulesEmail: rulesEmail(t),
		rulesPassword: rulesPassword(t),
	};

	const navigate = useNavigate();
	useEffect(() => {
		if (isLogin) {
			navigate('/dashboard');
		}
	}, [isLogin, navigate]);

	return (
		<Fragment>
			<ViewSignIn
				signIn={signIn}
				formProps={{ handleSubmit, errors, isSubmitting, isValid }}
				controlProps={{ control, defaultValue, rules }}
				error={signInStatusError}
			/>
		</Fragment>
	);
};
