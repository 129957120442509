import { FunctionComponent } from 'react';
import { DataTable, Column, Button, Dialog } from 'UI';
import { HeaderDataTable } from 'components';
import style from './DataTableUser.module.css';

type Props = {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	user?: any;
};

const ViewDataTableUser: FunctionComponent<Props> = (props) => (
	<div>
		<DataTable value={props.value} header={props.header}>
			<Column field='adminUserId' header='ID Usuario'></Column>
			<Column field='email' header='Email'></Column>
			<Column field='roles' header='Rol'></Column>
			<Column header='Acciones' body={props.body}></Column>
		</DataTable>
		<Dialog
			visible={props.visible}
			header='Eliminar Usuario'
			modal
			footer={props.footer}
			style={{ width: '450px' }}
			onHide={props.onHide}
		>
			<div className='confirmation-content'>
				<i
					className='pi pi-exclamation-triangle mr-3'
					style={{ fontSize: '2rem' }}
				/>
				{props.user && (
					<span>
						Estas seguro de eliminar al usuario <b>{props.user?.email}</b>?
					</span>
				)}
			</div>
		</Dialog>
	</div>
);

export default ViewDataTableUser;
