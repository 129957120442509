import { useAppDispatch } from 'data/global/hooks';
import { AddBannerAsync } from 'data/global/features/BannerSlice/bannerSlice';
import { AddBannerRequest } from 'data/types/bannerTypes';

export const useAddBanner = () => {
	const dispatch = useAppDispatch();

	const addBanner = (banner: AddBannerRequest): void => {
		dispatch(AddBannerAsync(banner));
	};

	return addBanner;
};

export default useAddBanner;
