import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import CategoriesApi from 'data/api/categories';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllCategoriesRequest,
	GetAllCategoriesResponse,
	AddCategoriesRequest,
	AddCategoriesResponse,
	DeleteCategoriesResponse,
	PutCategoriesRequest,
	PutCategoriesResponse,
} from 'data/types/categoriesTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TcategoriesSlice = {
	getAllCategoriesStatusRequest: StatusRequest;
	getAllCategoriesResponse?: GetAllCategoriesResponse;
	getAllCategoriesStatusError?: Failure;
	addCategoriesStatusRequest?: StatusRequest;
	addCategoriesStatusError?: Failure;
	deleteCategoriesStatusRequest?: StatusRequest;
	deleteCategoriesStatusError?: Failure;
	putCategoriesStatusRequest: StatusRequest;
	putCategoriesStatusError?: Failure;
};
const initialState: TcategoriesSlice = {
	getAllCategoriesStatusRequest: StatusRequest.initial,
	getAllCategoriesResponse: undefined,
	getAllCategoriesStatusError: undefined,
	addCategoriesStatusRequest: StatusRequest.initial,
	addCategoriesStatusError: undefined,
	deleteCategoriesStatusRequest: StatusRequest.initial,
	deleteCategoriesStatusError: undefined,
	putCategoriesStatusRequest: StatusRequest.initial,
	putCategoriesStatusError: undefined,
};

export const GetAllCategoriesAsync = createAsyncThunk<
	GetAllCategoriesResponse,
	GetAllCategoriesRequest,
	{ rejectValue: Failure }
>('auth/GetAllCategoriesAsync', async (request: GetAllCategoriesRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllCategoriesResponse> =
			await CategoriesApi.getAllCategories();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get Categories');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddCategoriesAsync = createAsyncThunk<
	AddCategoriesResponse,
	AddCategoriesRequest,
	{ rejectValue: Failure }
>('categories/AddCategoriesAsync', async (request: AddCategoriesRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, AddCategoriesResponse> =
			await CategoriesApi.addCategories(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post Categories');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteCategoriesAsync = createAsyncThunk<
	DeleteCategoriesResponse,
	string,
	{ rejectValue: Failure }
>('categories/DeleteCategoriesAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteCategoriesResponse> =
			await CategoriesApi.deleteCategories(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutCategoriesAsync = createAsyncThunk<
	PutCategoriesResponse,
	PutCategoriesRequest,
	{ rejectValue: Failure }
>('categories/PutCategoriesAsync', async (request: PutCategoriesRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutCategoriesResponse> =
			await CategoriesApi.putCategories(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const CategoriesSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putCategoriesStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addCategoriesStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllCategoriesAsync.pending, (state) => {
			state.getAllCategoriesStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllCategoriesAsync.fulfilled, (state, action) => {
			state.getAllCategoriesStatusRequest = StatusRequest.fulfilled;
			state.getAllCategoriesResponse = action.payload;
		});
		builder.addCase(GetAllCategoriesAsync.rejected, (state, action) => {
			state.getAllCategoriesStatusRequest = StatusRequest.rejected;
			state.getAllCategoriesStatusError = action.payload;
		});
		builder.addCase(AddCategoriesAsync.pending, (state) => {
			state.addCategoriesStatusRequest = StatusRequest.pending;
			state.addCategoriesStatusError = undefined;
		});
		builder.addCase(AddCategoriesAsync.fulfilled, (state, action) => {
			state.addCategoriesStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddCategoriesAsync.rejected, (state, action) => {
			state.addCategoriesStatusRequest = StatusRequest.rejected;
			state.addCategoriesStatusError = action.payload;
		});
		builder.addCase(DeleteCategoriesAsync.pending, (state) => {
			state.deleteCategoriesStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteCategoriesAsync.fulfilled, (state, action) => {
			state.deleteCategoriesStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteCategoriesAsync.rejected, (state, action) => {
			state.deleteCategoriesStatusRequest = StatusRequest.rejected;
			state.deleteCategoriesStatusError = action.payload;
		});
		builder.addCase(PutCategoriesAsync.pending, (state) => {
			state.putCategoriesStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutCategoriesAsync.fulfilled, (state, action) => {
			state.putCategoriesStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutCategoriesAsync.rejected, (state, action) => {
			state.putCategoriesStatusRequest = StatusRequest.rejected;
			state.putCategoriesStatusError = action.payload;
		});
	},
});

export const selectCategories = (state: RootState) => state.categories;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	CategoriesSlice.actions;
export default CategoriesSlice.reducer;
