import { FunctionComponent } from 'react';
import { Card as CardPrime, CardProps } from 'primereact/card';

type Props = {
	header?: any;
	title?: any;
	className?: string;
} & CardProps;

export const Card: FunctionComponent<Props> = ({ ...props }) => (
	<CardPrime title={props.title} {...props}>
		{props.children}
	</CardPrime>
);
