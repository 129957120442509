// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LibraryUser_container__8gkNC {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n\n.LibraryUser_containerDataTable__OYdmg {\n\twidth: 80%;\n}\n\n.LibraryUser_buttons__RZyWa {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/pages/LibraryUser/LibraryUser.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n\n.containerDataTable {\n\twidth: 80%;\n}\n\n.buttons {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LibraryUser_container__8gkNC",
	"containerDataTable": "LibraryUser_containerDataTable__OYdmg",
	"buttons": "LibraryUser_buttons__RZyWa"
};
export default ___CSS_LOADER_EXPORT___;
