import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import LibraryUserApi from 'data/api/libraryUser';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllLibraryUserRequest,
	GetAllLibraryUserResponse,
	AddLibraryUserRequest,
	AddLibraryUserResponse,
	DeleteLibraryUserResponse,
	PutLibraryUserRequest,
	PutLibraryUserResponse,
	ImpersonateLibraryUserResponse,
} from 'data/types/libraryUserTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TlibraryUserSlice = {
	getAllLibraryUserStatusRequest: StatusRequest;
	getAllLibraryUserResponse?: GetAllLibraryUserResponse;
	getAllLibraryUserStatusError?: Failure;
	addLibraryUserStatusRequest?: StatusRequest;
	addLibraryUserStatusError?: Failure;
	deleteLibraryUserStatusRequest?: StatusRequest;
	deleteLibraryUserStatusError?: Failure;
	putLibraryUserStatusRequest: StatusRequest;
	putLibraryUserStatusError?: Failure;
};
const initialState: TlibraryUserSlice = {
	getAllLibraryUserStatusRequest: StatusRequest.initial,
	getAllLibraryUserResponse: undefined,
	getAllLibraryUserStatusError: undefined,
	addLibraryUserStatusRequest: StatusRequest.initial,
	addLibraryUserStatusError: undefined,
	deleteLibraryUserStatusRequest: StatusRequest.initial,
	deleteLibraryUserStatusError: undefined,
	putLibraryUserStatusRequest: StatusRequest.initial,
	putLibraryUserStatusError: undefined,
};

export const GetAllLibraryUserAsync = createAsyncThunk<
	GetAllLibraryUserResponse,
	GetAllLibraryUserRequest,
	{ rejectValue: Failure }
>('auth/GetAllLibraryUserAsync', async (request: GetAllLibraryUserRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllLibraryUserResponse> =
			await LibraryUserApi.getAllLibraryUser();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get LibraryUser');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddLibraryUserAsync = createAsyncThunk<
	AddLibraryUserResponse,
	AddLibraryUserRequest,
	{ rejectValue: Failure }
>('libraryUser/AddLibraryUserAsync', async (request: AddLibraryUserRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, AddLibraryUserResponse> =
			await LibraryUserApi.addLibraryUser(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post LibraryUser');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteLibraryUserAsync = createAsyncThunk<
	DeleteLibraryUserResponse,
	string,
	{ rejectValue: Failure }
>('libraryUser/DeleteLibraryUserAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteLibraryUserResponse> =
			await LibraryUserApi.deleteLibraryUser(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const ImpersonateLibraryUserAsync = createAsyncThunk<
	ImpersonateLibraryUserResponse,
	string,
	{ rejectValue: Failure }
>('libraryUser/ImpersonateLibraryUserAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ImpersonateLibraryUserResponse> =
			await LibraryUserApi.impersonateLibraryUser(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			window.location.replace(response.toString());
			return response;
		}
		const error = unwrapEither(eitherResponse);
		console.log(error, "errors");
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutLibraryUserAsync = createAsyncThunk<
	PutLibraryUserResponse,
	PutLibraryUserRequest,
	{ rejectValue: Failure }
>('libraryUser/PutLibraryUserAsync', async (request: PutLibraryUserRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutLibraryUserResponse> =
			await LibraryUserApi.putLibraryUser(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const LibraryUserSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putLibraryUserStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addLibraryUserStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllLibraryUserAsync.pending, (state) => {
			state.getAllLibraryUserStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllLibraryUserAsync.fulfilled, (state, action) => {
			state.getAllLibraryUserStatusRequest = StatusRequest.fulfilled;
			state.getAllLibraryUserResponse = action.payload;
		});
		builder.addCase(GetAllLibraryUserAsync.rejected, (state, action) => {
			state.getAllLibraryUserStatusRequest = StatusRequest.rejected;
			state.getAllLibraryUserStatusError = action.payload;
		});
		builder.addCase(AddLibraryUserAsync.pending, (state) => {
			state.addLibraryUserStatusRequest = StatusRequest.pending;
			state.addLibraryUserStatusError = undefined;
		});
		builder.addCase(AddLibraryUserAsync.fulfilled, (state, action) => {
			state.addLibraryUserStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddLibraryUserAsync.rejected, (state, action) => {
			state.addLibraryUserStatusRequest = StatusRequest.rejected;
			state.addLibraryUserStatusError = action.payload;
		});
		builder.addCase(DeleteLibraryUserAsync.pending, (state) => {
			state.deleteLibraryUserStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteLibraryUserAsync.fulfilled, (state, action) => {
			state.deleteLibraryUserStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteLibraryUserAsync.rejected, (state, action) => {
			state.deleteLibraryUserStatusRequest = StatusRequest.rejected;
			state.deleteLibraryUserStatusError = action.payload;
		});
		builder.addCase(PutLibraryUserAsync.pending, (state) => {
			state.putLibraryUserStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutLibraryUserAsync.fulfilled, (state, action) => {
			state.putLibraryUserStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutLibraryUserAsync.rejected, (state, action) => {
			state.putLibraryUserStatusRequest = StatusRequest.rejected;
			state.putLibraryUserStatusError = action.payload;
		});
	},
});

export const selectLibraryUser = (state: RootState) => state.libraryUser;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	LibraryUserSlice.actions;
export default LibraryUserSlice.reducer;
