import { useAppDispatch } from 'data/global/hooks';
import { GetAllBrandsAsync } from 'data/global/features/BrandsSlice/brandsSlice';
import { useEffect } from 'react';

const useGetBrands = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllBrandsAsync({}));
	}, [dispatch]);
};

export default useGetBrands;