import { useAppDispatch } from 'data/global/hooks';
import { resetGetExportStatusRequest } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';

const useResetGetExport = () => {
	const dispatch = useAppDispatch();
	const ResetCreate = (): void => {
		dispatch(resetGetExportStatusRequest());
	};
	return ResetCreate;
};

export default useResetGetExport;
