import { useAppDispatch } from 'data/global/hooks';
import { PutBrandsAsync } from 'data/global/features/BrandsSlice/brandsSlice';
import { PutBrandsRequest } from 'data/types/brandsTypes';

const usePutBrand = () => {
	const dispatch = useAppDispatch();

	const putBrand = (request: PutBrandsRequest): void => {
		dispatch(PutBrandsAsync(request));
	};

	return putBrand;
};

export default usePutBrand;
