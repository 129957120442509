import { GetCategoriesExportAsync } from './../../../data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useCategoriesExport = () => {
	const dispatch = useAppDispatch();

	const GetCategoriesExport = (): void => {
		dispatch(GetCategoriesExportAsync());
	};

	return GetCategoriesExport;
};

export default useCategoriesExport;