import { ViewLibraryUser } from './view';
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectLibraryUser } from 'data/global/features/LibraryUserSlice/libraryUserSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteLibraryUser, useGetLibraryUser, useImpersonateLibraryUser } from './hooks';

export const LibraryUser: React.FC = () => {
	const [deleteLibraryUserDialog, setDeleteLibraryUserDialog] = useState(false);
	const [deleteLibraryUserSuccess, setDeleteLibraryUserSuccess] =
		useState(false);

	const deleteLibraryUser = useDeleteLibraryUser();
	const impersonateLibraryUser = useImpersonateLibraryUser();

	const navigate = useNavigate();

	useGetLibraryUser();
	const { getAllLibraryUserResponse, deleteLibraryUserStatusRequest } =
		useAppSelector(selectLibraryUser);

	useEffect(() => {
		if (deleteLibraryUserStatusRequest === 1) {
			setDeleteLibraryUserSuccess(true);
		}
	}, [deleteLibraryUserStatusRequest]);

	useEffect(() => {
		if (deleteLibraryUserSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [libraryUserDelete, setLibraryUserDelete] = useState<null | {
		libraryUserId: any;
	}>(null);

	const editLibraryUser = (libraryUser: any) => {
		navigate('/addlibraryUser', { state: { libraryUser: libraryUser } });
	};

	//  delete
	const confirmDeleteLibraryUser = (libraryUser: any) => {
		setLibraryUserDelete(libraryUser);
		setDeleteLibraryUserDialog(true);
	};

	const deleteLibraryUserDispatch = () => {
		setDeleteLibraryUserDialog(false);
		deleteLibraryUser(libraryUserDelete?.libraryUserId);
	};
	const hideDeleteLibraryUserDialog = () => {
		setDeleteLibraryUserDialog(false);
	};

	// impersonate
	const impersonateLibraryUserDispatch = (libraryUser: any) => {
		impersonateLibraryUser(libraryUser.libraryUserId);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'LibraryUser'}
					textButton={'Agregar LibraryUser'}
					redirectTo={'/addLibraryUser'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-user'
						tooltip='Impersonar libraryUser'
						tooltipOptions={{ position: 'top' }}
						onClick={() => impersonateLibraryUserDispatch(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar libraryUser'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editLibraryUser(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar libraryUser'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteLibraryUser(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteLibraryUserDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteLibraryUserDispatch}
			/>
		</Fragment>
	);

	return (
		<ViewLibraryUser
			value={getAllLibraryUserResponse}
			body={bodyTemplate}
			header={header}
			footer={deleteProductDialogFooter}
			visible={deleteLibraryUserDialog}
			onHide={hideDeleteLibraryUserDialog}
			libraryUser={libraryUserDelete}
		/>
	);
};
