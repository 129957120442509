import { useAppDispatch } from 'data/global/hooks';
import { DeletePresetCartsAsync } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';

const useDeletePresetCarts = () => {
	const dispatch = useAppDispatch();

	const deletePresetCarts = (id: string): void => {
		dispatch(DeletePresetCartsAsync(id));
	};

	return deletePresetCarts;
};

export default useDeletePresetCarts;