import { useAppDispatch } from 'data/global/hooks';
import { GetAllManufacturersAsync } from 'data/global/features/ManufacturersSlice/manufacturersSlice';
import { useEffect } from 'react';

const useGetManufacturer = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllManufacturersAsync({}));
	}, [dispatch]);
};

export default useGetManufacturer;