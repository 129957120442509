import { AllPanelMenu, Logout } from "components";
import { FunctionComponent } from "react";
import style from './Importer.module.css';

type Props = {
	value?: any;
    onUpload: (data: string, type: ImportTypes) => void;
    onExport: (type: ImportTypes) => void;
    onExample: (type: ImportTypes) => void;
    onClear: (type: string) => void;
    onReindex: () => void;
    onGenerateDescription: () => void;
};

export enum ImportTypes {
    CATEGORIES,
    DISTRIBUTORS,
    BRANDS,
    MASTER_PRODUCTS,
    CATALOG_PRODUCTS,
    ORDERS,
    UK
}

export const ViewImporter: FunctionComponent<Props> = (props) => {
    let field: ImportTypes = ImportTypes.UK;
	const role = localStorage.getItem('role')
    return (
    <div id="sarasa">
        <Logout />
        <aside>
            <AllPanelMenu />
        </aside>
        <aside className={style.containerDataTable}>
            <h3>Importer options <span className={style.debugClass}><input type="checkbox" id="debugger"/>&nbsp;(debug)</span></h3>
            <section>
                <div className={style.blockData} style={role=='ROLE_MANUFACTURER' || role=='ROLE_DISTRIBUTOR' ?{ display:'none'} : {display : 'flex'} }>
                    <h4>Categorías</h4>
                    <button onClick={()=>{
                        document.getElementById('uploader')?.click();
                        field=ImportTypes.CATEGORIES
                    }}>Importar</button>
                    <button onClick={()=>{
                        props.onExport(ImportTypes.CATEGORIES)
                    }}>Exportar</button>
                    {/* <button onClick={()=>{
                        props.onExample(ImportTypes.CATEGORIES)
                    }}>Exportar Ejemplo</button> */}
                </div>
                <div className={style.blockData} style={role=='ROLE_MANUFACTURER' || role=='ROLE_DISTRIBUTOR' ?{ display:'none'} : {display : 'flex'}}>
                    <h4>Distribuidores</h4>
                    <button onClick={()=>{
                        document.getElementById('uploader')?.click();
                        field=ImportTypes.DISTRIBUTORS
                    }}>Importar</button>
                    <button onClick={()=>{
                        props.onExport(ImportTypes.DISTRIBUTORS)
                    }}>Exportar</button>
                    {/* <button onClick={()=>{
                        props.onExample(ImportTypes.DISTRIBUTORS)
                    }}>Exportar Ejemplo</button> */}
                </div>
                <div className={style.blockData} style={role=='ROLE_DISTRIBUTOR' ?{ display:'none'} : {display : 'flex'}}>
                    <h4>Marcas</h4>
                    <button style={role=='ROLE_MANUFACTURER'?{ display:'none'} : {display : 'flex'}} onClick={()=>{
                        document.getElementById('uploader')?.click();
                        field=ImportTypes.BRANDS
                    }}>Importar</button>
                    <button onClick={()=>{
                        props.onExport(ImportTypes.BRANDS)
                    }}>Exportar</button>
                    {/* <button onClick={()=>{
                        props.onExample(ImportTypes.BRANDS)
                    }}>Exportar Ejemplo</button> */}
                </div>
            </section>
            <hr />
            <section>
                <div className={style.blockData} style={role=='ROLE_DISTRIBUTOR' ?{ display:'none'} : {display : 'flex'}}>
                    <h4>Master Products</h4>
                    <button style={role=='ROLE_MANUFACTURER'?{ display:'none'} : {display : 'null'}} onClick={()=>{
                        document.getElementById('uploader')?.click();
                        field=ImportTypes.MASTER_PRODUCTS
                    }}>Importar</button>
                    <button onClick={()=>{
                        props.onExport(ImportTypes.MASTER_PRODUCTS)
                    }}>Exportar</button>
                </div>
			
                <div className={style.blockData}>
                    <h4>Catalog Products</h4>
                    <button style={role=='ROLE_MANUFACTURER' || role=='ROLE_DISTRIBUTOR' ?{ display:'none'} : {display : 'null'}} onClick={()=>{
                        document.getElementById('uploader')?.click();
                        field=ImportTypes.CATALOG_PRODUCTS
                    }}>Importar</button>
                    <button onClick={()=>{
                        props.onExport(ImportTypes.CATALOG_PRODUCTS)
                    }}>Exportar</button>
                </div>
                <div className={style.blockData} style={role=='ROLE_MANUFACTURER'?{ display:'none'} : {display : 'flex'} }>
                    <h4>Orders</h4>
                    {/* <button onClick={()=>{
                        document.getElementById('uploader')?.click();
                        field=ImportTypes.CATALOG_PRODUCTS
                    }}>Importar</button> */}
                    <button onClick={()=>{
                        props.onExport(ImportTypes.ORDERS)
                    }}>Exportar</button>
                </div>
            </section>
            <section>
                <div className={style.blockData} style={role=='ROLE_MANUFACTURER' || role=='ROLE_DISTRIBUTOR'?{ display:'none'} : {display : 'flex'} }>
                    <h4>Cleaner</h4>
                    <select id="cleaner_select">
                        <option value="categoria">
                            Categorias
                        </option>
                        <option value="distribuidores">
                            Distribuidores
                        </option>
                        <option value="brands">
                            Marcas
                        </option>
                        <option value="master_products">
                            Master Products
                        </option>
                        <option value="catalog_product">
                            Catalog Product
                        </option>
                        <option value="all">
                            Todo
                        </option>
                    </select>
                    <button onClick={()=>{
                        // eslint-disable-next-line no-restricted-globals
                        if(confirm('Estas seguro de borrar ' + (document.getElementById('cleaner_select') as any).value)) {
                            props.onClear((document.getElementById('cleaner_select') as any).value);
                        }
                    }}>borrar</button>
                    <button onClick={()=>{
                        props.onReindex();
                    }}>FULL-REINDEX</button>
                    <button onClick={()=>{
                        props.onGenerateDescription();
                    }}>GENERAR DESCRIPCIONES CON AI</button>
                </div>
            </section>
            <input type="file" id="uploader" accept=".csv" onChange={(e) => {
                const fr = new FileReader();
                fr.onloadend=(data)=>{
                    const fileB64 =(data.target?.result as string).split('base64,')[1];
                    props.onUpload(fileB64, field);
                    (document.getElementById('uploader') as any).type = '';
                    (document.getElementById('uploader') as any).type = 'file';
                }
                fr.readAsDataURL(e.target.files![0])
            }} className={style.input}/>
        </aside>
    </div>
)};