import React, { useRef, useEffect } from 'react';
import { ViewResendEmail } from './view';
import { useForm } from 'react-hook-form';
import { rulesName, rulesLink } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import { useResendEmail, useResetResendEmail } from './hooks';
import { selectResendEmail } from 'data/global/features/ResendEmailSlice/resendEmailSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const ResendEmail: React.FC = () => {
	// funcion que hace el dispatch para reenviar email
	const resendEmail = useResendEmail();
	const resetResendEmail = useResetResendEmail();

	const { t } = useTranslation();
	const { resendEmailStatusError, resendEmailStatusRequest } =
		useAppSelector(selectResendEmail);

	type LocationStateProps = {
		resendEmail: any;
	};

	const location = useLocation().state as LocationStateProps;
	const resendEmailModified = location?.resendEmail;
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = resendEmailModified || {
		subOrderId: '',
		emails: '',
		attachedFile: '',
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (resendEmailStatusRequest === 1) {
			showSuccess();
		} else if (resendEmailStatusRequest === 2) {
			showError();
		}
	}, [resendEmailStatusRequest]);

	const showSuccess = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Email reenviado',
			detail: 'El email fue reenviado con Exito',
			life: 3000,
		});
		resetResendEmail();
		setTimeout(() => {
			navigate('/resendEmail');
		}, 1000);
	};
	const showError = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido reenviar el email',
		});
	};
	//
	//reglas de los inputs
	const rules = {
		rulesImage: rulesLink(t),
		rulesLink: rulesLink(t),
		rulesPosition: rulesName(t),
	};

	const attachedFileSelectItems = [
		{ label: 'SI', value: true },
		{ label: 'NO', value: false },
	];
	return (
		<ViewResendEmail
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			options={attachedFileSelectItems}
			resendEmail={resendEmail}
			error={resendEmailStatusError}
			toast={toast}
		/>
	);
};
