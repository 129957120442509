import { FunctionComponent } from 'react';
import { Column as ColumnPrime, ColumnProps } from 'primereact/column';

type Props = {
	field?: string;
	header?: string;
	body?: any;
} & ColumnProps;

export const Column: FunctionComponent<Props> = ({ ...props }) => (
	<ColumnPrime
		field={props.field}
		header={props.header}
		body={props.body}
		{...props}
	>
		{props.children}
	</ColumnPrime>
);
