import { ViewBrandss } from "./view";
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectBrands } from 'data/global/features/BrandsSlice/brandsSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteBrands, useGetBrands } from "./hooks";

export const Brands: React.FC = () => {
    const [deleteBrandsDialog, setDeleteBrandsDialog] = useState(false);
	const [deleteBrandsSuccess, setDeleteBrandsSuccess] = useState(false);

	const deleteBrands = useDeleteBrands();

	const navigate = useNavigate();

	useGetBrands();
	const { getAllBrandsResponse, deleteBrandsStatusRequest } =
		useAppSelector(selectBrands);

	useEffect(() => {
		if (deleteBrandsStatusRequest === 1) {
			setDeleteBrandsSuccess(true);
		}
	}, [deleteBrandsStatusRequest]);

	useEffect(() => {
		if (deleteBrandsSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [brandsDelete, setBrandsDelete] = useState<null | { brandID: any }>(
		null
	);

	const editBrands = (brands: any) => {
		console.log('Tuvi', brands);
		navigate('/addbrand', { state: { brands: brands } });
	};

	//  delete
	const confirmDeleteBrands = (brands: any) => {
		setBrandsDelete(brands);
		setDeleteBrandsDialog(true);
	};

	const deleteBrandsDispatch = () => {
		setDeleteBrandsDialog(false);
		deleteBrands(brandsDelete?.brandID);
	};
	const hideDeleteBrandsDialog = () => {
		setDeleteBrandsDialog(false);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'Brands'}
					textButton={'Agregar Brands'}
                    redirectTo={'/addBrand'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar brands'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editBrands(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar brands'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteBrands(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteBrandsDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteBrandsDispatch}
			/>
		</Fragment>
	);

    return <ViewBrandss
        value={getAllBrandsResponse}
        body={bodyTemplate}
        header={header}
        footer={deleteProductDialogFooter}
        visible={deleteBrandsDialog}
        onHide={hideDeleteBrandsDialog}
        brands={brandsDelete}
            />
};