import { FunctionComponent } from 'react';
import {
	InputText as InputTextPrime,
	InputTextProps,
} from 'primereact/inputtext';

type Props = {
	value?: string;
	onChange?: (e: any) => void;
	className?: string;
} & InputTextProps;

export const InputText: FunctionComponent<Props> = ({ ...props }) => (
	<InputTextPrime value={props.title} onChange={props.onChange} {...props}>
		{props.children}
	</InputTextPrime>
);
