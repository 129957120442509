import { getMasterProductsAsync } from 'data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useMasterProductExport = () => {
	const dispatch = useAppDispatch();

	const GetMasterProductExport = (): void => {
		dispatch(getMasterProductsAsync());
	};

	return GetMasterProductExport;
};

export default useMasterProductExport;