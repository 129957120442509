import { FunctionComponent } from 'react';
import { PanelMenu } from 'UI';

type Props = {
	model?: any;
};

const ViewPanelMenuUserAdmin: FunctionComponent<Props> = (props) => (
	<div>
		<PanelMenu model={props.model}></PanelMenu>
	</div>
);

export default ViewPanelMenuUserAdmin;
