export const rulesName = (texts: any) => ({
	required: texts('error.required'),
	minLength: {
		value: 3,
		message: texts('error.min_character', { numero: 3 }),
	},
});

export const rulesEmail = (texts: any) => ({
	required: texts('error.required'),
	pattern: {
		value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
		message: texts('error.email_invalid'),
	},
});

export const rulesPassword = (texts: any) => ({
	required: texts('error.required'),
	pattern: {
		value: /^(?=(.*[0-9]))(?=(.*[A-Z]))(?=(.*[a-zA-Z0-9]){7})/,
		message: texts('error.password_invalid'),
	},
	minLength: {
		value: 8,
		// value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8}$/,
		message: texts('error.min_character', { numero: 8 }),
	},
});
export const rulesLink = (texts: any) => ({
	require: texts('error.requiered'),
	pattern:{
		value:/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i,
		message: texts('error.link_invalid')
	}
})


// anterior regex para rulesLink, lo cambie porque no estaba funcionando 
// /^[A-Z0-9._%+-]+\.[A-Z]{2,4}$/i
