import { selectImporters } from 'data/global/features/ImporterSlice/importerSlice';
import React, { useEffect } from 'react';
import { ImportTypes, ViewImporter } from './view';
import { useAppSelector } from 'data/global/hooks';
import useBrandsExport from './hooks/useGetBrands';
import useCategoriesExport from './hooks/useGetCategories';
import useDistributorsExport from './hooks/useGetDistributors';
import { StatusRequest } from 'data/types';
import useImportBrands from './hooks/useImportBrand';
import useImportCategories from './hooks/useImportCategories';
import useImportDistributors from './hooks/useImportDistributor';
import useImportMasterProduct from './hooks/useImportMasterProduct';
import useImportCatalogProduct from './hooks/useImportCatalogProduct';
import useCatalogProductExport from './hooks/useGetCatalogProduct';
import useMasterProductExport from './hooks/useGetMasterProducts';
import useCleaner from './hooks/useCleaner';
import useOrdersExport from './hooks/useOrdersExport';
import useReIndex from './hooks/useReIndex';
import useGenerateDescription from './hooks/useGenerateDescription';

export const Importer: React.FC = () => {
	const {
		getBrandsExportStatusRequest,
		getBrandsExportResponse,
		getDistributorsExportStatusRequest,
		getDistributorsExportResponse,
		getCategoriesExportStatusRequest,
		getCategoriesExportResponse,
		getMasterProductExportStatusRequest,
		getMasterProductExportResponse,
		getCatalogProductExportStatusRequest,
		getCatalogProductExportResponse,
		ordersToExportRequest,
		ordersToExportResponse,
		importBrandsStatusRequest,
		importCategoriesStatusRequest,
		importDistributorsStatusRequest,
		importCatalogProductStatusRequest,
		importMasterProductStatusRequest,
		cleanerStatusRequest,
		reIndexStatusRequest,
		generateDescriptionStatusRequest,
	} = useAppSelector(selectImporters);

	const getBrandsExport = useBrandsExport();
	const getCategoriesExport = useCategoriesExport();
	const getDistributorsExport = useDistributorsExport();
	const getCatalogProductExport = useCatalogProductExport();
	const getOrdersExport = useOrdersExport();
	const getMasterProductExport = useMasterProductExport();

	const importBrands = useImportBrands();
	const importCategory = useImportCategories();
	const importDistributor = useImportDistributors();
	const importMasterProduct = useImportMasterProduct();
	const importCatalogProduct = useImportCatalogProduct();
	const cleaner = useCleaner();

	const reIndexer = useReIndex();

	const generateDescription = useGenerateDescription();

	const download_txt = (file: string, name: string) => {
		var hiddenElement = document.createElement('a');
		hiddenElement.href = 'data:attachment/text,' + encodeURIComponent(file);
		hiddenElement.target = '_blank';
		hiddenElement.download = `${name}.csv`;
		hiddenElement.click();
	};

	useEffect(() => {
		if (getDistributorsExportStatusRequest === StatusRequest.fulfilled) {
			download_txt(getDistributorsExportResponse!.file, 'Distributors');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [getDistributorsExportStatusRequest]);

	useEffect(() => {
		if (getCategoriesExportStatusRequest === StatusRequest.fulfilled) {
			download_txt(getCategoriesExportResponse!.file, 'Categories');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [getCategoriesExportStatusRequest]);

	useEffect(() => {
		if (getBrandsExportStatusRequest === StatusRequest.fulfilled) {
			download_txt(getBrandsExportResponse!.file, 'Brands');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [getBrandsExportStatusRequest]);

	useEffect(() => {
		if (getCatalogProductExportStatusRequest === StatusRequest.fulfilled) {
			download_txt(getCatalogProductExportResponse!.file, 'CatalogProducts');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [getCatalogProductExportStatusRequest]);

	useEffect(() => {
		if (ordersToExportRequest === StatusRequest.fulfilled) {
			download_txt(ordersToExportResponse!.file, 'Orders');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [ordersToExportRequest]);

	useEffect(() => {
		if (getMasterProductExportStatusRequest === StatusRequest.fulfilled) {
			download_txt(getMasterProductExportResponse!.file, 'MasterProducts');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [getMasterProductExportStatusRequest]);

	useEffect(() => {
		if (importBrandsStatusRequest === StatusRequest.fulfilled) {
			alert('brands updated');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
		if (importCategoriesStatusRequest === StatusRequest.fulfilled) {
			alert('categories updated');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
		if (importDistributorsStatusRequest === StatusRequest.fulfilled) {
			alert('distributors updated');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
		if (importCatalogProductStatusRequest === StatusRequest.fulfilled) {
			alert('catalog products updated');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
		if (importMasterProductStatusRequest === StatusRequest.fulfilled) {
			alert('master products updated');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}

		if (importBrandsStatusRequest === StatusRequest.rejected) {
			alert('ERROR brands');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
		if (importCategoriesStatusRequest === StatusRequest.rejected) {
			alert('ERROR categories');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
		if (importDistributorsStatusRequest === StatusRequest.rejected) {
			alert('ERROR distributors');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
		if (importCatalogProductStatusRequest === StatusRequest.rejected) {
			alert('ERROR catalog products');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
		if (importMasterProductStatusRequest === StatusRequest.rejected) {
			alert('ERROR master products');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [
		importBrandsStatusRequest,
		importCategoriesStatusRequest,
		importDistributorsStatusRequest,
		importCatalogProductStatusRequest,
		importMasterProductStatusRequest,
	]);

	useEffect(() => {
		if (cleanerStatusRequest === StatusRequest.rejected) {
			alert('error eliminando');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		} else if (cleanerStatusRequest === StatusRequest.fulfilled) {
			alert('Clean ok');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [cleanerStatusRequest]);

	useEffect(() => {
		if (reIndexStatusRequest === StatusRequest.rejected) {
			alert('Reindex error');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		} else if (reIndexStatusRequest === StatusRequest.fulfilled) {
			alert('Reindex ok');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [reIndexStatusRequest]);

	useEffect(() => {
		if (generateDescriptionStatusRequest === StatusRequest.rejected) {
			alert('Generate descriptions error');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		} else if (generateDescriptionStatusRequest === StatusRequest.fulfilled) {
			alert('Descriptions generated for products');
			!(document.getElementById('debugger') as any).checked &&
				window.location.reload();
		}
	}, [generateDescriptionStatusRequest]);

	return (
		<ViewImporter
			onUpload={(csvFileBase64, t) => {
				if (t === ImportTypes.BRANDS) {
					importBrands({
						csvFileBase64,
					});
				} else if (t === ImportTypes.CATEGORIES) {
					importCategory({
						csvFileBase64,
					});
				} else if (t === ImportTypes.MASTER_PRODUCTS) {
					importMasterProduct({
						csvFileBase64,
					});
				} else if (t === ImportTypes.CATALOG_PRODUCTS) {
					importCatalogProduct({
						csvFileBase64,
					});
				} else {
					importDistributor({
						csvFileBase64,
					});
				}
			}}
			onExport={(t) => {
				if (t === ImportTypes.BRANDS) {
					getBrandsExport();
				} else if (t === ImportTypes.CATEGORIES) {
					getCategoriesExport();
				} else if (t === ImportTypes.MASTER_PRODUCTS) {
					getMasterProductExport();
				} else if (t === ImportTypes.CATALOG_PRODUCTS) {
					getCatalogProductExport();
				} else if (t === ImportTypes.ORDERS) {
					getOrdersExport();
				} else {
					getDistributorsExport();
				}
			}}
			onExample={(t) => {
				if (t === ImportTypes.BRANDS) {
				} else if (t === ImportTypes.CATEGORIES) {
				} else {
					// distributors
				}
			}}
			onClear={(data) => {
				cleaner({
					type: data,
				});
			}}
			onReindex={() => {
				reIndexer();
			}}
			onGenerateDescription={() => {
				generateDescription();
			}}
		/>
	);
};
