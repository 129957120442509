import { useAppDispatch } from 'data/global/hooks';
import { GetAllMasterProductsAsync } from 'data/global/features/MasterProductsSlice/masterProductsSlice';
import { useEffect } from 'react';

const useGetMasterProducts = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllMasterProductsAsync({}));
	}, [dispatch]);
};

export default useGetMasterProducts;