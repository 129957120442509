import { GetDistributorsExportAsync } from './../../../data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useDistributorsExport = () => {
	const dispatch = useAppDispatch();

	const GetDistributorsExport = (): void => {
		dispatch(GetDistributorsExportAsync());
	};

	return GetDistributorsExport;
};

export default useDistributorsExport;