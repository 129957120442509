// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateLibraryUser_container__S3RoW {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.CreateLibraryUser_allContainer__IhThk {\n\theight: 100%;\n\tbackground-color: white;\n}\n.CreateLibraryUser_cardContainer__97hmG {\n\twidth: 60%;\n}\n\n.CreateLibraryUser_card__EP0mb {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.CreateLibraryUser_inputContainer__OX6D6 {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.CreateLibraryUser_label__1DQF6 {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/AddLibraryUser/CreateLibraryUser.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;AACA;CACC,YAAY;CACZ,uBAAuB;AACxB;AACA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;CACV,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.allContainer {\n\theight: 100%;\n\tbackground-color: white;\n}\n.cardContainer {\n\twidth: 60%;\n}\n\n.card {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.inputContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.label {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateLibraryUser_container__S3RoW",
	"allContainer": "CreateLibraryUser_allContainer__IhThk",
	"cardContainer": "CreateLibraryUser_cardContainer__97hmG",
	"card": "CreateLibraryUser_card__EP0mb",
	"inputContainer": "CreateLibraryUser_inputContainer__OX6D6",
	"label": "CreateLibraryUser_label__1DQF6"
};
export default ___CSS_LOADER_EXPORT___;
