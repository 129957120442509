import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllPresetCartsRequest,
	GetAllPresetCartsResponse,
	AddPresetCartsRequest,
	AddPresetCartsResponse,
	DeletePresetCartsResponse,
	PutPresetCartsRequest,
	PutPresetCartsResponse,
} from 'data/types/presetCartsTypes';
import { ExportResponse } from 'data/types/importerTypes';
import { Either } from 'models/either';

export const getAllPresetCarts = async (): Promise<
	Either<Failure, GetAllPresetCartsResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllPresetCartsRequest, GetAllPresetCartsResponse>({
			url: '/api/v1/backoffice/presetCart',
			secure: true,
		})
	);
};
export const addPresetCarts = async (
	request: AddPresetCartsRequest
): Promise<Either<Failure, AddPresetCartsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<AddPresetCartsRequest, AddPresetCartsResponse>({
			url: '/api/v1/backoffice/importer/presetCarts',
			request,
		})
	);
};

export const deletePresetCarts = async (
	id: string
): Promise<Either<Failure, DeletePresetCartsResponse>> => {
	console.log('id', id);
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<string, DeletePresetCartsResponse>({
			url: `/api/v1/backoffice/presetCart/${id}`,
		})
	);
};

export const putPresetCarts = async (
	request: PutPresetCartsRequest
): Promise<Either<Failure, PutPresetCartsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutPresetCartsRequest, PutPresetCartsResponse>({
			url: `/api/v1/backoffice/presetCart/${request.id}`,
			request,
		})
	);
};

export const getPresetCartItemsExport = async (
	id: string
): Promise<Either<Failure, ExportResponse>> => {
	console.log('id', id);
	return BaseRepository.toEither(() =>
		BaseApi.get<string, ExportResponse>({
			url: `/api/v1/backoffice/importer/presetCarts/${id}`,
		})
	);
};

const PresetCartsApi = {
	getAllPresetCarts,
	addPresetCarts,
	deletePresetCarts,
	putPresetCarts,
	getPresetCartItemsExport,
};

export default PresetCartsApi;
