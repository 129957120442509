import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllDistributorsRequest,
	GetAllDistributorsResponse,
	AddDistributorsRequest,
	AddDistributorsResponse,
	DeleteDistributorsResponse,
	PutDistributorsRequest,
	PutDistributorsResponse,
} from 'data/types/distributorsTypes';
import { Either } from 'models/either';

export const getAllDistributors = async (): Promise<
	Either<Failure, GetAllDistributorsResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllDistributorsRequest, GetAllDistributorsResponse>({
			url: '/api/v1/backoffice/distributor',
			secure: true,
		})
	);
};
export const addDistributors = async (
	request: AddDistributorsRequest
): Promise<Either<Failure, AddDistributorsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<AddDistributorsRequest, AddDistributorsResponse>({
			url: '/api/v1/backoffice/distributor',
			request,
		})
	);
};

export const deleteDistributors = async (
	id: string
): Promise<Either<Failure, DeleteDistributorsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<string, DeleteDistributorsResponse>({
			url: `/api/v1/backoffice/distributor/${id}`,
		})
	);
};

export const putDistributors = async (
	request: PutDistributorsRequest
): Promise<Either<Failure, PutDistributorsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutDistributorsRequest, PutDistributorsResponse>({
			url: `/api/v1/backoffice/distributor/${request.id}`,
			request,
		})
	);
};

const DistributorsApi = {
	getAllDistributors,
	addDistributors,
	deleteDistributors,
	putDistributors,
};

export default DistributorsApi;
