import { useAppDispatch } from 'data/global/hooks';
import { GetAllDistributorsAsync } from 'data/global/features/DistributorsSlice/distributorsSlice';
import { useEffect } from 'react';

const useGetDistributors = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllDistributorsAsync({}));
	}, [dispatch]);
};

export default useGetDistributors;