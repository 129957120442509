import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import AuthApi from 'data/api/authApi';
import { Failure, StatusRequest } from 'data/types';
import JSEncrypt from 'jsencrypt';
import {
	GetPublicKeyRequest,
	GetPublicKeyResponse,
	SignInRequest,
	SignInResponse,
} from 'data/types/authTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TAuthSlice = {
	getPublicKeyStatusRequest: StatusRequest;
	getPublicKeyStatusError?: Failure;
	signInStatusRequest: StatusRequest;
	signInStatusError?: Failure;
	isLogin: boolean | null;
};

const initialState: TAuthSlice = {
	getPublicKeyStatusRequest: StatusRequest.initial,
	getPublicKeyStatusError: undefined,
	signInStatusRequest: StatusRequest.initial,
	signInStatusError: undefined,
	isLogin: null,
};

export const GetPublicKeyAsync = createAsyncThunk<
	GetPublicKeyResponse,
	GetPublicKeyRequest,
	{ rejectValue: Failure }
>('auth/GetPublicKeyAsync', async (request: GetPublicKeyRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetPublicKeyResponse> =
			await AuthApi.getPublicKey();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const signInUserAsync = createAsyncThunk<
	SignInResponse,
	SignInRequest,
	{ rejectValue: Failure }
>('user/signInUserAsync', async (request: SignInRequest, thunkAPI) => {
	console.log('signInUserAsync');
	try {
		const eitherResponsePublicKey: Either<Failure, GetPublicKeyResponse> =
			await AuthApi.getPublicKey();
		if (isRight(eitherResponsePublicKey)) {
			const responsePublicKey = unwrapEither(eitherResponsePublicKey);
			const { rsaPublicKey, id } = responsePublicKey;
			request.cypherID = id;
			request.email = encriptRsa(rsaPublicKey, request.email);
			request.password = encriptRsa(rsaPublicKey, request.password);
			const eitherResponse: Either<Failure, SignInResponse> =
				await AuthApi.signIn(request);
			if (isRight(eitherResponse)) {
				const response = unwrapEither(eitherResponse);
				const { jwt } = response;
				const { role } = response;
				console.log(response);
				localStorage.setItem('jwt', jwt);
				localStorage.setItem('role', role);
				return response;
			}
			const error = unwrapEither(eitherResponse);
			return thunkAPI.rejectWithValue(error);
		}
		const error = unwrapEither(eitherResponsePublicKey);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const AuthSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logOut: (state) => {
			localStorage.removeItem('jwt');
			state.isLogin = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetPublicKeyAsync.pending, (state) => {
			state.getPublicKeyStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetPublicKeyAsync.fulfilled, (state, action) => {
			state.getPublicKeyStatusRequest = StatusRequest.fulfilled;
			// state.user = action.payload;
		});
		builder.addCase(GetPublicKeyAsync.rejected, (state, action) => {
			state.getPublicKeyStatusRequest = StatusRequest.rejected;
			state.getPublicKeyStatusError = action.payload;
		});
		builder.addCase(signInUserAsync.pending, (state) => {
			state.signInStatusRequest = StatusRequest.pending;
			state.signInStatusError = undefined;
		});
		builder.addCase(signInUserAsync.fulfilled, (state, action) => {
			state.signInStatusRequest = StatusRequest.fulfilled;
			state.isLogin = true;
		});
		builder.addCase(signInUserAsync.rejected, (state, action) => {
			state.signInStatusRequest = StatusRequest.rejected;
			state.signInStatusError = action.payload;
		});
	},
});

export const selectAuth = (state: RootState) => state.auth;
export const encriptRsa = (rsaKey: string, text: string) => {
	const rsaEncrypt = new JSEncrypt();
	rsaEncrypt.setPublicKey(rsaKey);
	const rsaEncryptedAesKey = rsaEncrypt.encrypt(text);
	return rsaEncryptedAesKey.toString();
};
export const { logOut } = AuthSlice.actions;

export default AuthSlice.reducer;
