import { cleanerProductAsync } from './../../../data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useCleaner = () => {
	const dispatch = useAppDispatch();

	const useCleanerExport = (req: {type: string}): void => {
		dispatch(cleanerProductAsync(req));
	};

	return useCleanerExport;
};

export default useCleaner;