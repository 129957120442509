import { FunctionComponent } from 'react';
import ViewDataTableMasterProducts from './view';

interface Props {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	masterProducts?: object;
}

export const DataTableMasterProducts: FunctionComponent<Props> = ({
	value,
	header,
	body,
	visible,
	footer,
	onHide,
	masterProducts,
}) => {
	return (
		<ViewDataTableMasterProducts
			value={value}
			header={header}
			body={body}
			visible={visible}
			footer={footer}
			onHide={onHide}
			masterProducts={masterProducts}
		/>
	);
};

export default DataTableMasterProducts;