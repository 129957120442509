import { FunctionComponent } from 'react';
import ViewDataTableBanner from './view';

interface Props {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	banner?: object;
}

export const DataTableBanner: FunctionComponent<Props> = ({
	value,
	header,
	body,
	visible,
	footer,
	onHide,
	banner,
}) => {
	return (
		<ViewDataTableBanner
			value={value}
			header={header}
			body={body}
			visible={visible}
			footer={footer}
			onHide={onHide}
			banner={banner}
		/>
	);
};

export default DataTableBanner;