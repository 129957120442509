// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreatePresetCart_container__34SFQ {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.CreatePresetCart_allContainer__ARSU0 {\n\theight: 100%;\n\tbackground-color: white;\n}\n.CreatePresetCart_cardContainer__THLK- {\n\twidth: 60%;\n}\n\n.CreatePresetCart_card__AYBa- {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.CreatePresetCart_inputContainer__tnytZ {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.CreatePresetCart_label__8GqBo {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n\n.CreatePresetCart_input__r3eKl[type=\"file\"] {\n    display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/AddPresetCart/CreatePresetCart.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;AACA;CACC,YAAY;CACZ,uBAAuB;AACxB;AACA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;CACV,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.allContainer {\n\theight: 100%;\n\tbackground-color: white;\n}\n.cardContainer {\n\twidth: 60%;\n}\n\n.card {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.inputContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.label {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n\n.input[type=\"file\"] {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreatePresetCart_container__34SFQ",
	"allContainer": "CreatePresetCart_allContainer__ARSU0",
	"cardContainer": "CreatePresetCart_cardContainer__THLK-",
	"card": "CreatePresetCart_card__AYBa-",
	"inputContainer": "CreatePresetCart_inputContainer__tnytZ",
	"label": "CreatePresetCart_label__8GqBo",
	"input": "CreatePresetCart_input__r3eKl"
};
export default ___CSS_LOADER_EXPORT___;
