import { ViewCategories } from "./view";
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectCategories } from 'data/global/features/CategoriesSlice/categoriesSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteCategories, useGetCategories } from "./hooks";

export const Categories: React.FC = () => {
    const [deleteCategoriesDialog, setDeleteCategoriesDialog] = useState(false);
	const [deleteCategoriesSuccess, setDeleteCategoriesSuccess] = useState(false);

	const deleteCategories = useDeleteCategories();

	const navigate = useNavigate();

	useGetCategories();
	const { getAllCategoriesResponse, deleteCategoriesStatusRequest } =
		useAppSelector(selectCategories);

	useEffect(() => {
		if (deleteCategoriesStatusRequest === 1) {
			setDeleteCategoriesSuccess(true);
		}
	}, [deleteCategoriesStatusRequest]);

	useEffect(() => {
		if (deleteCategoriesSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [categoriesDelete, setCategoriesDelete] = useState<null | { categoryID: any }>(
		null
	);

	const editCategories = (categories: any) => {
		console.log(categories);
		navigate('/addcategories', { state: { categories: categories } });
	};

	//  delete
	const confirmDeleteCategories = (categories: any) => {
		setCategoriesDelete(categories);
		setDeleteCategoriesDialog(true);
	};

	const deleteCategoriesDispatch = () => {
		setDeleteCategoriesDialog(false);
		console.log('delete', categoriesDelete);
		deleteCategories(categoriesDelete?.categoryID);
	};
	const hideDeleteCategoriesDialog = () => {
		setDeleteCategoriesDialog(false);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'Categories'}
					textButton={'Agregar Categories'}
                    redirectTo={'/addCategories'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar categories'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editCategories(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar categories'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteCategories(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteCategoriesDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteCategoriesDispatch}
			/>
		</Fragment>
	);

    return <ViewCategories
        value={getAllCategoriesResponse}
        body={bodyTemplate}
        header={header}
        footer={deleteProductDialogFooter}
        visible={deleteCategoriesDialog}
        onHide={hideDeleteCategoriesDialog}
        categories={categoriesDelete}
            />
};