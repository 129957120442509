import { FunctionComponent } from 'react';
import { InputText, Button } from 'UI';
import { NavLink } from 'react-router-dom';

type Props = {
	value?: string;
	title?: string;
	textButton?: string;
	redirectTo: string;
};

export const HeaderDataTable: FunctionComponent<Props> = (props) => (
	<div>
		<div className='flex justify-content-between align-items-center'>
			<h2 className='m-0'>{props.title}</h2>
			<span className='p-input-icon-left'>
				<i className='pi pi-search' />
				<InputText value={props.value} placeholder='Keyword Search' />
			</span>
			<NavLink
				to={props.redirectTo}
				className='p-button p-component'
				style={{ textDecoration: 'none' }}
			>
				{props.textButton}
			</NavLink>
		</div>
	</div>
);
