import { useAppDispatch } from 'data/global/hooks';
import { DeleteMasterProductsAsync } from 'data/global/features/MasterProductsSlice/masterProductsSlice';

const useDeleteMasterProducts = () => {
	const dispatch = useAppDispatch();

	const deleteMasterProducts = (id: string): void => {
		dispatch(DeleteMasterProductsAsync(id));
	};

	return deleteMasterProducts;
};

export default useDeleteMasterProducts;