import { FunctionComponent } from 'react';
import { InputTextSigIn, Button, ErrorText, Dropdown, Toast } from 'UI';
import { AllPanelMenu, Logout } from 'components';
import { ControlProps, FormProps } from 'models/form';
import { TCreateBrand } from 'models/brandModels';
import style from './CreateBrand.module.css';

type Props = {
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	value?: string;
	options?: any[];
	placeholder?: string;
	modifyBrand?: any;
	onChange?: (e: any) => void;
	addBrand: (brand: TCreateBrand) => void;
	handleSubmitModify: (data: any) => void;
	toast?: any;
};

export const ViewCreateBrand: FunctionComponent<Props> = (props) => (
	<div className={style.allContainer}>
		<Logout />

		<div className={style.container}>
			<aside>
				<AllPanelMenu />
			</aside>
			<aside className={style.cardContainer}>
				<div className={style.card}>
					{props?.modifyBrand ? (
						<h1>Modificar Brand</h1>
					) : (
						<h1> Crear Brand</h1>
					)}
					<div className={style.inputContainer}>
						<label className={style.label}>Name</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.brandName,
								name: 'name',
								rules: props.controlProps.rules.rulesName,
							}}
							errors={props.formProps.errors}
							type='text'
							placeholder='name'
							className={style.input}
						></InputTextSigIn>
						<label className={style.label}>Logo</label>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.brandLogo,
								name: 'logo',
								rules: props.controlProps.rules.rulesLogo,
							}}
							errors= {props.formProps.errors}
							type='text'
							placeholder='logo'
							className={style.label}
							></InputTextSigIn>
						{props?.modifyBrand ? (
							<Button
								label='Modificar Brand'
								className='p-button-lg'
								style={{ marginTop: '1.5em' }}
								onClick={props.formProps.handleSubmit(props.handleSubmitModify)}
							></Button>
						) : (
							<Button
								label='Crear Brand'
								className='p-button-lg'
								style={{ marginTop: '1.5em' }}
								onClick={props.formProps.handleSubmit(props.addBrand)}
							></Button>
						)}
						<Toast toast={props.toast} />
					</div>

					{props?.error?.error && (
						<ErrorText style={{ marginTop: '1.5em' }}>
							{props?.error?.error}
						</ErrorText>
					)}
				</div>
			</aside>
		</div>
	</div>
);
