import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import BannersApi from 'data/api/banners';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllBannerRequest,
	GetAllBannerResponse,
	AddBannerRequest,
	AddBannerResponse,
	DeleteBannerResponse,
	PutBannerRequest,
	PutBannerResponse,
} from 'data/types/bannerTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TbannerSlice = {
	getAllBannerStatusRequest: StatusRequest;
	getAllBannerResponse?: GetAllBannerResponse;
	getAllBannerStatusError?: Failure;
	addBannerStatusRequest?: StatusRequest;
	addBannerStatusError?: Failure;
	deleteBannerStatusRequest?: StatusRequest;
	deleteBannerStatusError?: Failure;
	putBannerStatusRequest: StatusRequest;
	putBannerStatusError?: Failure;
};
const initialState: TbannerSlice = {
	getAllBannerStatusRequest: StatusRequest.initial,
	getAllBannerResponse: undefined,
	getAllBannerStatusError: undefined,
	addBannerStatusRequest: StatusRequest.initial,
	addBannerStatusError: undefined,
	deleteBannerStatusRequest: StatusRequest.initial,
	deleteBannerStatusError: undefined,
	putBannerStatusRequest: StatusRequest.initial,
	putBannerStatusError: undefined,
};

export const GetAllBannerAsync = createAsyncThunk<
	GetAllBannerResponse,
	GetAllBannerRequest,
	{ rejectValue: Failure }
>('auth/GetAllBannerAsync', async (request: GetAllBannerRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllBannerResponse> =
			await BannersApi.getAllBanners();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get Banner');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddBannerAsync = createAsyncThunk<
	AddBannerResponse,
	AddBannerRequest,
	{ rejectValue: Failure }
>('banner/AddBannerAsync', async (request: AddBannerRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, AddBannerResponse> =
			await BannersApi.addBanner(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post Banner');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteBannerAsync = createAsyncThunk<
	DeleteBannerResponse,
	string,
	{ rejectValue: Failure }
>('banner/DeleteBannerAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteBannerResponse> =
			await BannersApi.deleteBanner(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutBannerAsync = createAsyncThunk<
	PutBannerResponse,
	PutBannerRequest,
	{ rejectValue: Failure }
>('banner/PutBannerAsync', async (request: PutBannerRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutBannerResponse> =
			await BannersApi.putBanner(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const BannerSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putBannerStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addBannerStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllBannerAsync.pending, (state) => {
			state.getAllBannerStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllBannerAsync.fulfilled, (state, action) => {
			state.getAllBannerStatusRequest = StatusRequest.fulfilled;
			state.getAllBannerResponse = action.payload;
		});
		builder.addCase(GetAllBannerAsync.rejected, (state, action) => {
			state.getAllBannerStatusRequest = StatusRequest.rejected;
			state.getAllBannerStatusError = action.payload;
		});
		builder.addCase(AddBannerAsync.pending, (state) => {
			state.addBannerStatusRequest = StatusRequest.pending;
			state.addBannerStatusError = undefined;
		});
		builder.addCase(AddBannerAsync.fulfilled, (state, action) => {
			state.addBannerStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddBannerAsync.rejected, (state, action) => {
			state.addBannerStatusRequest = StatusRequest.rejected;
			state.addBannerStatusError = action.payload;
		});
		builder.addCase(DeleteBannerAsync.pending, (state) => {
			state.deleteBannerStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteBannerAsync.fulfilled, (state, action) => {
			state.deleteBannerStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteBannerAsync.rejected, (state, action) => {
			state.deleteBannerStatusRequest = StatusRequest.rejected;
			state.deleteBannerStatusError = action.payload;
		});
		builder.addCase(PutBannerAsync.pending, (state) => {
			state.putBannerStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutBannerAsync.fulfilled, (state, action) => {
			state.putBannerStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutBannerAsync.rejected, (state, action) => {
			state.putBannerStatusRequest = StatusRequest.rejected;
			state.putBannerStatusError = action.payload;
		});
	},
});

export const selectBanner = (state: RootState) => state.banner;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	BannerSlice.actions;
export default BannerSlice.reducer;
