import { FunctionComponent } from 'react';
import { AllPanelMenu, Logout } from 'components';

type Props = {};

export const ViewDashboard: FunctionComponent<Props> = (props) => (
	<div>
		<Logout />
		<AllPanelMenu />
	</div>
);
