import { useAppDispatch } from 'data/global/hooks';
import { AddPresetCartsAsync } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';
import { AddPresetCartsRequest } from 'data/types/presetCartsTypes';

export const useAddPresetCart = () => {
	const dispatch = useAppDispatch();

	const addPresetCart = (presetCart: AddPresetCartsRequest): void => {
		dispatch(AddPresetCartsAsync(presetCart));
	};

	return addPresetCart;
};

export default useAddPresetCart;
