import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import DistributorsApi from 'data/api/distributors';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllDistributorsRequest,
	GetAllDistributorsResponse,
	AddDistributorsRequest,
	AddDistributorsResponse,
	DeleteDistributorsResponse,
	PutDistributorsRequest,
	PutDistributorsResponse,
} from 'data/types/distributorsTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TdistributorsSlice = {
	getAllDistributorsStatusRequest: StatusRequest;
	getAllDistributorsResponse?: GetAllDistributorsResponse;
	getAllDistributorsStatusError?: Failure;
	addDistributorsStatusRequest?: StatusRequest;
	addDistributorsStatusError?: Failure;
	deleteDistributorsStatusRequest?: StatusRequest;
	deleteDistributorsStatusError?: Failure;
	putDistributorsStatusRequest: StatusRequest;
	putDistributorsStatusError?: Failure;
};
const initialState: TdistributorsSlice = {
	getAllDistributorsStatusRequest: StatusRequest.initial,
	getAllDistributorsResponse: undefined,
	getAllDistributorsStatusError: undefined,
	addDistributorsStatusRequest: StatusRequest.initial,
	addDistributorsStatusError: undefined,
	deleteDistributorsStatusRequest: StatusRequest.initial,
	deleteDistributorsStatusError: undefined,
	putDistributorsStatusRequest: StatusRequest.initial,
	putDistributorsStatusError: undefined,
};

export const GetAllDistributorsAsync = createAsyncThunk<
	GetAllDistributorsResponse,
	GetAllDistributorsRequest,
	{ rejectValue: Failure }
>('auth/GetAllDistributorsAsync', async (request: GetAllDistributorsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllDistributorsResponse> =
			await DistributorsApi.getAllDistributors();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get Distributors');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddDistributorsAsync = createAsyncThunk<
	AddDistributorsResponse,
	AddDistributorsRequest,
	{ rejectValue: Failure }
>('distributors/AddDistributorsAsync', async (request: AddDistributorsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, AddDistributorsResponse> =
			await DistributorsApi.addDistributors(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post Distributors');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteDistributorsAsync = createAsyncThunk<
	DeleteDistributorsResponse,
	string,
	{ rejectValue: Failure }
>('distributors/DeleteDistributorsAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteDistributorsResponse> =
			await DistributorsApi.deleteDistributors(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutDistributorsAsync = createAsyncThunk<
	PutDistributorsResponse,
	PutDistributorsRequest,
	{ rejectValue: Failure }
>('distributors/PutDistributorsAsync', async (request: PutDistributorsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutDistributorsResponse> =
			await DistributorsApi.putDistributors(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DistributorsSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putDistributorsStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addDistributorsStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllDistributorsAsync.pending, (state) => {
			state.getAllDistributorsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllDistributorsAsync.fulfilled, (state, action) => {
			state.getAllDistributorsStatusRequest = StatusRequest.fulfilled;
			state.getAllDistributorsResponse = action.payload;
		});
		builder.addCase(GetAllDistributorsAsync.rejected, (state, action) => {
			state.getAllDistributorsStatusRequest = StatusRequest.rejected;
			state.getAllDistributorsStatusError = action.payload;
		});
		builder.addCase(AddDistributorsAsync.pending, (state) => {
			state.addDistributorsStatusRequest = StatusRequest.pending;
			state.addDistributorsStatusError = undefined;
		});
		builder.addCase(AddDistributorsAsync.fulfilled, (state, action) => {
			state.addDistributorsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddDistributorsAsync.rejected, (state, action) => {
			state.addDistributorsStatusRequest = StatusRequest.rejected;
			state.addDistributorsStatusError = action.payload;
		});
		builder.addCase(DeleteDistributorsAsync.pending, (state) => {
			state.deleteDistributorsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteDistributorsAsync.fulfilled, (state, action) => {
			state.deleteDistributorsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteDistributorsAsync.rejected, (state, action) => {
			state.deleteDistributorsStatusRequest = StatusRequest.rejected;
			state.deleteDistributorsStatusError = action.payload;
		});
		builder.addCase(PutDistributorsAsync.pending, (state) => {
			state.putDistributorsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutDistributorsAsync.fulfilled, (state, action) => {
			state.putDistributorsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutDistributorsAsync.rejected, (state, action) => {
			state.putDistributorsStatusRequest = StatusRequest.rejected;
			state.putDistributorsStatusError = action.payload;
		});
	},
});

export const selectDistributors = (state: RootState) => state.distributors;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	DistributorsSlice.actions;
export default DistributorsSlice.reducer;
