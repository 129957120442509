import React, { useRef, useEffect } from 'react';
import { ViewCreateManufacturer } from './view';
import { useForm } from 'react-hook-form';
import { rulesName, rulesLink } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import {
	useAddManufacturers,
	usePutManufacturers,
	usePutReset,
	useCreateReset,
} from './hooks';
import { selectManufacturer } from 'data/global/features/ManufacturersSlice/manufacturersSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';


export const CreateManufacturer: React.FC = () => {
	// funcion que hace el dispatch para crear distributors
	const addManufacturer = useAddManufacturers();

	const putManufacturer = usePutManufacturers();
	const resetPutDistributors = usePutReset();
	const resetCreateDistributors = useCreateReset();

	const { t } = useTranslation();
	const { addManufacturersStatusError, addManufacturersStatusRequest } =
		useAppSelector(selectManufacturer);

	const { putManufacturersStatusError, putManufacturersStatusRequest } =
		useAppSelector(selectManufacturer);

	type LocationStateProps = {
		manufacturer: any;
	};

	const location = useLocation().state as LocationStateProps;
	const manufacturerModified = location?.manufacturer;
	const navigate = useNavigate();


	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = manufacturerModified || {
		name: '',
		photo: '',
	};

	const handleSubmitModify = async (data: any) => {
		console.log('data', data)
		const manufacturerID = manufacturerModified.manufacturerID;
		const { photo,name  } = data;
		const request = { manufacturerID, name, photo };
		putManufacturer(request);
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (putManufacturersStatusRequest === 1) {
			showSuccessModify();
		} else if (putManufacturersStatusRequest === 2) {
			showErrorModify();
		}
	}, [putManufacturersStatusRequest]);

	useEffect(() => {
		if (addManufacturersStatusRequest === 1) {
			showSuccessCreate();
		} else if (addManufacturersStatusRequest === 2) {
			showErrorCreate();
		}
	}, [addManufacturersStatusRequest]);

	const showSuccessModify = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Manufacturer Modificado',
			detail: 'Se modifico con exito el manufacturer seleccionado',
			life: 2000,
		});
		resetPutDistributors();
		setTimeout(() => {
			navigate('/manufacturers');
		}, 1000);
	};
	const showErrorModify = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido modificar el manufacturer seleccionado',
		});
	};

	const showSuccessCreate = () => {
		toast.current.show({
			severity: 'success',
			summary: 'Manufacturer Creado',
			detail: 'El Manufacturer fue creado con Exito',
			life: 3000,
		});
		resetCreateDistributors();
		setTimeout(() => {
			navigate('/manufacturers');
		}, 1000);
	};
	const showErrorCreate = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido crear el Manufacturer',
		});
	};
	//
	//reglas de los inputs
	const rules = {
		rulesImage: rulesLink(t),
		rulesLink: rulesLink(t),
		rulesPosition: rulesName(t),
	};

	const positionSelectItems = [
		{ label: 'START', value: ['START'] },
		{ label: 'END', value: ['END'] },
	];
	const placeholder = 'Selecciona una position';
	return (
		<ViewCreateManufacturer
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			options={positionSelectItems}
			placeholder={placeholder}
			addManufacturers={addManufacturer}
			error={addManufacturersStatusError}
			modifyManufacturers={manufacturerModified}
			handleSubmitModify={handleSubmitModify}
			toast={toast}
		/>
	);
};
