import { Failure } from 'data/types';
import { ExportResponse, ImportRequest } from 'data/types/importerTypes';
import { Either } from 'models/either';
import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';

export const getBrandsToExport = async (): Promise<
	Either<Failure, ExportResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<void, ExportResponse>({
			url: '/api/v1/backoffice/importer/brands',
			secure: true,
		})
	);
};

export const getCategoriesToExport = async (): Promise<
	Either<Failure, ExportResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<void, ExportResponse>({
			url: '/api/v1/backoffice/importer/categories',
			secure: true,
		})
	);
};

export const getDistributorsToExport = async (): Promise<
	Either<Failure, ExportResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<void, ExportResponse>({
			url: '/api/v1/backoffice/importer/distributors',
			secure: true,
		})
	);
};

export const getCatalogProductsToExport = async (): Promise<
	Either<Failure, ExportResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<void, ExportResponse>({
			url: '/api/v1/backoffice/importer/catalog-products',
			secure: true,
		})
	);
};

export const getMasterProductsToExport = async (): Promise<
	Either<Failure, ExportResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<void, ExportResponse>({
			url: '/api/v1/backoffice/importer/master-products',
			secure: true,
		})
	);
};

export const importBrands = async (
	request: ImportRequest
): Promise<Either<Failure, ExportResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<ImportRequest, ExportResponse>({
			url: '/api/v1/backoffice/importer/brands',
			request,
			secure: true,
		})
	);
};

export const importCategories = async (
	request: ImportRequest
): Promise<Either<Failure, ExportResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<ImportRequest, ExportResponse>({
			url: '/api/v1/backoffice/importer/categories',
			request,
			secure: true,
		})
	);
};

export const importDistributors = async (
	request: ImportRequest
): Promise<Either<Failure, ExportResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<ImportRequest, ExportResponse>({
			url: '/api/v1/backoffice/importer/distributors',
			request,
			secure: true,
		})
	);
};

export const importMasterProducts = async (
	request: ImportRequest
): Promise<Either<Failure, ExportResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<ImportRequest, ExportResponse>({
			url: '/api/v1/backoffice/importer/master-products',
			request,
			secure: true,
		})
	);
};

export const importCatalogProducts = async (
	request: ImportRequest
): Promise<Either<Failure, ExportResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<ImportRequest, ExportResponse>({
			url: '/api/v1/backoffice/importer/catalog-products',
			request,
			secure: true,
		})
	);
};

export const cleanerProducts = async (request: {
	type: string;
}): Promise<Either<Failure, ExportResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<{ type: string }, ExportResponse>({
			url: '/api/v1/backoffice/importer/cleaner',
			request,
			secure: true,
		})
	);
};

export const OrdersToExport = async (): Promise<
	Either<Failure, ExportResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<void, ExportResponse>({
			url: '/api/v1/backoffice/importer/orders',
			secure: true,
		})
	);
};

export const reIndexElastic = async (): Promise<Either<Failure, string>> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<void, string>({
			url: '/api/v1/backoffice/importer/reindex',
			secure: true,
		})
	);
};

export const generateDescription = async (): Promise<
	Either<Failure, string>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<void, string>({
			url: '/api/v1/backoffice/importer/generate-description-for-products',
			secure: true,
		})
	);
};

const ImporterApi = {
	getBrandsToExport,
	getCategoriesToExport,
	getDistributorsToExport,
	getMasterProductsToExport,
	getCatalogProductsToExport,
	OrdersToExport,
	importBrands,
	importCategories,
	importDistributors,
	importCatalogProducts,
	importMasterProducts,
	cleanerProducts,
	reIndexElastic,
	generateDescription,
};

export default ImporterApi;
