// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContainerSidebar_sidebar__0dM\\+L {\n\tmargin: 0;\n\tpadding: 0;\n\twidth: 20%;\n\tbackground-color: #f1f1f1;\n\tposition: fixed;\n\theight: 100%;\n\toverflow: auto;\n}\n/* @media screen and (max-width: 700px) {\n\t.sidebar {\n\t\twidth: 80%;\n\t\theight: auto;\n\t\tposition: relative;\n\t}\n} */\n", "",{"version":3,"sources":["webpack://./src/UI/ContainerSidebar/ContainerSidebar.module.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,UAAU;CACV,UAAU;CACV,yBAAyB;CACzB,eAAe;CACf,YAAY;CACZ,cAAc;AACf;AACA;;;;;;GAMG","sourcesContent":[".sidebar {\n\tmargin: 0;\n\tpadding: 0;\n\twidth: 20%;\n\tbackground-color: #f1f1f1;\n\tposition: fixed;\n\theight: 100%;\n\toverflow: auto;\n}\n/* @media screen and (max-width: 700px) {\n\t.sidebar {\n\t\twidth: 80%;\n\t\theight: auto;\n\t\tposition: relative;\n\t}\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "ContainerSidebar_sidebar__0dM+L"
};
export default ___CSS_LOADER_EXPORT___;
