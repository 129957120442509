import { FunctionComponent } from 'react';
import { Menubar, Button, SuccessText } from 'UI';
import { AllPanelMenu, DataTableUser, Logout } from 'components';
import style from './UserAdmin.module.css';

type Props = {
	value?: any;
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	user?: any;
	successText?: boolean;
};

export const ViewUserAdmin: FunctionComponent<Props> = (props) => (
	<div>
		<Logout />
		<div className={style.container}>
			<aside>
				<AllPanelMenu />
			</aside>
			<aside className={style.containerDataTable}>
				<DataTableUser
					value={props.value}
					body={props.body}
					header={props.header}
					visible={props.visible}
					footer={props.footer}
					onHide={props.onHide}
					user={props.user}
				/>
				{props.successText === true && (
					<SuccessText style={{ marginTop: '1.5em' }}>
						El Usuario fue eliminado correctamente
					</SuccessText>
				)}
			</aside>
		</div>
	</div>
);
