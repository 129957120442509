import { useAppDispatch } from 'data/global/hooks';
import { GetAllPresetCartsAsync } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';
import { useEffect } from 'react';

const useGetPresetCarts = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllPresetCartsAsync({}));
	}, [dispatch]);
};

export default useGetPresetCarts;