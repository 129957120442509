import { FunctionComponent } from 'react';
import ViewDataTableDistributors from './view';

interface Props {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	manufacturers?: object;
}

export const DataTableManufacturer: FunctionComponent<Props> = ({
	value,
	header,
	body,
	visible,
	footer,
	onHide,
	manufacturers,
}) => {
	return (
		<ViewDataTableDistributors
			value={value}
			header={header}
			body={body}
			visible={visible}
			footer={footer}
			onHide={onHide}
			manufacturers={manufacturers}
		/>
	);
};

export default DataTableManufacturer;