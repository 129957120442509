import { FunctionComponent } from 'react';
import { Menubar as MenubarPrime, MenubarProps } from 'primereact/menubar';

type Props = {
	model?: [];
} & MenubarProps;

export const Menubar: FunctionComponent<Props> = ({ ...props }) => (
	<MenubarPrime model={props.model} {...props}>
		{props.children}
	</MenubarPrime>
);
