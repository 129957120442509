// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateDistributors_container__aTjmN {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.CreateDistributors_allContainer__fmco2 {\n\theight: 100%;\n\tbackground-color: white;\n}\n.CreateDistributors_cardContainer__qEz0R {\n\twidth: 60%;\n}\n\n.CreateDistributors_card__Tocqt {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.CreateDistributors_inputContainer__hcXEt {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.CreateDistributors_label__iA\\+Hw {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/AddDistributors/CreateDistributors.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;AACA;CACC,YAAY;CACZ,uBAAuB;AACxB;AACA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;CACV,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.allContainer {\n\theight: 100%;\n\tbackground-color: white;\n}\n.cardContainer {\n\twidth: 60%;\n}\n\n.card {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.inputContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.label {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateDistributors_container__aTjmN",
	"allContainer": "CreateDistributors_allContainer__fmco2",
	"cardContainer": "CreateDistributors_cardContainer__qEz0R",
	"card": "CreateDistributors_card__Tocqt",
	"inputContainer": "CreateDistributors_inputContainer__hcXEt",
	"label": "CreateDistributors_label__iA+Hw"
};
export default ___CSS_LOADER_EXPORT___;
