import { useAppDispatch } from 'data/global/hooks';
import { AddBrandsAsync } from 'data/global/features/BrandsSlice/brandsSlice';
import { AddBrandsRequest } from 'data/types/brandsTypes';

export const useAddBrand = () => {
	const dispatch = useAppDispatch();

	const addBrand = (brand: AddBrandsRequest): void => {
		dispatch(AddBrandsAsync(brand));
	};

	return addBrand;
};

export default useAddBrand;
