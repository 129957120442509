import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllCategoriesRequest,
	GetAllCategoriesResponse,
	AddCategoriesRequest,
	AddCategoriesResponse,
	DeleteCategoriesResponse,
	PutCategoriesRequest,
	PutCategoriesResponse,
} from 'data/types/categoriesTypes';
import { Either } from 'models/either';

export const getAllCategories = async (): Promise<
	Either<Failure, GetAllCategoriesResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllCategoriesRequest, GetAllCategoriesResponse>({
			url: '/api/v1/backoffice/categories',
			secure: true,
		})
	);
};
export const addCategories = async (
	request: AddCategoriesRequest
): Promise<Either<Failure, AddCategoriesResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<AddCategoriesRequest, AddCategoriesResponse>({
			url: '/api/v1/backoffice/categories',
			request,
		})
	);
};

export const deleteCategories = async (
	id: string
): Promise<Either<Failure, DeleteCategoriesResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<string, DeleteCategoriesResponse>({
			url: `/api/v1/backoffice/categories/${id}`,
		})
	);
};

export const putCategories = async (
	request: PutCategoriesRequest
): Promise<Either<Failure, PutCategoriesResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutCategoriesRequest, PutCategoriesResponse>({
			url: `/api/v1/backoffice/categories/${request.id}`,
			request,
		})
	);
};

const CategoriesApi = {
	getAllCategories,
	addCategories,
	deleteCategories,
	putCategories,
};

export default CategoriesApi;
