// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserAdmin_container__DNLmI {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n\n.UserAdmin_containerDataTable__DBkpL {\n\twidth: 80%;\n}\n\n.UserAdmin_buttons__rFfdQ {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserAdmin/UserAdmin.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n\n.containerDataTable {\n\twidth: 80%;\n}\n\n.buttons {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UserAdmin_container__DNLmI",
	"containerDataTable": "UserAdmin_containerDataTable__DBkpL",
	"buttons": "UserAdmin_buttons__rFfdQ"
};
export default ___CSS_LOADER_EXPORT___;
