import { useAppDispatch } from 'data/global/hooks';
import { DeleteCategoriesAsync } from 'data/global/features/CategoriesSlice/categoriesSlice';

const useDeleteCategories = () => {
	const dispatch = useAppDispatch();

	const deleteCategories = (id: string): void => {
		dispatch(DeleteCategoriesAsync(id));
	};

	return deleteCategories;
};

export default useDeleteCategories;