import { FunctionComponent } from 'react';
import { Column, DataTable, Dialog } from 'UI';

type Props = {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	banner?: any;
};

const ViewDataTableBanner: FunctionComponent<Props> = (props) => (
	<div>
		<DataTable value={props.value} header={props.header}>
			<Column field='bannerId' header='Banner ID'></Column>
			<Column field='image' header='Imagen'></Column>
			<Column field='link' header='Link'></Column>
            <Column field='position' header='Position'></Column>
			<Column header='Acciones' body={props.body}></Column>
		</DataTable>
		<Dialog
			visible={props.visible}
			header='Eliminar Banner'
			modal
			footer={props.footer}
			style={{ width: '450px' }}
			onHide={props.onHide}
		>
			<div className='confirmation-content'>
				<i
					className='pi pi-exclamation-triangle mr-3'
					style={{ fontSize: '2rem' }}
				/>
				{props.banner && (
					<span>
						Estas seguro de eliminar el banner? <b>{props.banner?.email}</b>?
					</span>
				)}
			</div>
		</Dialog>
	</div>
);

export default ViewDataTableBanner;