import { FunctionComponent } from 'react';
import ViewDataTableBrands from './view';

interface Props {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	brands?: object;
}

export const DataTableBrands: FunctionComponent<Props> = ({
	value,
	header,
	body,
	visible,
	footer,
	onHide,
	brands,
}) => {
	return (
		<ViewDataTableBrands
			value={value}
			header={header}
			body={body}
			visible={visible}
			footer={footer}
			onHide={onHide}
			brands={brands}
		/>
	);
};

export default DataTableBrands;