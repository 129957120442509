import { useAppDispatch } from 'data/global/hooks';
import { PutPresetCartsAsync } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';
import { PutPresetCartsRequest } from 'data/types/presetCartsTypes';

const usePutPresetCart = () => {
	const dispatch = useAppDispatch();

	const putPresetCart = (request: PutPresetCartsRequest): void => {
		dispatch(PutPresetCartsAsync(request));
	};

	return putPresetCart;
};

export default usePutPresetCart;
