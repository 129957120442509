import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllCatalogProductsRequest,
	GetAllCatalogProductsResponse,
	AddCatalogProductsRequest,
	AddCatalogProductsResponse,
	DeleteCatalogProductsResponse,
	PutCatalogProductsRequest,
	PutCatalogProductsResponse,
} from 'data/types/catalogProductsTypes';
import { Either } from 'models/either';

export const getAllCatalogProducts = async (): Promise<
	Either<Failure, GetAllCatalogProductsResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllCatalogProductsRequest, GetAllCatalogProductsResponse>({
			url: '/api/v1/backoffice/catalog',
			secure: true,
		})
	);
};
export const addCatalogProducts = async (
	request: AddCatalogProductsRequest
): Promise<Either<Failure, AddCatalogProductsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<AddCatalogProductsRequest, AddCatalogProductsResponse>({
			url: '/api/v1/backoffice/catalog',
			request,
		})
	);
};

export const deleteCatalogProducts = async (
	id: string
): Promise<Either<Failure, DeleteCatalogProductsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<string, DeleteCatalogProductsResponse>({
			url: `/api/v1/backoffice/catalog/${id}`,
		})
	);
};

export const putCatalogProducts = async (
	request: PutCatalogProductsRequest
): Promise<Either<Failure, PutCatalogProductsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutCatalogProductsRequest, PutCatalogProductsResponse>({
			url: `/api/v1/backoffice/catalog/${request.id}`,
			request,
		})
	);
};

const CatalogProductsApi = {
	getAllCatalogProducts,
	addCatalogProducts,
	deleteCatalogProducts,
	putCatalogProducts,
};

export default CatalogProductsApi;
