import { AllPanelMenu, Logout } from 'components';
import DataTableBanner from 'components/DataTableBanner';
import { FunctionComponent } from 'react';
import style from './Banner.module.css';

type Props = {
	value?: any;
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	banner?: any;
	successText?: boolean;
};

export const ViewBanners: FunctionComponent<Props> = (props) => (
	<div>
		<Logout />
		
			<div className={style.container}>
				<aside>
				<AllPanelMenu />

				</aside>

				<aside className={style.containerDataTable}>

			
				<DataTableBanner 
				value={props.value}
				body={props.body}
				header={props.header}
				visible={props.visible}
				footer={props.footer}
				onHide={props.onHide}
				banner={props.banner}
				/>
				</aside>
			
			</div>
		
		
	</div>
);