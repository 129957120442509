import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import BrandssApi from 'data/api/brands';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllBrandsRequest,
	GetAllBrandsResponse,
	AddBrandsRequest,
	AddBrandsResponse,
	DeleteBrandsResponse,
	PutBrandsRequest,
	PutBrandsResponse,
} from 'data/types/brandsTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TbrandsSlice = {
	getAllBrandsStatusRequest: StatusRequest;
	getAllBrandsResponse?: GetAllBrandsResponse;
	getAllBrandsStatusError?: Failure;
	addBrandsStatusRequest?: StatusRequest;
	addBrandsStatusError?: Failure;
	deleteBrandsStatusRequest?: StatusRequest;
	deleteBrandsStatusError?: Failure;
	putBrandsStatusRequest: StatusRequest;
	putBrandsStatusError?: Failure;
};
const initialState: TbrandsSlice = {
	getAllBrandsStatusRequest: StatusRequest.initial,
	getAllBrandsResponse: undefined,
	getAllBrandsStatusError: undefined,
	addBrandsStatusRequest: StatusRequest.initial,
	addBrandsStatusError: undefined,
	deleteBrandsStatusRequest: StatusRequest.initial,
	deleteBrandsStatusError: undefined,
	putBrandsStatusRequest: StatusRequest.initial,
	putBrandsStatusError: undefined,
};

export const GetAllBrandsAsync = createAsyncThunk<
	GetAllBrandsResponse,
	GetAllBrandsRequest,
	{ rejectValue: Failure }
>('auth/GetAllBrandsAsync', async (request: GetAllBrandsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllBrandsResponse> =
			await BrandssApi.getAllBrandss();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get Brands');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddBrandsAsync = createAsyncThunk<
	AddBrandsResponse,
	AddBrandsRequest,
	{ rejectValue: Failure }
>('brands/AddBrandsAsync', async (request: AddBrandsRequest, thunkAPI) => {
	try {
		console.log('request',request);
		const eitherResponse: Either<Failure, AddBrandsResponse> =
			await BrandssApi.addBrands(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post Brands');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteBrandsAsync = createAsyncThunk<
	DeleteBrandsResponse,
	string,
	{ rejectValue: Failure }
>('brands/DeleteBrandsAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteBrandsResponse> =
			await BrandssApi.deleteBrands(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutBrandsAsync = createAsyncThunk<
	PutBrandsResponse,
	PutBrandsRequest,
	{ rejectValue: Failure }
>('brands/PutBrandsAsync', async (request: PutBrandsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutBrandsResponse> =
			await BrandssApi.putBrands(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const BrandsSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putBrandsStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addBrandsStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllBrandsAsync.pending, (state) => {
			state.getAllBrandsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllBrandsAsync.fulfilled, (state, action) => {
			state.getAllBrandsStatusRequest = StatusRequest.fulfilled;
			state.getAllBrandsResponse = action.payload;
		});
		builder.addCase(GetAllBrandsAsync.rejected, (state, action) => {
			state.getAllBrandsStatusRequest = StatusRequest.rejected;
			state.getAllBrandsStatusError = action.payload;
		});
		builder.addCase(AddBrandsAsync.pending, (state) => {
			state.addBrandsStatusRequest = StatusRequest.pending;
			state.addBrandsStatusError = undefined;
		});
		builder.addCase(AddBrandsAsync.fulfilled, (state, action) => {
			state.addBrandsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddBrandsAsync.rejected, (state, action) => {
			state.addBrandsStatusRequest = StatusRequest.rejected;
			state.addBrandsStatusError = action.payload;
		});
		builder.addCase(DeleteBrandsAsync.pending, (state) => {
			state.deleteBrandsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteBrandsAsync.fulfilled, (state, action) => {
			state.deleteBrandsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteBrandsAsync.rejected, (state, action) => {
			state.deleteBrandsStatusRequest = StatusRequest.rejected;
			state.deleteBrandsStatusError = action.payload;
		});
		builder.addCase(PutBrandsAsync.pending, (state) => {
			state.putBrandsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutBrandsAsync.fulfilled, (state, action) => {
			state.putBrandsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutBrandsAsync.rejected, (state, action) => {
			state.putBrandsStatusRequest = StatusRequest.rejected;
			state.putBrandsStatusError = action.payload;
		});
	},
});

export const selectBrands = (state: RootState) => state.brands;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	BrandsSlice.actions;
export default BrandsSlice.reducer;
