// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResendEmail_container__NgsC1 {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.ResendEmail_allContainer__nn-9n {\n\theight: 100%;\n\tbackground-color: white;\n}\n.ResendEmail_cardContainer__OpaXY {\n\twidth: 60%;\n}\n\n.ResendEmail_card__2ITSg {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.ResendEmail_inputContainer__Heuo1 {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.ResendEmail_label__IO9PL {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ResendEmail/ResendEmail.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;AACA;CACC,YAAY;CACZ,uBAAuB;AACxB;AACA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;CACV,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-between;\n}\n.allContainer {\n\theight: 100%;\n\tbackground-color: white;\n}\n.cardContainer {\n\twidth: 60%;\n}\n\n.card {\n\twidth: 50%;\n\tmargin-left: 4em;\n\tmargin-top: 6em;\n}\n\n.inputContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.label {\n\tfont-weight: bold;\n\tmargin-bottom: 10px;\n\tmargin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ResendEmail_container__NgsC1",
	"allContainer": "ResendEmail_allContainer__nn-9n",
	"cardContainer": "ResendEmail_cardContainer__OpaXY",
	"card": "ResendEmail_card__2ITSg",
	"inputContainer": "ResendEmail_inputContainer__Heuo1",
	"label": "ResendEmail_label__IO9PL"
};
export default ___CSS_LOADER_EXPORT___;
