import { FunctionComponent } from 'react';
import { Dialog as DialogPrime, DialogProps } from 'primereact/dialog';

type Props = {
	header: any;
	footer?: any;
	visible: boolean;
	onHide: (e: any) => void;
} & DialogProps;

export const Dialog: FunctionComponent<Props> = ({ ...props }) => (
	<DialogPrime
		visible={props.visible}
		header={props.header}
		footer={props.footer}
		onHide={props.onHide}
		modal
	>
		{props.children}
	</DialogPrime>
);
