import { GetCatalogProductExportAsync } from './../../../data/global/features/ImporterSlice/importerSlice';
import { useAppDispatch } from 'data/global/hooks';

const useCatalogProductExport = () => {
	const dispatch = useAppDispatch();

	const GetCatalogProductExport = (): void => {
		dispatch(GetCatalogProductExportAsync());
	};

	return GetCatalogProductExport;
};

export default useCatalogProductExport;