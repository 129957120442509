import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import MasterProductsApi from 'data/api/masterProducts';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllMasterProductsRequest,
	GetAllMasterProductsResponse,
	AddMasterProductsRequest,
	AddMasterProductsResponse,
	DeleteMasterProductsResponse,
	PutMasterProductsRequest,
	PutMasterProductsResponse,
} from 'data/types/masterProductsTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TmasterProductsSlice = {
	getAllMasterProductsStatusRequest: StatusRequest;
	getAllMasterProductsResponse?: GetAllMasterProductsResponse;
	getAllMasterProductsStatusError?: Failure;
	addMasterProductsStatusRequest?: StatusRequest;
	addMasterProductsStatusError?: Failure;
	deleteMasterProductsStatusRequest?: StatusRequest;
	deleteMasterProductsStatusError?: Failure;
	putMasterProductsStatusRequest: StatusRequest;
	putMasterProductsStatusError?: Failure;
};
const initialState: TmasterProductsSlice = {
	getAllMasterProductsStatusRequest: StatusRequest.initial,
	getAllMasterProductsResponse: undefined,
	getAllMasterProductsStatusError: undefined,
	addMasterProductsStatusRequest: StatusRequest.initial,
	addMasterProductsStatusError: undefined,
	deleteMasterProductsStatusRequest: StatusRequest.initial,
	deleteMasterProductsStatusError: undefined,
	putMasterProductsStatusRequest: StatusRequest.initial,
	putMasterProductsStatusError: undefined,
};

export const GetAllMasterProductsAsync = createAsyncThunk<
	GetAllMasterProductsResponse,
	GetAllMasterProductsRequest,
	{ rejectValue: Failure }
>('auth/GetAllMasterProductsAsync', async (request: GetAllMasterProductsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllMasterProductsResponse> =
			await MasterProductsApi.getAllMasterProducts();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get MasterProducts');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddMasterProductsAsync = createAsyncThunk<
	AddMasterProductsResponse,
	AddMasterProductsRequest,
	{ rejectValue: Failure }
>('masterProducts/AddMasterProductsAsync', async (request: AddMasterProductsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, AddMasterProductsResponse> =
			await MasterProductsApi.addMasterProducts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post MasterProducts');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteMasterProductsAsync = createAsyncThunk<
	DeleteMasterProductsResponse,
	string,
	{ rejectValue: Failure }
>('masterProducts/DeleteMasterProductsAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteMasterProductsResponse> =
			await MasterProductsApi.deleteMasterProducts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutMasterProductsAsync = createAsyncThunk<
	PutMasterProductsResponse,
	PutMasterProductsRequest,
	{ rejectValue: Failure }
>('masterProducts/PutMasterProductsAsync', async (request: PutMasterProductsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutMasterProductsResponse> =
			await MasterProductsApi.putMasterProducts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const MasterProductsSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putMasterProductsStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addMasterProductsStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllMasterProductsAsync.pending, (state) => {
			state.getAllMasterProductsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllMasterProductsAsync.fulfilled, (state, action) => {
			state.getAllMasterProductsStatusRequest = StatusRequest.fulfilled;
			state.getAllMasterProductsResponse = action.payload;
		});
		builder.addCase(GetAllMasterProductsAsync.rejected, (state, action) => {
			state.getAllMasterProductsStatusRequest = StatusRequest.rejected;
			state.getAllMasterProductsStatusError = action.payload;
		});
		builder.addCase(AddMasterProductsAsync.pending, (state) => {
			state.addMasterProductsStatusRequest = StatusRequest.pending;
			state.addMasterProductsStatusError = undefined;
		});
		builder.addCase(AddMasterProductsAsync.fulfilled, (state, action) => {
			state.addMasterProductsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddMasterProductsAsync.rejected, (state, action) => {
			state.addMasterProductsStatusRequest = StatusRequest.rejected;
			state.addMasterProductsStatusError = action.payload;
		});
		builder.addCase(DeleteMasterProductsAsync.pending, (state) => {
			state.deleteMasterProductsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteMasterProductsAsync.fulfilled, (state, action) => {
			state.deleteMasterProductsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteMasterProductsAsync.rejected, (state, action) => {
			state.deleteMasterProductsStatusRequest = StatusRequest.rejected;
			state.deleteMasterProductsStatusError = action.payload;
		});
		builder.addCase(PutMasterProductsAsync.pending, (state) => {
			state.putMasterProductsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutMasterProductsAsync.fulfilled, (state, action) => {
			state.putMasterProductsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutMasterProductsAsync.rejected, (state, action) => {
			state.putMasterProductsStatusRequest = StatusRequest.rejected;
			state.putMasterProductsStatusError = action.payload;
		});
	},
});

export const selectMasterProducts = (state: RootState) => state.masterProducts;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	MasterProductsSlice.actions;
export default MasterProductsSlice.reducer;
