import { useAppDispatch } from 'data/global/hooks';
import { DeleteBrandsAsync } from 'data/global/features/BrandsSlice/brandsSlice';

const useDeleteBrands = () => {
	const dispatch = useAppDispatch();

	const deleteBrands = (id: string): void => {
		dispatch(DeleteBrandsAsync(id));
	};

	return deleteBrands;
};

export default useDeleteBrands;