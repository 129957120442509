import * as BaseApi from 'data/models/baseApi';
import * as BaseRepository from 'data/models/baseRepository';
import { Failure } from 'data/types';
import {
	GetAllBrandsRequest,
	GetAllBrandsResponse,
	AddBrandsRequest,
	AddBrandsResponse,
	DeleteBrandsResponse,
	PutBrandsRequest,
	PutBrandsResponse,
} from 'data/types/brandsTypes';
import { Either } from 'models/either';

export const getAllBrandss = async (): Promise<
	Either<Failure, GetAllBrandsResponse>
> => {
	return BaseRepository.toEither(() =>
		BaseApi.get<GetAllBrandsRequest, GetAllBrandsResponse>({
			url: '/api/v1/backoffice/brands',
			secure: true,
		})
	);
};
export const addBrands = async (
	request: AddBrandsRequest
): Promise<Either<Failure, AddBrandsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.post<AddBrandsRequest, AddBrandsResponse>({
			url: '/api/v1/backoffice/brands',
			request,
		})
	);
};

export const deleteBrands = async (
	id: string
): Promise<Either<Failure, DeleteBrandsResponse>> => {
	console.log('id', id);
	return BaseRepository.toEither(() =>
		BaseApi.deleteApi<string, DeleteBrandsResponse>({
			url: `/api/v1/backoffice/brands/${id}`,
		})
	);
};

export const putBrands = async (
	request: PutBrandsRequest
): Promise<Either<Failure, PutBrandsResponse>> => {
	return BaseRepository.toEither(() =>
		BaseApi.put<PutBrandsRequest, PutBrandsResponse>({
			url: `/api/v1/backoffice/brands/${request.id}`,
			request,
		})
	);
};

const BrandssApi = {
	getAllBrandss,
	addBrands,
	deleteBrands,
	putBrands,
};

export default BrandssApi;
