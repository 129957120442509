import { useAppDispatch } from 'data/global/hooks';
import { ImpersonateLibraryUserAsync } from 'data/global/features/LibraryUserSlice/libraryUserSlice';

const useImpersonateLibraryUser = () => {
	const dispatch = useAppDispatch();

	const impersonateLibraryUser = (id: string): void => {
		dispatch(ImpersonateLibraryUserAsync(id));
	};

	return impersonateLibraryUser;
};

export default useImpersonateLibraryUser;