import { AllPanelMenu, Logout } from 'components';
import DataTableCategories from 'components/DataTableCategories';
import { FunctionComponent } from 'react';
import style from './Categories.module.css';

type Props = {
	value?: any;
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	categories?: any;
	successText?: boolean;
};

export const ViewCategories: FunctionComponent<Props> = (props) => (
	<div>
		<Logout />
		
			<div className={style.container}>
				<aside>
				<AllPanelMenu />

				</aside>

				<aside className={style.containerDataTable}>

			
				<DataTableCategories 
				value={props.value}
				body={props.body}
				header={props.header}
				visible={props.visible}
				footer={props.footer}
				onHide={props.onHide}
				categories={props.categories}
				/>
				</aside>
			
			</div>
		
		
	</div>
);