import * as pages from 'pages';
import namesRoutes from 'routes/namesRoutes';

const routes = [
	{
		path: namesRoutes.createuser,
		component: pages.CreateUser,
	},
	{
		path: namesRoutes.useradmin,
		component: pages.UserAdmin,
	},
	{
		path: namesRoutes.dashboard,
		component: pages.Dashboard,
	},
	{
		path: namesRoutes.home,
		component: pages.SignIn,
	},
	{
		path: namesRoutes.banners,
		component: pages.Banners,
	},
	{
		path: namesRoutes.addbanner,
		component: pages.CreateBanner,
	},
	{
		path: namesRoutes.brands,
		component: pages.Brands,
	},
	{
		path: namesRoutes.addbrand,
		component: pages.CreateBrand,
	},
	{
		path: namesRoutes.categories,
		component: pages.Categories,
	},
	{
		path: namesRoutes.addcategories,
		component: pages.CreateCategories,
	},
	{
		path: namesRoutes.distributors,
		component: pages.Distributors,
	},
	{
		path: namesRoutes.manufacturers,
		component: pages.Manufacturer,
	},
	{
		path: namesRoutes.adddistributors,
		component: pages.CreateDistributors,
	},
	{
		path: namesRoutes.addManufacturers,
		component: pages.CreateManufacturer,
	},
	{
		path: namesRoutes.libraryuser,
		component: pages.LibraryUser,
	},
	{
		path: namesRoutes.addlibraryuser,
		component: pages.CreateLibraryUser,
	},
	{
		path: namesRoutes.libraryusersdetail,
		component: pages.LibraryUsersDetail,
	},
	{
		path: namesRoutes.masterproducts,
		component: pages.MasterProducts,
	},
	{
		path: namesRoutes.catalogproducts,
		component: pages.CatalogProducts,
	},
	{
		path: namesRoutes.importer,
		component: pages.Importer,
	},
	{
		path: namesRoutes.resendEmail,
		component: pages.ResendEmail,
	},
	{
		path: namesRoutes.addPresetCarts,
		component: pages.CreatePresetCart,
	},
	{
		path: namesRoutes.presetCarts,
		component: pages.PresetCarts,
	},
];

export default routes;
