import { ViewDistributors } from "./view";
import React, { useState, Fragment, useEffect } from 'react';
import { Button, ConfirmPopup } from 'UI';
import { HeaderDataTable } from 'components';
import { useAppSelector } from 'data/global/hooks';
import { selectDistributors } from 'data/global/features/DistributorsSlice/distributorsSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteDistributors, useGetDistributors } from "./hooks";

export const Distributors: React.FC = () => {
    const [deleteDistributorsDialog, setDeleteDistributorsDialog] = useState(false);
	const [deleteDistributorsSuccess, setDeleteDistributorsSuccess] = useState(false);

	const deleteDistributors = useDeleteDistributors();

	const navigate = useNavigate();

	useGetDistributors();
	const { getAllDistributorsResponse, deleteDistributorsStatusRequest } =
		useAppSelector(selectDistributors);

	useEffect(() => {
		if (deleteDistributorsStatusRequest === 1) {
			setDeleteDistributorsSuccess(true);
		}
	}, [deleteDistributorsStatusRequest]);

	useEffect(() => {
		if (deleteDistributorsSuccess) {
			window.location.reload();
		}
	});
	// Put
	const [distributorsDelete, setDistributorsDelete] = useState<null | { distributorID: any }>(
		null
	);

	const editDistributors = (distributors: any) => {
		navigate('/adddistributors', { state: { distributors: distributors } });
	};

	//  delete
	const confirmDeleteDistributors = (distributors: any) => {
		setDistributorsDelete(distributors);
		setDeleteDistributorsDialog(true);
	};

	const deleteDistributorsDispatch = () => {
		setDeleteDistributorsDialog(false);
		console.log('distID', distributorsDelete);
		deleteDistributors(distributorsDelete?.distributorID);
	};
	const hideDeleteDistributorsDialog = () => {
		setDeleteDistributorsDialog(false);
	};

	const header = () => {
		return (
			<Fragment>
				<HeaderDataTable
					title={'Distributors'}
					textButton={'Agregar Distributors'}
                    redirectTo={'/addDistributors'}
				></HeaderDataTable>
			</Fragment>
		);
	};
	const bodyTemplate = (rowData: any) => {
		return (
			<Fragment>
				<div>
					<Button
						icon='pi pi-pencil'
						tooltip='Editar distributors'
						tooltipOptions={{ position: 'top' }}
						onClick={() => editDistributors(rowData)}
						style={{ marginRight: '6px' }}
					/>
					<ConfirmPopup />
					<Button
						icon='pi pi-trash'
						tooltip='Eliminar distributors'
						tooltipOptions={{ position: 'top' }}
						onClick={() => confirmDeleteDistributors(rowData)}
					/>
				</div>
			</Fragment>
		);
	};

	const deleteProductDialogFooter = (
		<Fragment>
			<Button
				label='No'
				icon='pi pi-times'
				className='p-button-text'
				onClick={hideDeleteDistributorsDialog}
			/>
			<Button
				label='Yes'
				icon='pi pi-check'
				className='p-button-text'
				onClick={deleteDistributorsDispatch}
			/>
		</Fragment>
	);

    return <ViewDistributors
        value={getAllDistributorsResponse}
        body={bodyTemplate}
        header={header}
        footer={deleteProductDialogFooter}
        visible={deleteDistributorsDialog}
        onHide={hideDeleteDistributorsDialog}
        distributors={distributorsDelete}
            />
};