import { FunctionComponent } from 'react';
import { TUserSignIn } from 'models/userModels';
import { Card, InputTextSigIn, Button, ErrorText } from 'UI';
import { ControlProps, FormProps } from 'models/form';
import style from './SignIn.module.css';
import image from './image.jpg';

type Props = {
	formProps: FormProps;
	controlProps: Omit<ControlProps, 'name'>;
	error?: any;
	signIn: (user: TUserSignIn) => void;
};

export const ViewSignIn: FunctionComponent<Props> = (props) => (
	<div className={style.cardContainer}>
		<Card className={style.cardSignIn}>
			<div className={style.containerCardandImage}>
				<aside>
					<h1> Acceder al Backoffice</h1>
					<div className={style.inputContainer}>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.email,
								name: 'email',
								rules: props.controlProps.rules.rulesEmail,
							}}
							errors={props.formProps.errors}
							type='text'
							className={style.input}
							placeholder='email'
						></InputTextSigIn>
						<InputTextSigIn
							controllerProps={{
								control: props.controlProps.control,
								defaultValue: props.controlProps.defaultValue.email,
								name: 'password',
								rules: props.controlProps.rules.rulesPassword,
							}}
							className={style.input}
							errors={props.formProps.errors}
							type='password'
							placeholder='password'
						></InputTextSigIn>
						<Button
							label='Sing In'
							className='p-button-lg'
							style={{ marginTop: '1.5em' }}
							onClick={props.formProps.handleSubmit(props.signIn)}
						></Button>
					</div>
				</aside>
				<aside className={style.containerImage}>
					<img src={image} alt='img' className={style.image}></img>
				</aside>
			</div>
			{props?.error?.error && (
				<ErrorText style={{ marginTop: '1.5em' }}>
					{props?.error?.error}
				</ErrorText>
			)}
		</Card>
	</div>
);
