import { FunctionComponent } from 'react';
import { Column, DataTable, Dialog } from 'UI';

type Props = {
	value?: any[];
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	libraryUser?: any;
};

const ViewDataTableLibraryUser: FunctionComponent<Props> = (props) => (
	<div>
		<DataTable value={props.value} header={props.header}>
			<Column field='libraryUserId' header='ID'></Column>
			<Column field='name' header='Name'></Column>
			<Column field='lastName' header='LastName'></Column>
			<Column header='Acciones' body={props.body}></Column>
		</DataTable>
		<Dialog
			visible={props.visible}
			header='Eliminar LibraryUser'
			modal
			footer={props.footer}
			style={{ width: '450px' }}
			onHide={props.onHide}
		>
			<div className='confirmation-content'>
				<i
					className='pi pi-exclamation-triangle mr-3'
					style={{ fontSize: '2rem' }}
				/>
				{props.libraryUser && (
					<span>
						Estas seguro de eliminar el libraryUser? <b>{props.libraryUser?.email}</b>?
					</span>
				)}
			</div>
		</Dialog>
	</div>
);

export default ViewDataTableLibraryUser;