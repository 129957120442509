import { useAppDispatch } from 'data/global/hooks';
import { logOut } from 'data/global/features/AuthSlice/authSlice';

const useLogout = () => {
	const dispatch = useAppDispatch();
	const LogOut = (): void => {
		dispatch(logOut());
	};
	return LogOut;
};

export default useLogout;
