import { useAppDispatch } from 'data/global/hooks';
import { GetAllCatalogProductsAsync } from 'data/global/features/CatalogProductsSlice/catalogProductsSlice';
import { useEffect } from 'react';

const useGetCatalogProducts = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(GetAllCatalogProductsAsync({}));
	}, [dispatch]);
};

export default useGetCatalogProducts;