import { FunctionComponent } from 'react';

type Props = { style?: object };

export const SuccessText: FunctionComponent<Props> = (props) => (
	<span className='successText' style={props.style}>
		{props.children}
	</span>
);
export default SuccessText;
