import { useAppDispatch } from 'data/global/hooks';
import { resetputStatusRequest } from 'data/global/features/CategoriesSlice/categoriesSlice';

const usePutReset = () => {
	const dispatch = useAppDispatch();
	const ResetPut = (): void => {
		dispatch(resetputStatusRequest());
	};
	return ResetPut;
};

export default usePutReset;
