import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import PresetCartsApi from 'data/api/presetCarts';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllPresetCartsRequest,
	GetAllPresetCartsResponse,
	AddPresetCartsRequest,
	AddPresetCartsResponse,
	DeletePresetCartsResponse,
	PutPresetCartsRequest,
	PutPresetCartsResponse,
} from 'data/types/presetCartsTypes';
import { ExportResponse } from 'data/types/importerTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TpresetCartsSlice = {
	getAllPresetCartsStatusRequest: StatusRequest;
	getAllPresetCartsResponse?: GetAllPresetCartsResponse;
	getAllPresetCartsStatusError?: Failure;
	addPresetCartsStatusRequest?: StatusRequest;
	addPresetCartsStatusError?: Failure;
	deletePresetCartsStatusRequest?: StatusRequest;
	deletePresetCartsStatusError?: Failure;
	putPresetCartsStatusRequest: StatusRequest;
	putPresetCartsStatusError?: Failure;
	getPresetCartItemsExportResponse?: ExportResponse;
	getPresetCartItemsExportStatusRequest?: StatusRequest;
	getPresetCartItemsExportStatusError?: Failure;
};
const initialState: TpresetCartsSlice = {
	getAllPresetCartsStatusRequest: StatusRequest.initial,
	getAllPresetCartsResponse: undefined,
	getAllPresetCartsStatusError: undefined,
	addPresetCartsStatusRequest: StatusRequest.initial,
	addPresetCartsStatusError: undefined,
	deletePresetCartsStatusRequest: StatusRequest.initial,
	deletePresetCartsStatusError: undefined,
	putPresetCartsStatusRequest: StatusRequest.initial,
	putPresetCartsStatusError: undefined,
	getPresetCartItemsExportResponse: undefined,
	getPresetCartItemsExportStatusRequest: StatusRequest.initial,
	getPresetCartItemsExportStatusError: undefined,
};

export const GetAllPresetCartsAsync = createAsyncThunk<
	GetAllPresetCartsResponse,
	GetAllPresetCartsRequest,
	{ rejectValue: Failure }
>('auth/GetAllPresetCartsAsync', async (request: GetAllPresetCartsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllPresetCartsResponse> =
			await PresetCartsApi.getAllPresetCarts();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get PresetCarts');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const AddPresetCartsAsync = createAsyncThunk<
	AddPresetCartsResponse,
	AddPresetCartsRequest,
	{ rejectValue: Failure }
>('presetCarts/AddPresetCartsAsync', async (request: AddPresetCartsRequest, thunkAPI) => {
	try {
		console.log('request',request);
		const eitherResponse: Either<Failure, AddPresetCartsResponse> =
			await PresetCartsApi.addPresetCarts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post PresetCarts');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeletePresetCartsAsync = createAsyncThunk<
	DeletePresetCartsResponse,
	string,
	{ rejectValue: Failure }
>('presetCarts/DeletePresetCartsAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeletePresetCartsResponse> =
			await PresetCartsApi.deletePresetCarts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);
		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutPresetCartsAsync = createAsyncThunk<
	PutPresetCartsResponse,
	PutPresetCartsRequest,
	{ rejectValue: Failure }
>('presetCarts/PutPresetCartsAsync', async (request: PutPresetCartsRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutPresetCartsResponse> =
			await PresetCartsApi.putPresetCarts(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const GetPresetCartItemsExportAsync = createAsyncThunk<
	ExportResponse,
	string,
	{ rejectValue: Failure }
>('presetCarts/DownloadItemsAsync', async (request: string, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, ExportResponse> =
			await PresetCartsApi.getPresetCartItemsExport(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response download');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PresetCartsSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putPresetCartsStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.addPresetCartsStatusRequest = 0;
		},
		resetGetExportStatusRequest: (state) => {
			state.getPresetCartItemsExportStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllPresetCartsAsync.pending, (state) => {
			state.getAllPresetCartsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllPresetCartsAsync.fulfilled, (state, action) => {
			state.getAllPresetCartsStatusRequest = StatusRequest.fulfilled;
			state.getAllPresetCartsResponse = action.payload;
		});
		builder.addCase(GetAllPresetCartsAsync.rejected, (state, action) => {
			state.getAllPresetCartsStatusRequest = StatusRequest.rejected;
			state.getAllPresetCartsStatusError = action.payload;
		});
		builder.addCase(AddPresetCartsAsync.pending, (state) => {
			state.addPresetCartsStatusRequest = StatusRequest.pending;
			state.addPresetCartsStatusError = undefined;
		});
		builder.addCase(AddPresetCartsAsync.fulfilled, (state, action) => {
			state.addPresetCartsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(AddPresetCartsAsync.rejected, (state, action) => {
			state.addPresetCartsStatusRequest = StatusRequest.rejected;
			state.addPresetCartsStatusError = action.payload;
		});
		builder.addCase(DeletePresetCartsAsync.pending, (state) => {
			state.deletePresetCartsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeletePresetCartsAsync.fulfilled, (state, action) => {
			state.deletePresetCartsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeletePresetCartsAsync.rejected, (state, action) => {
			state.deletePresetCartsStatusRequest = StatusRequest.rejected;
			state.deletePresetCartsStatusError = action.payload;
		});
		builder.addCase(PutPresetCartsAsync.pending, (state) => {
			state.putPresetCartsStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutPresetCartsAsync.fulfilled, (state, action) => {
			state.putPresetCartsStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutPresetCartsAsync.rejected, (state, action) => {
			state.putPresetCartsStatusRequest = StatusRequest.rejected;
			state.putPresetCartsStatusError = action.payload;
		});
		builder.addCase(GetPresetCartItemsExportAsync.pending, (state) => {
			state.getPresetCartItemsExportStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetPresetCartItemsExportAsync.fulfilled, (state, action) => {
			state.getPresetCartItemsExportStatusRequest = StatusRequest.fulfilled;
			state.getPresetCartItemsExportResponse = action.payload;
		});
		builder.addCase(GetPresetCartItemsExportAsync.rejected, (state, action) => {
			state.getPresetCartItemsExportStatusRequest = StatusRequest.rejected;
			state.getPresetCartItemsExportStatusError = action.payload;
		});
	},
});

export const selectPresetCarts = (state: RootState) => state.presetCarts;
export const { resetputStatusRequest, resetCreateStatusRequest, resetGetExportStatusRequest } =
PresetCartsSlice.actions;
export default PresetCartsSlice.reducer;
