import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'data/global/store';
import UsersApi from 'data/api/users';
import { Failure, StatusRequest } from 'data/types';
import {
	GetAllUserAdminRequest,
	GetAllUserAdminResponse,
	CreateUserRequest,
	CreateUserResponse,
	DeleteUserRequest,
	DeleteUserResponse,
	PutUserRequest,
	PutUserResponse,
} from 'data/types/userTypes';
import { Either, isRight, unwrapEither } from 'models/either';

type TuserSlice = {
	getAllUserAdminStatusRequest: StatusRequest;
	getAllUserAdminResponse?: GetAllUserAdminResponse;
	getAllUserAdminStatusError?: Failure;
	createUserStatusRequest?: StatusRequest;
	createUserStatusError?: Failure;
	deleteUserStatusRequest?: StatusRequest;
	deleteUserStatusError?: Failure;
	putUserStatusRequest: StatusRequest;
	putUserStatusError?: Failure;
};
const initialState: TuserSlice = {
	getAllUserAdminStatusRequest: StatusRequest.initial,
	getAllUserAdminResponse: undefined,
	getAllUserAdminStatusError: undefined,
	createUserStatusRequest: StatusRequest.initial,
	createUserStatusError: undefined,
	deleteUserStatusRequest: StatusRequest.initial,
	deleteUserStatusError: undefined,
	putUserStatusRequest: StatusRequest.initial,
	putUserStatusError: undefined,
};

export const GetAllUserAsync = createAsyncThunk<
	GetAllUserAdminResponse,
	GetAllUserAdminRequest,
	{ rejectValue: Failure }
>('auth/GetAllUserAsync', async (request: GetAllUserAdminRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, GetAllUserAdminResponse> =
			await UsersApi.getAllUsers();
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response get');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});
export const CreateUserAsync = createAsyncThunk<
	CreateUserResponse,
	CreateUserRequest,
	{ rejectValue: Failure }
>('user/CreateUserAsync', async (request: CreateUserRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, CreateUserResponse> =
			await UsersApi.createUser(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response post');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const DeleteUserAsync = createAsyncThunk<
	DeleteUserResponse,
	DeleteUserRequest,
	{ rejectValue: Failure }
>('user/DeleteUserAsync', async (request: DeleteUserRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, DeleteUserResponse> =
			await UsersApi.deleteUser(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response delete');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const PutUserAsync = createAsyncThunk<
	PutUserResponse,
	PutUserRequest,
	{ rejectValue: Failure }
>('user/PutUserAsync', async (request: PutUserRequest, thunkAPI) => {
	try {
		const eitherResponse: Either<Failure, PutUserResponse> =
			await UsersApi.putUser(request);
		if (isRight(eitherResponse)) {
			const response = unwrapEither(eitherResponse);
			console.log(response, 'Response put');
			return response;
		}
		const error = unwrapEither(eitherResponse);

		return thunkAPI.rejectWithValue(error);
	} catch (e) {
		const error: Failure = {
			error: true,
			message: (e as Error).toString(),
		};
		return thunkAPI.rejectWithValue(error);
	}
});

export const UserSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetputStatusRequest: (state) => {
			state.putUserStatusRequest = 0;
		},
		resetCreateStatusRequest: (state) => {
			state.createUserStatusRequest = 0;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GetAllUserAsync.pending, (state) => {
			state.getAllUserAdminStatusRequest = StatusRequest.pending;
		});
		builder.addCase(GetAllUserAsync.fulfilled, (state, action) => {
			state.getAllUserAdminStatusRequest = StatusRequest.fulfilled;
			state.getAllUserAdminResponse = action.payload;
		});
		builder.addCase(GetAllUserAsync.rejected, (state, action) => {
			state.getAllUserAdminStatusRequest = StatusRequest.rejected;
			state.getAllUserAdminStatusError = action.payload;
		});
		builder.addCase(CreateUserAsync.pending, (state) => {
			state.createUserStatusRequest = StatusRequest.pending;
			state.createUserStatusError = undefined;
		});
		builder.addCase(CreateUserAsync.fulfilled, (state, action) => {
			state.createUserStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(CreateUserAsync.rejected, (state, action) => {
			state.createUserStatusRequest = StatusRequest.rejected;
			state.createUserStatusError = action.payload;
		});
		builder.addCase(DeleteUserAsync.pending, (state) => {
			state.deleteUserStatusRequest = StatusRequest.pending;
		});
		builder.addCase(DeleteUserAsync.fulfilled, (state, action) => {
			state.deleteUserStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(DeleteUserAsync.rejected, (state, action) => {
			state.deleteUserStatusRequest = StatusRequest.rejected;
			state.deleteUserStatusError = action.payload;
		});
		builder.addCase(PutUserAsync.pending, (state) => {
			state.putUserStatusRequest = StatusRequest.pending;
		});
		builder.addCase(PutUserAsync.fulfilled, (state, action) => {
			state.putUserStatusRequest = StatusRequest.fulfilled;
		});
		builder.addCase(PutUserAsync.rejected, (state, action) => {
			state.putUserStatusRequest = StatusRequest.rejected;
			state.putUserStatusError = action.payload;
		});
	},
});

export const selectUser = (state: RootState) => state.user;
export const { resetputStatusRequest, resetCreateStatusRequest } =
	UserSlice.actions;
export default UserSlice.reducer;
