// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LibraryUsersDetail_container__xg84l {\n\tcolor: #f8f8f8;\n\tpadding: 10px;\n\tfont-family: monospace;\n\toverflow-x: auto;\n\tbackground-color: #333333;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/LibraryUsersDetail/LibraryUsersDetail.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,aAAa;CACb,sBAAsB;CACtB,gBAAgB;CAChB,yBAAyB;AAC1B","sourcesContent":[".container {\n\tcolor: #f8f8f8;\n\tpadding: 10px;\n\tfont-family: monospace;\n\toverflow-x: auto;\n\tbackground-color: #333333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LibraryUsersDetail_container__xg84l"
};
export default ___CSS_LOADER_EXPORT___;
