import { AllPanelMenu, Logout } from 'components';
import DataTableMasterProducts from 'components/DataTableMasterProducts';
import { FunctionComponent } from 'react';
import style from './MasterProducts.module.css';

type Props = {
	value?: any;
	body?: any;
	header?: any;
	visible: boolean;
	footer?: any;
	onHide?: any;
	masterProducts?: any;
	successText?: boolean;
};

export const ViewMasterProducts: FunctionComponent<Props> = (props) => (
	<div>
		<Logout />
		
			<div className={style.container}>
				<aside>
				<AllPanelMenu />

				</aside>

				<aside className={style.containerDataTable}>

			
				<DataTableMasterProducts 
				value={props.value}
				body={props.body}
				header={props.header}
				visible={props.visible}
				footer={props.footer}
				onHide={props.onHide}
				masterProducts={props.masterProducts}
				/>
				</aside>
			
			</div>
		
		
	</div>
);