import { useAppDispatch } from 'data/global/hooks';
import { GetPresetCartItemsExportAsync } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';

const usePresetCartItemsExport = () => {
	const dispatch = useAppDispatch();

	const getPresetCartItems = (id: string): void => {
		dispatch(GetPresetCartItemsExportAsync(id));
	};

	return getPresetCartItems;
};

export default usePresetCartItemsExport;