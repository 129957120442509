import { FunctionComponent } from 'react';
import styles from './LibraryUsersDetail.module.css';

type Props = {
	jsonData?: any;
};

export const ViewLibraryUsersDetail: FunctionComponent<Props> = (props) => (
	<div className={styles.container}>
		{props.jsonData && <pre>{JSON.stringify(props.jsonData, null, 2)}</pre>}
	</div>
);
