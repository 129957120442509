import { useAppDispatch } from 'data/global/hooks';
import { DeleteManufacturerAsync } from 'data/global/features/ManufacturersSlice/manufacturersSlice';
import {DeleteManufacturerRequest} from 'data/types/manufacturerTypes'
const useDeleteManufacturer = () => {
	const dispatch = useAppDispatch();

	const deleteManufacturer = (id: DeleteManufacturerRequest): void => {
		dispatch(DeleteManufacturerAsync(id));
	};

	return deleteManufacturer;
};

export default useDeleteManufacturer;