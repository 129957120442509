import React, { useRef, useEffect } from 'react';
import { ViewCreatePresetCart } from './view';
import { useForm } from 'react-hook-form';
import { rulesName, rulesLink } from 'utils/validationRules';
import { useTranslation } from 'react-i18next';
import {
	useAddPresetCart,
	usePutPresetCart,
	usePutReset,
	useCreateReset,
} from './hooks';
import { selectPresetCarts } from 'data/global/features/PresetCartsSlice/presetsCartsSlice';
import { useAppSelector } from 'data/global/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const CreatePresetCart: React.FC = () => {
	// funcion que hace el dispatch para crear brand
	const addPresetCart = useAddPresetCart();

	const putPresetCart = usePutPresetCart();
	const resetPutPresetCart = usePutReset();
	const resetCreatePresetCart = useCreateReset();

	const { t } = useTranslation();
	const { addPresetCartsStatusError, addPresetCartsStatusRequest } =
		useAppSelector(selectPresetCarts);

	const { putPresetCartsStatusError, putPresetCartsStatusRequest } =
		useAppSelector(selectPresetCarts);

	type LocationStateProps = {
		presetCarts: any;
	};

	const location = useLocation().state as LocationStateProps;
	const presetCartModified = location?.presetCarts;
	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
	} = useForm({ mode: 'onChange' });

	const defaultValue = presetCartModified || {
		name: '',
		logo: '',
	};

	const handleSubmitModify = async (data: any, csvFileBase64: string) => {
		const id = presetCartModified.id;
		const { fechaInicio, fechaFin, nombreFantasia, enabled } = data;
		const request = { id, fechaInicio, fechaFin, nombreFantasia, csvFileBase64, enabled };
		putPresetCart(request);
	};

	const handleSubmitCreate = async (data: any, csvFileBase64: string) => {
		const newData = {
			...data,
			csvFileBase64: csvFileBase64,
		  };
		addPresetCart(newData);
	};

	// manejo de Toast
	const toast = useRef<any>(null);

	useEffect(() => {
		if (putPresetCartsStatusRequest === 1) {
			showSuccessModify();
		} else if (putPresetCartsStatusRequest === 2) {
			showErrorModify();
		}
	}, [putPresetCartsStatusRequest]);

	useEffect(() => {
		if (addPresetCartsStatusRequest === 1) {
			showSuccessCreate();
		} else if (addPresetCartsStatusRequest === 2) {
			showErrorCreate();
		}
	}, [addPresetCartsStatusRequest]);

	const showSuccessModify = () => {
		toast.current.show({
			severity: 'success',
			summary: 'PresetCart Modificado',
			detail: 'Se modifico con exito el presetCart seleccionado',
			life: 2000,
		});
		resetPutPresetCart();
		setTimeout(() => {
			navigate('/presetCarts');
		}, 1000);
	};
	const showErrorModify = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido modificar el presetCart seleccionado',
		});
	};

	const showSuccessCreate = () => {
		toast.current.show({
			severity: 'success',
			summary: 'PresetCart Creado',
			detail: 'El PresetCart fue creado con Exito',
			life: 3000,
		});
		resetCreatePresetCart();
		setTimeout(() => {
			navigate('/presetCarts'); 
		}, 1000);
	};
	const showErrorCreate = () => {
		toast.current.show({
			severity: 'error',
			summary: 'Error',
			detail: 'No se ha podido crear el PresetCart',
		});
	};

	const enabledSelectItems = [
		{ label: 'YES', value: 'YES' },
		{ label: 'NO', value: 'NO' },
	];

	//
	//reglas de los inputs
	const rules = {
		rulesImage: rulesLink(t),
		rulesLink: rulesLink(t),
		rulesPosition: rulesName(t),
	};
	return (
		<ViewCreatePresetCart
			formProps={{ handleSubmit, errors, isSubmitting, isValid }}
			controlProps={{ control, defaultValue, rules }}
			addPresetCart={addPresetCart}
			options={enabledSelectItems}
			error={addPresetCartsStatusError}
			modifyPresetCart={presetCartModified}
			handleSubmitModify={handleSubmitModify}
			handleSubmitCreate={handleSubmitCreate}
			toast={toast}
		/>
	);
};
