import { useAppDispatch } from 'data/global/hooks';
import { DeleteLibraryUserAsync } from 'data/global/features/LibraryUserSlice/libraryUserSlice';

const useDeleteLibraryUser = () => {
	const dispatch = useAppDispatch();

	const deleteLibraryUser = (id: string): void => {
		dispatch(DeleteLibraryUserAsync(id));
	};

	return deleteLibraryUser;
};

export default useDeleteLibraryUser;