import { FunctionComponent, Fragment } from 'react';
import { Dropdown as DropdownPrime, DropdownProps } from 'primereact/dropdown';
import { Controller } from 'react-hook-form';
import { ControlProps } from 'models/form';
type Props = {
	options?: any;
	placeholder?: string;
	controllerProps: ControlProps;
} & DropdownProps;

export const Dropdown: FunctionComponent<Props> = ({ ...props }) => (
	<Fragment>
		<Controller
			render={({ field: { onChange, onBlur, value, ref } }) => (
				<DropdownPrime
					options={props.options}
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					placeholder={props.placeholder}
					{...props}
				>
					{props.children}
				</DropdownPrime>
			)}
			control={props.controllerProps.control}
			defaultValue={props.controllerProps.defaultValue}
			name={props.controllerProps.name}
		/>
	</Fragment>
);
